import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, RadioField, ToggleField } from "react-components-linaia";
import { Field as RFField, reduxForm, formValueSelector } from "redux-form";
import { values, map, filter, find, isNull, isUndefined, includes } from "lodash";
import { withRouter, NavLink } from "react-router-dom";

import BaseLayout from "../../containers/shared/BaseLayout";
import ModeleVerreField from "./ModeleVerreField";
import { TYPE_VERRES, VERRES_COULEURS } from "../../constants/Properties";

import { loadTarifs } from "../../store/modules/tarifs";
import { loadGammes } from "../../store/modules/gammes";
import { loadReglesGestion } from "../../store/modules/reglesGestion";
import { loadRemisesMontures } from "../../store/modules/remisesMontures";

export const submit = (values, dispatch, props) => {
  // dispatch(reset("ModeleVerreForm"));
  // props.createQuestion(props.values).then(() => {
  //   // do other success stuff
  // });
  // window.location.replace("/verre");
};

const Home = ({
  history: { push },
  handleSubmit,
  loadTarifs,
  loadGammes,
  loadReglesGestion,
  loadRemisesMontures,
  oeil,
  reset,
  plus,
  type_verre,
  verre_couleur,
  correction,
  marque,
  reseau,
  tarifs,
  gammes,
  montures,
  regle_gestions,
  history,
  cylindre,
  sphere,
  id_verre_gauche,
  id_verre_droit,
  prisme,
}) => {
  useEffect(() => {
    async function fetchData() {
      // You can await here
      await loadTarifs();
      await loadGammes();
      await loadReglesGestion();
      await loadRemisesMontures();
      // ...
    }
    fetchData();
  }, []);

  const tarif_oeil_gauche = find(tarifs, (tarif) => tarif.tarif_id === parseInt(id_verre_gauche));
  const tarif_oeil_droit = find(tarifs, (tarif) => tarif.tarif_id === parseInt(id_verre_droit));

  const [showAppairageMessage, setShowAppairageMessage] = useState(false);
  const [greater_indice_og, setGreater_indice_og] = useState("");
  const [greater_indice_od, setGreater_indice_od] = useState("");
  const setShowAppairageMessageProps = (indice, side) => {
    if (side === "droit") {
      setGreater_indice_og(indice);
    } else {
      setGreater_indice_od(indice);
    }
    setShowAppairageMessage(greater_indice_od !== greater_indice_og);
  };

  const tarifKalixiaOeilDroit = (tarif_oeil_droit, correction, gamme) => {
    let price = tarif_oeil_droit.prix;

    if (
      Math.abs(correction.od_sphere + correction.od_cylindre) <= 3 &&
      gamme &&
      gamme.k_sph_cyl_inf_3
    ) {
      price = price - 15.84;
    }

    if (
      correction.galbe >= 5 &&
      correction.galbe <= 7 &&
      correction.distance_vo >= 11 &&
      correction.distance_vo <= 13 &&
      correction.angle >= 7 &&
      correction.angle <= 9 &&
      correction.diff_vlvp >= 2.4 &&
      correction.diff_vlvp <= 2.6 &&
      gamme.k_aspherisation &&
      (gamme.libelle !== "SUPERSEE AP" || tarif_oeil_droit.indice !== 1.74)
    ) {
      // #9123 passer a 10 pourcent
      price = price * 0.9;
    }
    return parseFloat(price.toFixed(2));
  };

  const tarifKalixiaOeilGauche = (tarif_oeil_gauche, correction, gamme) => {
    let price = tarif_oeil_gauche.prix;

    if (
      Math.abs(correction.og_sphere + correction.og_cylindre) <= 3 &&
      gamme &&
      gamme.k_sph_cyl_inf_3
    ) {
      price = price - 15.84;
    }

    if (
      correction.galbe >= 5 &&
      correction.galbe <= 7 &&
      correction.distance_vo >= 11 &&
      correction.distance_vo <= 13 &&
      correction.angle >= 7 &&
      correction.angle <= 9 &&
      correction.diff_vlvp >= 2.4 &&
      correction.diff_vlvp <= 2.6 &&
      gamme.k_aspherisation &&
      (gamme.libelle !== "SUPERSEE AP" || tarif_oeil_gauche.indice !== 1.74)
    ) {
      // #9123 passer a 10 pourcent
      price = price * 0.9;
    }
    return parseFloat(price.toFixed(2));
  };

  const remise_monture = find(
    montures,
    (monture) =>
      parseInt(monture.reseau_id) === parseInt(reseau.reseau_id) &&
      (!monture.montant_maxi || parseFloat(monture.montant_maxi) >= parseFloat(correction.price)) &&
      (!monture.montant_mini || parseFloat(monture.montant_mini) < parseFloat(correction.price))
  );

  const compare = (op, v1, v2) => {
    return (
      (!v1 && v1 !== 0) ||
      (!v2 && v2 !== 0) ||
      (op === "<="
        ? v1 <= v2
        : op === ">="
        ? v1 >= v2
        : op === "<"
        ? v1 < v2
        : op === ">"
        ? v1 > v2
        : op === "="
        ? v1 === v2
        : false)
    );
  };

  const filtered_rg_og = filter(regle_gestions, (rg) => {
    if (correction.moins_16 && reseau.code === "KALIXIA") {
      return (
        rg.moins_16 &&
        rg.reseau_id === reseau.reseau_id &&
        compare(rg.sphere_ope_bas, Math.abs(correction.og_sphere), rg.sphere_valeur_bas) &&
        compare(rg.sphere_ope_haut, Math.abs(correction.og_sphere), rg.sphere_valeur_haut) &&
        compare(rg.cylindre_ope_bas, Math.abs(correction.og_cylindre), rg.cylindre_valeur_bas) &&
        compare(rg.cylindre_ope_haut, Math.abs(correction.og_cylindre), rg.cylindre_valeur_haut)
      );
    } else if (!correction.moins_16 && reseau.code === "KALIXIA") {
      return (
        !rg.moins_16 &&
        rg.reseau_id === reseau.reseau_id &&
        compare(rg.sphere_ope_bas, Math.abs(correction.og_sphere), rg.sphere_valeur_bas) &&
        compare(rg.sphere_ope_haut, Math.abs(correction.og_sphere), rg.sphere_valeur_haut) &&
        compare(rg.cylindre_ope_bas, Math.abs(correction.og_cylindre), rg.cylindre_valeur_bas) &&
        compare(rg.cylindre_ope_haut, Math.abs(correction.og_cylindre), rg.cylindre_valeur_haut)
      );
    }
    return (
      rg.reseau_id === reseau.reseau_id &&
      compare(rg.sphere_ope_bas, Math.abs(correction.og_sphere), rg.sphere_valeur_bas) &&
      compare(rg.sphere_ope_haut, Math.abs(correction.og_sphere), rg.sphere_valeur_haut) &&
      compare(rg.cylindre_ope_bas, Math.abs(correction.og_cylindre), rg.cylindre_valeur_bas) &&
      compare(rg.cylindre_ope_haut, Math.abs(correction.og_cylindre), rg.cylindre_valeur_haut)
    )
    });

    const filtered_rg_od = filter(regle_gestions, (rg) => {
      if (correction.moins_16 && reseau.code === "KALIXIA") {
        return (
          rg.moins_16 &&
          rg.reseau_id === reseau.reseau_id &&
          compare(rg.sphere_ope_bas, Math.abs(correction.od_sphere), rg.sphere_valeur_bas) &&
          compare(rg.sphere_ope_haut, Math.abs(correction.od_sphere), rg.sphere_valeur_haut) &&
          compare(rg.cylindre_ope_bas, Math.abs(correction.od_cylindre), rg.cylindre_valeur_bas) &&
          compare(rg.cylindre_ope_haut, Math.abs(correction.od_cylindre), rg.cylindre_valeur_haut)
        );
      } else if (!correction.moins_16 && reseau.code === "KALIXIA") {
        return (
          !rg.moins_16 &&
          rg.reseau_id === reseau.reseau_id &&
          compare(rg.sphere_ope_bas, Math.abs(correction.od_sphere), rg.sphere_valeur_bas) &&
          compare(rg.sphere_ope_haut, Math.abs(correction.od_sphere), rg.sphere_valeur_haut) &&
          compare(rg.cylindre_ope_bas, Math.abs(correction.od_cylindre), rg.cylindre_valeur_bas) &&
          compare(rg.cylindre_ope_haut, Math.abs(correction.od_cylindre), rg.cylindre_valeur_haut)
        );
      }
      return (
      rg.reseau_id === reseau.reseau_id &&
      compare(rg.sphere_ope_bas, Math.abs(correction.od_sphere), rg.sphere_valeur_bas) &&
      compare(rg.sphere_ope_haut, Math.abs(correction.od_sphere), rg.sphere_valeur_haut) &&
      compare(rg.cylindre_ope_bas, Math.abs(correction.od_cylindre), rg.cylindre_valeur_bas) &&
      compare(rg.cylindre_ope_haut, Math.abs(correction.od_cylindre), rg.cylindre_valeur_haut)
      )
    });

  return (
    <BaseLayout>
      <form className="form-list-verres" onSubmit={handleSubmit(submit)} name="modeleVerreForm">
        <div className="workspace-header">
          <div className="grid-noGutter">
            <Field
              name="oeil"
              component={RadioField}
              // input={{ value: "gauche"}} // to fix
              options={{
                droit: `OD : ${correction.od_sphere > 0 ? "+" : ""}${correction.od_sphere} ${
                  correction.od_cylindre > 0 ? "(+" : "("
                }${correction.od_cylindre})`,
                gauche: `OG : ${correction.og_sphere > 0 ? "+" : ""}${correction.og_sphere} ${
                  correction.og_cylindre > 0 ? "(+" : "("
                }${correction.og_cylindre})`,
              }}
              placeholder=""
              label=""
              className="col-0 radioToggleWrapper"
              classNameGroup="radio-toggle"
            />
            <div className="col-0">
              <Field
                name="type_verre"
                component={RadioField}
                options={TYPE_VERRES}
                placeholder=""
                label=""
                className="radioToggleWrapper"
                classNameGroup="radio-toggle"
              />
              {/* // enlever pour photochromique mais on laisse pour polariss */}
              {type_verre && type_verre === "polarises" && (
                <Field
                  name="verre_couleur"
                  component={RadioField}
                  options={VERRES_COULEURS}
                  placeholder=""
                  label=""
                  className="radioToggleWrapper radioToggle-couleurs"
                  classNameGroup="radio-toggle"
                />
              )}
            </div>
            <Field
              name="plus"
              component={ToggleField}
              placeholder=""
              fontIcon="far fa-plus"
              label="test"
              className="col-0"
              fieldClassName="radio-toggle_single_icon"
            />
          </div>
        </div>

        <div className="verresWrapper">
          <ModeleVerreField
            oeil={oeil}
            tarifs={tarifs}
            reseau={reseau}
            correction={correction}
            verre_couleur={verre_couleur}
            type_verre={type_verre}
            plus={plus}
            gammes={gammes}
            regle_gestions={filtered_rg_od}
            marque={marque}
            name="od_modele_verre"
            side="droit"
            cylindre={correction.od_cylindre}
            sphere={correction.od_sphere}
            showAppairageMessage={showAppairageMessage}
            setMaxIndice={(maxIndice) => setShowAppairageMessageProps(maxIndice, "droit")}
          />

          <ModeleVerreField
            oeil={oeil}
            tarifs={tarifs}
            reseau={reseau}
            correction={correction}
            verre_couleur={verre_couleur}
            type_verre={type_verre}
            plus={plus}
            gammes={gammes}
            regle_gestions={filtered_rg_og}
            marque={marque}
            name="og_modele_verre"
            side="gauche"
            cylindre={correction.og_cylindre}
            sphere={correction.og_sphere}
            showAppairageMessage={showAppairageMessage}
            setMaxIndice={(maxIndice) => setShowAppairageMessageProps(maxIndice, "gauche")}
          />
        </div>
        <div className="workspace-footer">
          <div className="grid-noBottom-center">
            {/* Colonne de gauche */}
            <div className="absolute_bottom_left">
              <button className="btn btn-big btn-secondary" onClick={() => history.goBack()}>
                <i className="item-icon far fa-long-arrow-left" />
              </button>
            </div>
            {/* Colonne du centre */}
            <div className="liste-choix">
              <div className="grid-4-noGutter-center-equalsHeight">
                {(!isUndefined(correction.price) || !isNull(correction.price)) &&
                  correction.price !== 0 &&
                  correction.price >= 0 && (
                    <div className="col">
                      <div className="box box-choix-verre">
                        <div className="box-header">
                          <div className="box-title">Monture</div>
                        </div>
                        <div className="box-content">
                          <div className="flexbox flexColumn flex-1 justifyCenter">
                            <div className="box-choix-verre-data">
                              <div>Prix brut</div>
                              <div className="box-choix-verre-data-price">{correction.price} €</div>
                            </div>
                            {!isUndefined(remise_monture) && (
                              <div className="box-choix-verre-data">
                                <div>Remise</div>
                                <div className="box-choix-verre-data-price">
                                  {remise_monture &&
                                    ((correction.price * remise_monture.remise) / 100).toFixed(
                                      2
                                    )}{" "}
                                  €
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="box-choix-verre-data highlight-bloc">
                            <div>Prix net</div>
                            <div className="box-choix-verre-data-price">
                              {remise_monture && correction && correction.price
                                ? parseInt(correction.price) -
                                  ((correction.price * remise_monture.remise) / 100).toFixed(2)
                                : correction.price}{" "}
                              €
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {!isNull(id_verre_droit) && (
                  <div className="col">
                    <div className="box box-choix-verre">
                      <div className="box-header">
                        <div className="box-title">Oeil droit</div>
                        <div className="box-subtitle">
                          {tarif_oeil_droit.indice} {tarif_oeil_droit.libelle}
                        </div>
                      </div>
                      <div className="box-content">
                        <div className="flexbox flexColumn flex-1 justifyCenter">
                          <div className="box-choix-verre-data">
                            <div>Code EDI</div>
                            <div className="box-choix-verre-data-price">
                              {tarif_oeil_droit.code_edi}
                            </div>
                          </div>
                        </div>
                        {reseau.code === "KALIXIA" && (
                          <div className="box-choix-verre-data highlight-bloc">
                            <div>Prix net</div>
                            <div className="box-choix-verre-data-price">
                              {parseFloat(
                                tarifKalixiaOeilDroit(
                                  tarif_oeil_droit,
                                  correction,
                                  gammes[tarif_oeil_droit.gamme_id]
                                )
                              ) + parseInt(tarif_oeil_droit.prix_precal)}{" "}
                              €
                            </div>
                          </div>
                        )}

                        {reseau.code !== "KALIXIA" && (
                          <div className="box-choix-verre-data highlight-bloc">
                            <div>Prix net</div>
                            <div className="box-choix-verre-data-price">
                              {parseFloat(tarif_oeil_droit.prix) +
                                parseInt(tarif_oeil_droit.prix_precal)}
                              €
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!isNull(id_verre_gauche) && (
                  <div className="col">
                    <div className="box box-choix-verre">
                      <div className="box-header">
                        <div className="box-title">Oeil gauche</div>
                        <div className="box-subtitle">
                          {tarif_oeil_gauche.indice} {tarif_oeil_gauche.libelle}
                        </div>
                      </div>
                      <div className="box-content">
                        <div className="flexbox flexColumn flex-1 justifyCenter">
                          <div className="box-choix-verre-data">
                            <div>Code EDI</div>
                            <div className="box-choix-verre-data-price">
                              {tarif_oeil_gauche.code_edi}
                            </div>
                          </div>
                        </div>
                        {reseau.code === "KALIXIA" && (
                          <div className="box-choix-verre-data highlight-bloc">
                            <div>Prix net</div>
                            <div className="box-choix-verre-data-price">
                              {parseFloat(
                                tarifKalixiaOeilGauche(
                                  tarif_oeil_gauche,
                                  correction,
                                  gammes[tarif_oeil_gauche.gamme_id]
                                )
                              ) + parseInt(tarif_oeil_gauche.prix_precal)}{" "}
                              €
                            </div>
                          </div>
                        )}
                        {reseau.code !== "KALIXIA" && (
                          <div className="box-choix-verre-data highlight-bloc">
                            <div>Prix net</div>
                            <div className="box-choix-verre-data-price">
                              {parseFloat(tarif_oeil_gauche.prix) +
                                parseInt(tarif_oeil_gauche.prix_precal)}
                              €
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {prisme && (
                  <div className="col">
                    <div className="box box-choix-verre">
                      <div className="box-header">
                        <div className="box-title">Prisme</div>
                      </div>
                      <div className="box-content">
                        <div className="flexbox flexColumn flex-1 justifyCenter">
                          <div className="box-choix-verre-data">
                            <div>Code LPP 2247905 (Adulte)</div>
                          </div>
                        </div>
                        <div className="box-choix-verre-data highlight-bloc">
                          <div>Tarif</div>
                          <div className="box-choix-verre-data-price">{reseau.prix_prisme}€</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseLayout>
  );
};

const selector = formValueSelector("ModeleVerreForm");

export default withRouter(
  connect(
    (state, props) =>  ({
      initialValues: {
        oeil: "droit",
        type_verre: state.data.config.correction.blue === 'N' ? "blancs": "blue",
        verre_couleur: "BR",
      },
      oeil: selector(state, "oeil") || null,
      type_verre: selector(state, "type_verre") || null,
      plus: selector(state, "plus") || null,
      id_verre_gauche: selector(state, "og_modele_verre") || null,
      id_verre_droit: selector(state, "od_modele_verre") || null,
      verre_couleur: selector(state, "verre_couleur") || null,
      correction: state.data.config.correction,
      prisme: state.data.config.correction.prisme,
      reseau: state.data.config.reseau,
      marque: state.data.config.marque,
      tarifs: values(state.data.entities.tarifs),
      gammes: state.data.entities.gammes,
      montures: values(state.data.entities.remisesMontures),
      regle_gestions: state.data.entities.reglesGestion,
    }),
    { loadTarifs, loadGammes, loadReglesGestion, loadRemisesMontures }
  )(
    reduxForm({
      form: "ModeleVerreForm",
      validate: (values = {}) => {
        const errors = {};

        return errors;
      },
    })(Home)
  )
);
