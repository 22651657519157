import { flow, set } from "lodash/fp";
import { omit, isUndefined } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_MARQUES_REQUEST = "arwa/marques/FETCH_ALL_MARQUES_REQUEST";
export const FETCH_ALL_MARQUES_SUCCESS = "arwa/marques/FETCH_ALL_MARQUES_SUCCESS";
const FETCH_ALL_MARQUES_FAILURE = "arwa/marques/FETCH_ALL_MARQUES_FAILURE";
const FETCH_MARQUE_REQUEST = "arwa/marques/FETCH_MARQUE_REQUEST";
const FETCH_MARQUE_SUCCESS = "arwa/marques/FETCH_MARQUE_SUCCESS";
const FETCH_MARQUE_FAILURE = "arwa/marques/FETCH_MARQUE_FAILURE";
const SET_MARQUE_REQUEST = "arwa/marques/SET_MARQUE_REQUEST";
const SET_MARQUE_SUCCESS = "arwa/marques/SET_MARQUE_SUCCESS";
const SET_MARQUE_FAILURE = "arwa/marques/SET_MARQUE_FAILURE";
const DELETE_MARQUE_REQUEST = "arwa/marques/DELETE_MARQUE_REQUEST";
const DELETE_MARQUE_SUCCESS = "arwa/marques/DELETE_MARQUE_SUCCESS";
const DELETE_MARQUE_FAILURE = "arwa/marques/DELETE_MARQUE_FAILURE";

export const marquesActionsHandlers = {
  [FETCH_ALL_MARQUES_REQUEST]: (state) =>
    flow(set("loaded.marques", false), set("loading.marques", true))(state),
  [FETCH_MARQUE_REQUEST]: (state) => {
    console.log("fetch marque");
    return flow(set("loaded.marques", false), set("loading.marques", true))(state);
  },
  [FETCH_ALL_MARQUES_SUCCESS]: (state, action) => {
    return flow(
      set("entities.marques", action.response.entities.marques || {}),
      set("loaded.marques", true),
      set("loading.marques", false)
    )(state);
  },
  [FETCH_ALL_MARQUES_FAILURE]: (state) =>
    flow(set("loaded.marques", false), set("loading.marques", false))(state),
  [FETCH_MARQUE_FAILURE]: (state) =>
    flow(set("loaded.marques", false), set("loading.marques", false))(state),
  [FETCH_MARQUE_SUCCESS]: (state, action) => {
    console.log("marque success");
    console.log(action.id);
    console.log(action.response.entities.marques[action.id]);
    return flow(
      set(`entities.marques.${action.id}`, action.response.entities.marques[action.id]),
      set("loaded.marques", true),
      set("loading.marques", false)
    )(state);
  },
  [SET_MARQUE_REQUEST]: (state) =>
    flow(set("loaded.marques", false), set("loading.marques", true))(state),
  [SET_MARQUE_SUCCESS]: (state, action) =>
    flow(
      set("entities.marques", {
        ...state.entities.marques,
        ...action.response.entities.marques,
      }),
      set("loaded.marques", true),
      set("loading.marques", false)
    )(state),
  [SET_MARQUE_FAILURE]: (state, action) =>
    flow(set("loaded.marques", false), set("loading.marques", false))(state),
  [DELETE_MARQUE_SUCCESS]: (state, action) =>
    flow(set("entities.marques", omit(state.entities.marques, action.id)))(state),
};

export function loadMarque(id) {
  console.log("load marque");
  return {
    id,
    [CALL_API]: {
      types: [FETCH_MARQUE_REQUEST, FETCH_MARQUE_SUCCESS, FETCH_MARQUE_FAILURE],
      method: "GET",
      endpoint: `/marques/${id}`,
      schema: Schemas.MARQUE,
      successMessage: "La marque a bien été chargée",
    },
  };
}

export function loadMarques() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_MARQUES_REQUEST, FETCH_ALL_MARQUES_SUCCESS, FETCH_ALL_MARQUES_FAILURE],
      method: "GET",
      endpoint: "/marques",
      schema: Schemas.MARQUE_ARRAY,
    },
  };
}

export function createOrUpdateMarque(marque) {
  console.log("----------------------------------->marque", marque);

  return {
    [CALL_API]: {
      types: [SET_MARQUE_REQUEST, SET_MARQUE_SUCCESS, SET_MARQUE_FAILURE],
      method:
        !marque ||
        (!isUndefined(marque.marque_id) && !Number.isInteger(marque.marque_id)) ||
        isUndefined(marque.marque_id)
          ? "POST"
          : "PUT",
      endpoint:
        !marque ||
        (!isUndefined(marque.marque_id) && !Number.isInteger(marque.marque_id)) ||
        isUndefined(marque.marque_id)
          ? "/marques"
          : `/marques/${marque.marque_id}`,
      schema: Schemas.MARQUE,
      body: marque,
      successMessage: "La marque est bien enregistrée",
    },
  };
}

export function deleteMarque(id) {
  return {
    id,
    [CALL_API]: {
      types: [DELETE_MARQUE_REQUEST, DELETE_MARQUE_SUCCESS, DELETE_MARQUE_FAILURE],
      method: "DELETE",
      endpoint: `/marques/${id}`,
      successMessage: "La marque a bien été supprimée",
    },
  };
}
