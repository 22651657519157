import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InputField, RadioField, ToggleField, Field } from "react-components-linaia";
import { reduxForm, formValueSelector, change, reset } from "redux-form";
import { values, isUndefined } from "lodash";
import { withRouter } from "react-router-dom";

import BaseLayout from "../../containers/shared/BaseLayout";
import { CORRECTION , ANTIREFLET } from "../../constants/Properties";
import { setConfig } from "../../store/modules/globals";

const CorrectionForm = ({
  history: { push },
  history,
  reseaux,
  typeCorrection,
  handleSubmit,
  loadReseaux,
  setConfig,
  reset,
  change,
  od_sphere,
  od_cylindre,
  od_addition,
}) => {
  useEffect(() => {
    async function fetchData() {
      // You can await here
      // ...
    }
    fetchData();
  }, []);

  const submit = (values, dispatch, props) => {
    return Promise.resolve()
      .then(() => props.setConfig("correction", props.values))
      .then(() => push(`/modele-verre`));
  };

  function duplicate() {
    change("og_sphere", od_sphere);
    change("og_cylindre", od_cylindre);
    change("og_addition", od_addition);
  }

  function clearAll() {
    change("og_sphere", null);
    change("og_cylindre", null);
    change("od_sphere", null);
    change("od_cylindre", null);
    change("price", null);
    change("type_correction", "U");
    change("prisme", false);
  }

  const resetSign_od_sphere = () => change("od_sphere", "");
  const resetSign_od_cylindre = () => change("od_cylindre", "");
  const resetSign_og_sphere = () => change("og_sphere", "");
  const resetSign_og_cylindre = () => change("og_cylindre", "");

  const decimalReplacer = (value) => value.replace(",", ".");

  return (
    <BaseLayout>
      <form className="" onSubmit={handleSubmit(submit)} name="correctionForm">
        <div className="gridWrapper">
          <div className="grid-spaceBetween-middle">
            <div className="col">
              <div className="grid-noBottom">
                <Field
                  name="type_correction"
                  component={RadioField}
                  options={CORRECTION}
                  placeholder=""
                  label=""
                  className="col-0"
                  classNameGroup="radio-toggle"
                />

                <div className="col-0">
                  <button
                    class="btn btn-tertiary"
                    type="button"
                    onClick={() => {
                      return Promise.resolve().then(() => clearAll());
                    }}
                  >
                    <i class="fas fa-trash-alt"></i>
                    <span>Clear All</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-0">
              <div className="grid-noBottom">
                <div className="col-0">
                  <div className="box box-form">
                    <Field
                      name="prisme"
                      component={ToggleField}
                      placeholder=""
                      className="box-content"
                      fieldClassName="checkbox"
                      label="Prisme"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col_xs-12">
              <div className="box box-form">
                <div className="box-header">
                  <div className="box-title">Oeil droit</div>
                </div>
                <div className="box-content">
                  <button
                    className="btn btn-tertiary btn-duplicate"
                    type="button"
                    onClick={() => duplicate()}
                  >
                    <i className="fas fa-copy" />
                    <span>Dupliquer</span>
                  </button>
                  <div className="grid">
                    <Field
                      name="od_sphere"
                      component={InputField}
                      placeholder=""
                      onFocus={() => resetSign_od_sphere()}
                      type="text"
                      inputmode="decimal"
                      normalize={decimalReplacer}
                      step="0.25"
                      label="Sphère"
                      className="col-12"
                      fieldClassName="field inline wrap"
                    />
                    <Field
                      name="od_cylindre"
                      component={InputField}
                      placeholder=""
                      onFocus={() => resetSign_od_cylindre()}
                      type="text"
                      inputmode="decimal"
                      normalize={decimalReplacer}
                      step="0.25"
                      label="Cylindre (+)"
                      className="col-12"
                      fieldClassName="field inline wrap"
                    />

                    {typeCorrection && typeCorrection === "P" && (
                      <Field
                        name="od_addition"
                        component={InputField}
                        placeholder=""
                        type="text"
                        inputmode="decimal"
                        normalize={decimalReplacer}
                        step="0.25"
                        label="Addition"
                        className="col-12"
                        fieldClassName="field inline wrap"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col_xs-12">
              <div className="box box-form">
                <div className="box-header">
                  <div className="box-title">Oeil gauche</div>
                </div>
                <div className="box-content">
                  <div className="grid">
                    <Field
                      name="og_sphere"
                      component={InputField}
                      placeholder=""
                      onFocus={() => resetSign_og_sphere()}
                      type="text"
                      inputmode="decimal"
                      normalize={decimalReplacer}
                      step="0.25"
                      label="Sphère"
                      className="col-12"
                      fieldClassName="field inline wrap"
                    />
                    <Field
                      name="og_cylindre"
                      component={InputField}
                      placeholder=""
                      onFocus={() => resetSign_og_cylindre()}
                      type="text"
                      inputmode="decimal"
                      normalize={decimalReplacer}
                      step="0.25"
                      label="Cylindre (+)"
                      className="col-12"
                      fieldClassName="field inline wrap"
                    />
                    {typeCorrection && typeCorrection === "P" && (
                      <Field
                        name="od_addition"
                        component={InputField}
                        type="text"
                        inputmode="decimal"
                        normalize={decimalReplacer}
                        placeholder=""
                        label=""
                        disabled
                        step="0.25"
                        label="Addition"
                        className="col-12"
                        fieldClassName="field inline wrap"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col_xs-12">
              <div className="box box-form">
                <div className="box-header">
                  <div className="box-title">Monture</div>
                </div>
                <div className="box-content">
                  <div className="grid">
                    <Field
                      name="price"
                      component={InputField}
                      type="text"
                      inputmode="decimal"
                      normalize={decimalReplacer}
                      min="0"
                      placeholder=""
                      step="0.01"
                      label="Prix de vente"
                      className="col-12"
                      fieldClassName="field inline wrap"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="question-subsidiaire">
          <div>
            <i className="fas  fa-exclamation-triangle" />
            Le client souhaite t-il l'antireflet lumière bleue ?
          </div>
          <Field name="blue" component={RadioField} className="radio-light" options={ANTIREFLET} />
        </div>
        <div className="workspace-footer">
          <div className="grid-center-noBottom">
            {/* Colonne de gauche */}
            <div className="col flexbox">
              <span className="btn btn-big btn-secondary" onClick={() => history.goBack()}>
                <i className="item-icon far fa-long-arrow-left" />
              </span>
            </div>
            {/* Colonne de droite */}
            <div className="col flexbox justifyEnd">
              <button className="btn btn-primary btn-big" type="submit">
                <span>Suivant</span>
                <i className="far fa-long-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </BaseLayout>
  );
};

const selector = formValueSelector("CorrectionForm");

export default withRouter(
  connect(
    (state) => ({
      typeCorrection: selector(state, "type_correction"),
      og_sphere: selector(state, "og_sphere"),
      og_cylindre: selector(state, "og_cylindre"),
      og_addition: selector(state, "og_addition"),
      od_sphere: selector(state, "od_sphere"),
      od_cylindre: selector(state, "od_cylindre"),
      od_addition: selector(state, "od_addition"),
      initialValues: {
        ...state.data.config.correction,
        type_correction: "U",
      },
    }),
    { setConfig, change }
  )(
    reduxForm({
      form: "CorrectionForm",
      validate: (values = {}) => {
        const errors = {};

        if (values.og_sphere && (100 * parseFloat(values.og_sphere)) % 25 !== 0)
          errors.og_sphere = "le pas doit être de 0.25";
        if (values.og_cylindre && (100 * parseFloat(values.og_cylindre)) % 25 !== 0)
          errors.og_cylindre = "le pas doit être de 0.25";
        if (values.od_sphere && (100 * parseFloat(values.od_sphere)) % 25 !== 0)
          errors.od_sphere = "le pas doit être de 0.25";
        if (values.od_cylindre && (100 * parseFloat(values.od_cylindre)) % 25 !== 0)
          errors.od_cylindre = "le pas doit être de 0.25";
        if (values.od_addition && (100 * parseFloat(values.od_addition)) % 25 !== 0)
          errors.od_addition = "le pas doit être de 0.25";
        if (values.price && (100 * parseFloat(values.price)) % 1 !== 0)
          errors.price = "le pas doit être de 0.01";
        if (isUndefined(values.og_sphere)) errors.og_sphere = "Champ requis";
        if (isUndefined(values.og_cylindre)) errors.og_cylindre = "Champ requis";
        if (isUndefined(values.od_sphere)) errors.od_sphere = "Champ requis";
        if (isUndefined(values.od_cylindre)) errors.od_cylindre = "Champ requis";
        if (isUndefined(values.od_addition)) errors.od_addition = "Champ requis";
        if (isUndefined(values.blue))
          errors.blue = "Veuillez répondre à la question avant de passer à l'étape suivante";

        return errors;
      },
    })(CorrectionForm)
  )
);
