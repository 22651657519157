import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { capitalize, get, orderBy } from "lodash";
import { connect } from "react-redux";
import { ButtonConfirmModal } from "react-components-linaia";

import TableListView from "../../shared/TableListView";

const ReglesTableListView = ({
  history: { push },
  reglesGestion,
  reseaux,
  baseUrl,
  onClickDelete,
}) => (
  <TableListView
    className="table-boxed full-height"
    data={reglesGestion}
    cols={{
      reseau_id: {
        label: "Réseau",
        className: "col",
        value: (reseau_id) => get(reseaux, `${reseau_id}.nom`),
      },
      sphere_ope_bas: {
        className: "col-1_sm-1 text-center col-operand",
        value: capitalize,
      },
      sphere_valeur_bas: {
        label: "Sphère bas",
        className: "col text-center",
        value: capitalize,
      },
      sphere_ope_haut: {
        className: "col-1_sm-1 text-center col-operand",
        value: capitalize,
      },
      sphere_valeur_haut: {
        label: "Sphère haut",
        className: "col text-center",
        value: capitalize,
      },
      cylindre_ope_bas: {
        className: "col-1_sm-1 text-center col-operand",
        value: capitalize,
      },

      cylindre_valeur_bas: {
        label: "Cylindre bas",
        className: "col text-center",
        value: capitalize,
      },
      cylindre_ope_haut: {
        className: "col-1_sm-1 text-center col-operand",
        value: capitalize,
      },

      cylindre_valeur_haut: {
        label: "Cylindre haut",
        className: "col text-center",
        value: capitalize,
      },
      indice: {
        label: "Indice",
        className: "col-1_sm-1 text-center ",
        value: capitalize,
      },
      moins_16: {
        label: "- 16 ans",
        className: "col-1_sm-1 text-center ",
        render: (value, row) => {
          return (
            <div key={row.regle_gestion_id} className={"field checkbox"}>
              <input id={row.regle_gestion_id} type="checkbox" disabled checked={value} />
              <label htmlFor={row.regle_gestion_id}></label>
            </div>
          );
        },
        // value: capitalize,
      },
    }}
    search={["reseau_id"]}
    searchLabel={"Rechercher une règle"}
    sortByDefault="regle_gestion_id"
    sortOrderDefault="asc"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <i className="far fa-plus" />
          <span>Ajouter une règle</span>
        </NavLink>
      </div>
    }
    rowButtons={(regleGestion) => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {regleGestion && (
            <NavLink className="btn" to={`${baseUrl}/${regleGestion.regle_gestion_id}/edit`}>
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {regleGestion && (
            <ButtonConfirmModal
              onConfirmationButton={() => onClickDelete(regleGestion.regle_gestion_id)}
              message="Voulez-vous vraiment supprimer cette règle ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

ReglesTableListView.propTypes = {
  reglesGestion: PropTypes.object,
  reseaux: PropTypes.object,
  isAdmin: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect((state, { regleGestionId }) => ({
  reglesGestion: state.data.entities.reglesGestion,
  reseaux: state.data.entities.reseaux,
  regleGestionId,
}))(withRouter(ReglesTableListView));
