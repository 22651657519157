import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Field, InputField, SelectField } from "react-components-linaia";

import { ACCOUNTS_ROLES } from "../../constants/Properties";

const AccountEditForm = ({ handleSubmit, isMyAccount }) => (
  <form onSubmit={handleSubmit}>
    <div className="grid">
      <div className="col-12">
        <Field
          name="username"
          component={InputField}
          placeholder="Identifiant"
          label="Identifiant"
          disabled={isMyAccount}
        />
      </div>
      <div className="col-12">
        <Field name="email" component={InputField} placeholder="Email" label="Email" type="email" />
      </div>

      <div className="col-12">
        <Field
          name="roles"
          component={SelectField}
          placeholder="Rôles"
          label="Rôles"
          options={ACCOUNTS_ROLES}
          isMulti
          classNamePrefix="react-select"
        />
      </div>

      {/*    <div className="col-12">
        <Field
          name="password"
          component={InputField}
          placeholder="Mot de passe"
          label="Mot de passe"
          type="password"
        />
      </div> */}
    </div>
    <div className="btn-group right">
      <button className="btn" type="submit">
        Enregistrer
      </button>
    </div>
  </form>
);

AccountEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,

  isMyAccount: PropTypes.bool.isRequired
};

export default reduxForm({
  form: "AccountEditForm",
  validate: (values = {}) => {
    const errors = {};

    if (!values.username) errors.username = "Must be set";
    if (!values.email) errors.email = "Must be set";

    return errors;
  }
})(AccountEditForm);
