import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InputField, Field, SelectField } from "react-components-linaia";
import { reduxForm, reset } from "redux-form";
import { values, map, filter } from "lodash";

import BaseLayout from "../../containers/shared/BaseLayout";

import { CORRECTION } from "../../constants/Properties";
import { setConfig } from "../../store/modules/globals";
import { loadOptions } from "../../store/modules/options";

const submit = (values, dispatch, props) => {
  props.setConfig(
    "option",
    props.reseaux.find(r => r.reseau_id === props.values.reseau)
  );
  // props.setConfig('correction', props.values);
  // props.history.push(`/modele-verre`);
};

const OptionForm = ({ reseau, loadOptions, options, handleSubmit, loadReseaux }) => {
  useEffect(() => {
    async function fetchData() {
      await loadOptions();
      // You can await here
      // ...
    }
    fetchData();
  }, []);

  return (
    <BaseLayout>
      <div className="gridWrapper">
        <div className="box">
          <div className="table">
            <div className="thead">
              <div className="tr">
                <div className="grid">
                  <div className="col">
                    <div className="th">Options (tarif par verre)</div>
                  </div>
                  <div className="col-4">
                    <div className="th">Verre unifocal</div>
                  </div>
                  <div className="col-4">
                    <div className="th">Verre progressif</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tbody">
              {map(options, option => {
                return (
                  <div className="tr">
                    <div className="grid">
                      <div className="col">
                        <div className="td">{option.libelle}</div>
                      </div>
                      <div className="col-4">
                        <div className="td highlight">{option.tarif_unifocaux}</div>
                      </div>
                      <div className="col-4">
                        <div className="td highlight">{option.tarif_progressifs}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default connect(
  state => ({
    reseau: state.data.config.reseau,
    options: filter(values(state.data.entities.options), [
      "reseau_id",
      state.data.config.reseau.reseau_id
    ])
  }),
  { setConfig, loadOptions }
)(
  reduxForm({
    form: "OptionForm",
    validate: (values = {}) => {
      const errors = {};

      // if (!values.og_sphere) errors.og_sphere = 'Champ requis';
      // if (!values.og_cylindre) errors.og_cylindre = 'Champ requis';
      // if (!values.od_sphere) errors.od_sphere = 'Champ requis';
      // if (!values.od_cylindre) errors.od_cylindre = 'Champ requis';

      return errors;
    }
  })(OptionForm)
);
