import { flow, set } from "lodash/fp";
import { omit, isUndefined } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_SERVICES_REQUEST = "ksa/services/FETCH_ALL_SERVICES_REQUEST";
export const FETCH_ALL_SERVICES_SUCCESS = "kwa/services/FETCH_ALL_SERVICES_SUCCESS";

const FETCH_ALL_SERVICES_FAILURE = "ksa/services/FETCH_ALL_SERVICES_FAILURE";

const FETCH_SERVICE_REQUEST = "ksa/services/FETCH_SERVICE_REQUEST";
const FETCH_SERVICE_SUCCESS = "ksa/services/FETCH_SERVICE_SUCCESS";
const FETCH_SERVICE_FAILURE = "ksa/services/FETCH_SERVICE_FAILURE";

const SET_SERVICE_REQUEST = "ksa/services/SET_SERVICE_REQUEST";
const SET_SERVICE_SUCCESS = "ksa/services/SET_SERVICE_SUCCESS";
const SET_SERVICE_FAILURE = "ksa/services/SET_SERVICE_FAILURE";

const DELETE_SERVICE_REQUEST = "ksa/services/DELETE_SERVICE_REQUEST";
const DELETE_SERVICE_SUCCESS = "ksa/services/DELETE_SERVICE_SUCCESS";
const DELETE_SERVICE_FAILURE = "ksa/services/DELETE_SERVICE_FAILURE";

export const servicesActionsHandlers = {
  [FETCH_ALL_SERVICES_REQUEST]: (state) =>
    flow(set("loaded.services", false), set("loading.services", true))(state),
  [FETCH_SERVICE_REQUEST]: (state) => {
    return flow(set("loaded.services", false), set("loading.services", true))(state);
  },
  [FETCH_ALL_SERVICES_SUCCESS]: (state, action) => {
    return flow(
      set("entities.services", action.response.entities.services || {}),
      set("loaded.services", true),
      set("loading.services", false)
    )(state);
  },

  [FETCH_ALL_SERVICES_FAILURE]: (state) =>
    flow(set("loaded.services", false), set("loading.services", false))(state),
  [FETCH_SERVICE_FAILURE]: (state) =>
    flow(set("loaded.services", false), set("loading.services", false))(state),

  [FETCH_SERVICE_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.services.${action.id}`, action.response.entities.services[action.id]),
      set("loaded.services", true),
      set("loading.services", false)
    )(state);
  },

  [SET_SERVICE_REQUEST]: (state) =>
    flow(set("loaded.services", false), set("loading.services", true))(state),
  [SET_SERVICE_SUCCESS]: (state, action) =>
    flow(
      set("entities.services", {
        ...state.entities.services,
        ...action.response.entities.services,
      }),
      set("loaded.services", true),
      set("loading.services", false)
    )(state),
  [SET_SERVICE_FAILURE]: (state, action) =>
    flow(set("loaded.services", false), set("loading.services", false))(state),

  [DELETE_SERVICE_SUCCESS]: (state, action) =>
    flow(set("entities.services", omit(state.entities.services, action.id)))(state),
};

export function loadService(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_SERVICE_REQUEST, FETCH_SERVICE_SUCCESS, FETCH_SERVICE_FAILURE],
      method: "GET",
      endpoint: `/services/${id}`,
      schema: Schemas.SERVICE,
      successMessage: "Le service a bien été chargé",
    },
  };
}

export function loadServices() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_SERVICES_REQUEST, FETCH_ALL_SERVICES_SUCCESS, FETCH_ALL_SERVICES_FAILURE],
      method: "GET",
      endpoint: "/services",
      schema: Schemas.SERVICE_ARRAY,
    },
  };
}

export function createOrUpdateService(service) {
  console.log("createOrUpdateService()", service.service_id);

  return {
    [CALL_API]: {
      types: [SET_SERVICE_REQUEST, SET_SERVICE_SUCCESS, SET_SERVICE_FAILURE],
      method: !service || !Number.isInteger(service.service_id) ? "POST" : "PUT",
      endpoint:
        !service || !Number.isInteger(service.service_id)
          ? "/services"
          : `/services/${service.service_id}`,
      schema: Schemas.SERVICE,
      body: service,
      successMessage: "Le service à bien été enregistré",
    },
  };
}

export function deleteService(id) {
  console.log("deleteService() id===", id);
  return {
    id,
    [CALL_API]: {
      types: [DELETE_SERVICE_REQUEST, DELETE_SERVICE_SUCCESS, DELETE_SERVICE_FAILURE],
      method: "DELETE",
      endpoint: `/services/${id}`,
      successMessage: "Le service a été supprimé avec succès",
    },
  };
}
