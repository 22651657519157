import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { capitalize, get, orderBy } from "lodash";
import { connect } from "react-redux";
import { ButtonConfirmModal } from "react-components-linaia";

import TableListView from "../../shared/TableListView";

import { UPLOADS_URL } from "../../../constants/Config";

const GarantiesTableListView = ({
  history: { push },
  garanties,
  reseaux,
  baseUrl,
  onClickDelete,
  isAdmin
}) => (
  <TableListView
    className="table-boxed full-height"
    data={garanties}
    cols={{
      reseau_id: {
        label: "Nom du réseau",
        className: "col",
        value: reseau_id => get(reseaux, `${reseau_id}.nom`)
      },

      libelle: {
        label: "Libelle",
        className: "col-3_sm-12",
        value: capitalize
      },
      garantie: {
        label: "Garantie",
        className: "col-3_sm-12",
        value: capitalize
      }
    }}
    search={["reseau_id", "libelle", "garantie"]}
    searchLabel={"Rechercher une garantie"}
    sortByDefault="documentStartDate"
    sortOrderDefault="desc"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <i className="far fa-plus" />
          <span>Ajouter une garantie</span>
        </NavLink>
      </div>
    }
    rowButtons={garantie => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {garantie && (
            <NavLink className="btn" to={`${baseUrl}/${garantie.garantie_id}/edit`}>
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {garantie && (
            <ButtonConfirmModal
              onConfirmationButton={() => onClickDelete(garantie.garantie_id)}
              message="Voulez-vous vraiment supprimer cette garantie ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

GarantiesTableListView.propTypes = {
  garanties: PropTypes.object,
  reseaux: PropTypes.object,
  isAdmin: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect((state, { garantieID }) => ({
  garanties: state.data.entities.garanties,
  reseaux: state.data.entities.reseaux,
  garantieID
}))(withRouter(GarantiesTableListView));
