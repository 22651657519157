import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";
import { isUndefined } from "lodash";

import { loadOptions } from "../../../store/modules/options";
import { Field, InputField, SelectField } from "react-components-linaia";

import { uploadFiles, removeFile } from "../../../store/modules/uploads";

const OptionEditForm = ({ handleSubmit, baseUrl, initialValues, reseaux }) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.option_id >= 0 ? "Modifier l'option" : "Ajouter une option"}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="reseau_id"
            component={SelectField}
            options={reseaux}
            optionsKeys={{ value: "reseau_id", label: "nom" }}
            label="Reseau"
            className="col-12"
            classNamePrefix="react-select"
          />

          <Field name="libelle" component={InputField} label="Libelle" className="col-12" />

          <Field
            name="tarif_unifocaux"
            component={InputField}
            label="Tarif unifocaux"
            className="col-6"
          />
          <Field
            name="tarif_progressifs"
            component={InputField}
            label="Tarif progressif"
            className="col-6"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-tertiary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

OptionEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired
};

export default reduxForm({
  form: "OptionEditForm",
  validate: (values = {}) => {
    const errors = {};
    if (isUndefined(values.reseau_id)) errors.reseau_id = "Merci de renseigner un réseau";
    if (!values.libelle) errors.libelle = "Merci de renseigner un libelle";
    if (!values.tarif_unifocaux) errors.tarif_unifocaux = "Merci de renseigner le tarif unifocaux";
    if (!values.tarif_progressifs)
      errors.tarif_progressifs = "Merci de renseigner le tarif progressif";
    return errors;
  }
})(
  connect(
    (state, { optionId }) => ({
      options: state.data.entities.options,
      reseaux: state.data.entities.reseaux
    }),
    { upFiles: uploadFiles, rmFile: removeFile, loadOptions }
  )(OptionEditForm)
);
