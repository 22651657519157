import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { capitalize, get, orderBy } from "lodash";
import { connect } from "react-redux";
import { ButtonConfirmModal } from "react-components-linaia";

import TableListView from "../../shared/TableListView";

const AdministrateursTableListView = ({
  history: { push },
  accounts,
  baseUrl,
  onClickDelete,
  isAdmin
}) => (
  <TableListView
    className="table-boxed full-height"
    data={accounts}
    cols={{
      account_id: {
        label: "Nom d'utilisateur",
        className: "col-6_sm-12",
        value: account_id => get(accounts, `${account_id}.username`)
      },
      email: {
        label: "E-mail",
        className: "col-5_sm-12",
        value: value => value
      }
    }}
    search={["account_id", "email", "username"]}
    searchLabel={"Rechercher un administrateur"}
    sortByDefault="documentStartDate"
    sortOrderDefault="desc"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <i className="far fa-plus" />
          <span>Ajouter un admin</span>
        </NavLink>
      </div>
    }
    rowButtons={account => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {account && (
            <NavLink className="btn" to={`${baseUrl}/${account.account_id}/edit`}>
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {account && (
            <ButtonConfirmModal
              onConfirmationButton={() => onClickDelete(account.account_id)}
              message="Voulez-vous vraiment supprimer cet administrateur ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

AdministrateursTableListView.propTypes = {
  isAdmin: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(state => ({
  accounts: state.data.entities.accounts
}))(withRouter(AdministrateursTableListView));
