import { accountsActionsHandlers } from "./accounts";
import { optionsActionsHandlers } from "./options";
import { modelsActionsHandlers } from "./models";
import { uploadContentsActionsHandlers } from "./uploadContents";
import { postsActionsHandlers } from "./posts";
import { tarifsActionsHandlers } from "./tarifs";
import { reglesGestionActionsHandlers } from "./reglesGestion";
import { remisesMonturesActionsHandlers } from "./remisesMontures";
import { gammesActionsHandlers } from "./gammes";
import { imagesActionsHandlers } from "./images";
import { garantiesActionsHandlers } from "./garanties";
import { servicesActionsHandlers } from "./services";
import { optionVerresActionsHandlers } from "./optionVerres";
import { reseauxActionsHandlers } from "./reseaux";
import { appSettingsActionsHandlers } from "./appSettings";
import { marquesActionsHandlers } from "./marques";
import initialState from "../initialState";
import { uploadsActionsHandlers } from "./uploads";
import { socketActionsHandlers } from "./socket";
import { globalsActionsHandlers } from "./globals";

const actionsHandlers = {
  ...accountsActionsHandlers,
  ...optionsActionsHandlers,
  ...uploadsActionsHandlers,
  ...modelsActionsHandlers,
  ...uploadContentsActionsHandlers,
  ...postsActionsHandlers,
  ...socketActionsHandlers,
  ...globalsActionsHandlers,
  ...tarifsActionsHandlers,
  ...reglesGestionActionsHandlers,
  ...remisesMonturesActionsHandlers,
  ...gammesActionsHandlers,
  ...imagesActionsHandlers,
  ...garantiesActionsHandlers,
  ...servicesActionsHandlers,
  ...optionVerresActionsHandlers,
  ...reseauxActionsHandlers, 
  ...appSettingsActionsHandlers, 
  ...marquesActionsHandlers
};

export default function reducer(state = initialState, action) {
  const reduceFn = actionsHandlers[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
