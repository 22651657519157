import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, RadioField } from "react-components-linaia";
import { Field as RFField, reduxForm, formValueSelector } from "redux-form";
import { values, isEmpty, filter, map, includes, keys, pickBy, find } from "lodash";
import {
  TYPE_IMAGES,
  TYPE_IMAGES_2EME_PAIRE,
  TYPE_IMAGES_2EME_PAIRE_SHORT,
} from "../../constants/Properties";
import { IMAGE_API_URL } from "../../constants/Config";

import { loadImages } from "../../store/modules/images";
import BaseLayout from "../../containers/shared/BaseLayout";

const ImageView = ({
  history,
  loadImages,
  images,
  title,
  image_code,
  type_image,
  type,
  gammes,
  gammeId,
  reseauId,
}) => {
  useEffect(() => {
    async function fetchData() {
      await loadImages();
    }
    fetchData();
  }, []);

  const gammeSelected = gammes[gammeId];
  const typeImagesFilter = filter(
    map(gammeSelected, (value, gammeName) => value && !isEmpty(value) && gammeName),
    (value) => includes(keys(TYPE_IMAGES), value)
  );

  const TYPES_IMAGES_GAMMES = pickBy(TYPE_IMAGES, (v, e) => includes(typeImagesFilter, e));

  let image = { url: "" };

  if (type === "modele-verre") {
    image = { url: gammeSelected[type_image] };
  }

  // #5150
  if (type === "seconde-paire") {
    image = find(
      images,
      (image) =>
        image.page === TYPE_IMAGES_2EME_PAIRE_SHORT[type_image] &&
        image.reseau_id === parseInt(reseauId)
    );
  }

  // #5150
  if (type != "modele-verre" && type != "seconde-paire") {
    image = images.find(
      (image) => image.page === image_code && image.reseau_id === parseInt(reseauId)
    );
  }
  return (
    <BaseLayout title={title}>
      <div className="workspace-header">
        <div className="grid-noGutter-center">
          {type === "modele-verre" &&
            TYPES_IMAGES_GAMMES &&
            Object.keys(TYPES_IMAGES_GAMMES).length > 1 && (
              <Field
                name="type_image"
                component={RadioField}
                options={TYPES_IMAGES_GAMMES}
                placeholder=""
                label=""
                className="col-0 radioToggleWrapper"
                classNameGroup="radio-toggle"
              />
            )}
          {/* #5150 */}
          {type === "seconde-paire" &&
            TYPE_IMAGES_2EME_PAIRE &&
            Object.keys(TYPE_IMAGES_2EME_PAIRE).length > 1 && (
              <Field
                name="type_image"
                component={RadioField}
                options={TYPE_IMAGES_2EME_PAIRE}
                placeholder=""
                label=""
                className="col-0 radioToggleWrapper"
                classNameGroup="radio-toggle"
              />
            )}
        </div>
      </div>
      <div className="gridWrapper">
        <div className="box box-image">
          {image && image.url !== "" && (
            <div className="box-content">
              <img src={image && IMAGE_API_URL + "/uploads/" + image.url} />
            </div>
          )}
        </div>
      </div>
      <div className="workspace-footer">
        <div className="grid-center-noBottom">
          {/* Colonne de gauche */}
          <div className="col flexbox">
            <button className="btn btn-secondary btn-big" onClick={() => history.goBack()}>
              <i className="item-icon far fa-long-arrow-left" />
            </button>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

// export default ImageView;
const selector = formValueSelector("ImageViewForm");

export default withRouter(
  connect(
    (state, props) => ({
      initialValues: {
        // #5140
        type_image: props.type === "seconde-paire" ? "unifocaux" : "image_argumentaire",
      },
      type_image: selector(state, "type_image") || null,
      images: values(state.data.entities.images),
      gammes: state.data.entities.gammes,
    }),
    { loadImages }
  )(
    reduxForm({
      form: "ImageViewForm",
    })(ImageView)
  )
);
