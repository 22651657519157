import { ACCOUNTS_ROLES } from "../constants/Properties";

export function isAdmin(userAccount, uniqRole = false) {
  return (
    userAccount &&
    (!uniqRole || userAccount.roles.length === 1) &&
    userAccount.roles.includes(ACCOUNTS_ROLES.ADMIN)
  );
}

export function isUser(userAccount, uniqRole = false) {
  return (
    userAccount &&
    (!uniqRole || userAccount.roles.length === 1) &&
    userAccount.roles.includes(ACCOUNTS_ROLES.USER)
  );
}

export function isReporter(userAccount, uniqRole = false) {
  return (
    userAccount &&
    (!uniqRole || userAccount.roles.length === 1) &&
    userAccount.roles.includes(ACCOUNTS_ROLES.REPORTER)
  );
}

export function canDoSomething(userAccount) {
  return isAdmin(userAccount) || isUser(userAccount);
}
