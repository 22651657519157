import { flow, set } from "lodash/fp";

import { omit, isUndefined } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_OPTIONS_REQUEST = "ksa/options/FETCH_ALL_OPTIONS_REQUEST";

export const FETCH_ALL_OPTIONS_SUCCESS = "ksa/options/FETCH_ALL_OPTIONS_SUCCESS";
const FETCH_ALL_OPTIONS_FAILURE = "ksa/options/FETCH_ALL_OPTIONS_FAILURE";

const FETCH_OPTION_REQUEST = "ksa/options/FETCH_OPTION_REQUEST";
const FETCH_OPTION_SUCCESS = "ksa/options/FETCH_OPTION_SUCCESS";
const FETCH_OPTION_FAILURE = "ksa/options/FETCH_OPTION_FAILURE";

const UPDATE_OPTION_REQUEST = "ksa/options/UPDATE_OPTION_REQUEST";
const UPDATE_OPTION_SUCCESS = "ksa/options/UPDATE_OPTION_SUCCESS";
const UPDATE_OPTION_FAILURE = "ksa/options/UPDATE_OPTION_FAILURE";

const SET_OPTION_REQUEST = "ksa/options/SET_OPTION_REQUEST";
const SET_OPTION_SUCCESS = "ksa/options/SET_OPTION_SUCCESS";
const SET_OPTION_FAILURE = "ksa/options/SET_OPTION_FAILURE";

const DELETE_OPTION_REQUEST = "ksa/options/DELETE_OPTION_REQUEST";
const DELETE_OPTION_SUCCESS = "ksa/options/DELETE_OPTION_SUCCESS";
const DELETE_OPTION_FAILURE = "ksa/options/DELETE_OPTION_FAILURE";

export const optionsActionsHandlers = {
  [FETCH_ALL_OPTIONS_REQUEST]: (state) =>
    flow(set("loaded.options", false), set("loading.options", true))(state),
  [FETCH_OPTION_REQUEST]: (state) => {
    return flow(set("loaded.options", false), set("loading.options", true))(state);
  },
  [FETCH_ALL_OPTIONS_SUCCESS]: (state, action) => {
    return flow(
      set("entities.options", action.response.entities.options || {}),
      set("loaded.options", true),
      set("loading.options", false)
    )(state);
  },

  [FETCH_ALL_OPTIONS_FAILURE]: (state) =>
    flow(set("loaded.options", false), set("loading.options", false))(state),
  [FETCH_OPTION_FAILURE]: (state) =>
    flow(set("loaded.options", false), set("loading.options", false))(state),

  [FETCH_OPTION_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.options.${action.id}`, action.response.entities.options[action.id]),
      set("loaded.options", true),
      set("loading.options", false)
    )(state);
  },

  [SET_OPTION_REQUEST]: (state) =>
    flow(set("loaded.options", false), set("loading.options", true))(state),
  [SET_OPTION_SUCCESS]: (state, action) =>
    flow(
      set("entities.options", {
        ...state.entities.options,
        ...action.response.entities.options,
      }),
      set("loaded.options", true),
      set("loading.options", false)
    )(state),
  [SET_OPTION_FAILURE]: (state, action) =>
    flow(set("loaded.options", false), set("loading.options", false))(state),

  [DELETE_OPTION_SUCCESS]: (state, action) =>
    flow(set("entities.options", omit(state.entities.options, action.id)))(state),
};

export function loadOption(optionName) {
  return {
    optionName,
    [CALL_API]: {
      types: [FETCH_OPTION_REQUEST, FETCH_OPTION_SUCCESS, FETCH_OPTION_FAILURE],
      method: "GET",
      endpoint: `/options/${optionName}`,
    },
  };
}

export function loadOptions() {
  console.log("loadOptions()");
  return {
    [CALL_API]: {
      types: [FETCH_ALL_OPTIONS_REQUEST, FETCH_ALL_OPTIONS_SUCCESS, FETCH_ALL_OPTIONS_FAILURE],
      method: "GET",
      endpoint: "/options",
      schema: Schemas.OPTION_ARRAY,
    },
  };
}

export function createOrUpdateOption(option) {
  console.log("createOrUpdateOption()", option.option_id);

  return {
    [CALL_API]: {
      types: [SET_OPTION_REQUEST, SET_OPTION_SUCCESS, SET_OPTION_FAILURE],
      method: !option || !Number.isInteger(option.option_id) ? "POST" : "PUT",
      endpoint:
        !option || !Number.isInteger(option.option_id)
          ? "/options"
          : `/options/${option.option_id}`,
      schema: Schemas.OPTION,
      body: option,
      successMessage: "L'option à bien été enregistré",
    },
  };
}
export function updateOption(optionName, optionData) {
  return {
    optionName,
    [CALL_API]: {
      types: [UPDATE_OPTION_REQUEST, UPDATE_OPTION_SUCCESS, UPDATE_OPTION_FAILURE],
      method: "PUT",
      endpoint: `/options/${optionName}`,
      body: {
        data: optionData,
      },
    },
  };
}

export function deleteOption(id) {
  console.log("deleteOption() id =>", id);
  return {
    id,
    [CALL_API]: {
      types: [DELETE_OPTION_REQUEST, DELETE_OPTION_SUCCESS, DELETE_OPTION_FAILURE],
      method: "DELETE",
      endpoint: `/options/${id}`,
      successMessage: "L'option a été supprimé avec succès",
    },
  };
}
