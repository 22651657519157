import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";
import { isUndefined } from "lodash";

import { loadOptions } from "../../../store/modules/options";
import { Field, InputField, SelectField, ToggleField } from "react-components-linaia";

import { uploadFiles, removeFile } from "../../../store/modules/uploads";
import { operators } from "../../../utils/dataGlobals";

const RegleGestionEditForm = ({ handleSubmit, baseUrl, initialValues, reseaux }) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.regle_gestion_id >= 0 ? "Modifier la règle" : "Ajouter une règle"}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    //
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="reseau_id"
            component={SelectField}
            options={reseaux}
            optionsKeys={{ value: "reseau_id", label: "nom" }}
            label="Reseau *"
            className="col-6_xs-12"
            classNamePrefix="react-select"
          />
          {/* // Kalixia #9151 */}
          <div className="col-12">
            <Field
              name="moins_16"
              component={ToggleField}
              placeholder=""
              className="box-content"
              fieldClassName="checkbox"
              label="-16 ans"
            />
          </div>
          <Field name="indice" component={InputField} label="Indice *" className="col-6_xs-12" />
          <div className="col-12 fields-group">
            <div className="grid">
              <div className="col-12">
                <div className="fields-group-title">Sphère</div>
              </div>
              <Field
                name="sphere_ope_bas"
                component={SelectField}
                label="Opérateur bas"
                className="col-3"
                classNamePrefix="react-select"
                options={operators}
                optionsKeys={{ value: "value", label: "label" }}
              />
              <Field
                name="sphere_valeur_bas"
                component={InputField}
                label="Valeur basse"
                className="col"
                type="number"
              />
              <Field
                name="sphere_ope_haut"
                component={SelectField}
                label="Opérateur haut"
                className="col-3"
                classNamePrefix="react-select"
                options={operators}
                optionsKeys={{ value: "value", label: "label" }}
              />
              <Field
                name="sphere_valeur_haut"
                component={InputField}
                label="Valeur haute"
                className="col"
                type="number"
              />
            </div>
          </div>
          <div className="col-12 fields-group">
            <div className="grid">
              <div className="col-12">
                <div className="fields-group-title">Cylindre</div>
              </div>
              <Field
                name="cylindre_ope_bas"
                component={SelectField}
                label="Opérateur bas"
                options={operators}
                optionsKeys={{ value: "value", label: "label" }}
                className="col-3"
                classNamePrefix="react-select"
              />
              <Field
                name="cylindre_valeur_bas"
                component={InputField}
                label="Valeur basse"
                className="col"
                type="number"
              />
              <Field
                name="cylindre_ope_haut"
                component={SelectField}
                label="Opérateur haut"
                options={operators}
                optionsKeys={{ value: "value", label: "label" }}
                className="col-3"
                classNamePrefix="react-select"
              />
              <Field
                name="cylindre_valeur_haut"
                component={InputField}
                label="Valeur haute"
                className="col"
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-tertiary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

RegleGestionEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired
};

export default reduxForm({
  form: "RegleGestionEditForm",
  validate: (values = {}) => {
    const errors = {};
    if (isUndefined(values.reseau_id)) errors.reseau_id = "Merci de renseigner un réseau";
    if (!values.indice) errors.indice = "Merci de renseigner un indice";
    return errors;
  }
})(
  connect(
    (state, { regleGestionID }) => ({
      options: state.data.entities.options,
      reseaux: state.data.entities.reseaux
    }),
    { upFiles: uploadFiles, rmFile: removeFile, loadOptions }
  )(RegleGestionEditForm)
);
