import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";
import { values } from "lodash";

import { loadReseaux } from "../../../store/modules/reseaux";
import { Field, FileField, InputField, ToggleField } from "react-components-linaia";

import { uploadFiles, removeFile } from "../../../store/modules/uploads";

import { UPLOADS_URL } from "../../../constants/Config";

const ReseauEditForm = ({ handleSubmit, baseUrl, initialValues, upFiles, rmFile }) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.reseau_id >= 0 ? "Modifier le réseau" : "Ajouter un réseau"}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field name="nom" component={InputField} label="Nom du réseau *" className="col-12" />
          <Field name="code" component={InputField} label="Code du réseau *" className="col-12" />

          <Field
            name="services"
            component={ToggleField}
            label="Services"
            className="col-12"
            fieldClassName="checkbox"
          />
          <Field
            name="mineraux"
            component={ToggleField}
            label="Verres minéraux"
            className="col-12"
            fieldClassName="checkbox"
          />
          <Field
            name="classea_nikon"
            component={ToggleField}
            label="Verres Panier A"
            className="col-12"
            fieldClassName="checkbox"
          />
          <Field
            name="prix_prisme"
            component={InputField}
            label="Prix option Prisme *"
            className="col-12"
            type="number"
          />
          <Field
            name="libelle_classeA"
            component={InputField}
            label="Libellé Classe A"
            className="col-12"
          />
          <Field
            name="image"
            label="Logo du réseau"
            component={FileField}
            uploadFiles={upFiles}
            removeFile={rmFile}
            getUrl={(item) => `${UPLOADS_URL}/${item}`}
            buttonLabel="Choisir un Fichier"
            className="col-12"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-tertiary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

ReseauEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "ReseauEditForm",
  validate: (values = {}) => {
    const errors = {};
    if (!values.nom) errors.nom = "Merci de renseigner le nom du réseau";
    if (!values.code) errors.code = "Merci de renseigner le code de réseau";
    if (!values.prix_prisme) errors.prix_prisme = "Merci de renseigner le prix de l'option Prisme";
    return errors;
  },
})(
  connect(
    (state, { reseauId }) => ({
      reseaux: state.data.entities.reseaux,
    }),
    { upFiles: uploadFiles, rmFile: removeFile, loadReseaux }
  )(ReseauEditForm)
);
