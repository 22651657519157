import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { IconButton, SelectField } from "react-components-linaia";
import { values, map } from "lodash";

import { setConfig } from "../../store/modules/globals";
import { logout } from "../../store/modules/auth";

/* import {
  isAdmin,
  isClientContact,
  isTechnicianAdmin,
} from '../../utils/RolesUtils'; */

import logo from "../../../assets/images/logo-kharo.svg";
import mobileLogo from "../../../assets/images/logo-kharo.svg";

const HeaderAdmin = ({
  menuIsOpen,
  menuIsTiny,
  account,
  sites,
  setConf,
  doLogout,
  history: {
    push,
    location: { pathname }
  }
}) => {
  const [site, setSite] = useState(pathname.indexOf("/clients/sites/") === 0 ? pathname[15] : null);

  const redirectToPath = selectedSite => {
    setSite(selectedSite);
    if (menuIsOpen === true) {
      setConf("menuIsOpen", false);
    }
    return push(`/clients/sites/${selectedSite}/reports`);
  };
  return (
    <header className="header">
      <div className={classNames("header-menu", { tiny: menuIsTiny })}>
        <div className="container">
          <a href="/" className="logo-block">
            <img src={logo} alt="Logo" className="logo" />
          </a>
          <nav
            className={classNames(
              "main-nav",
              { "animated opened": menuIsOpen === true },
              { "animated closed": menuIsOpen === false }
            )}
          >
            <div href="/" className="mobile-logo-block">
              <img src={mobileLogo} alt="Logo" className="mobile-logo" />
            </div>
            {/* HEADER COTE ADMIN*/}

            <ul>
              <li>
                <NavLink
                  to="/admin"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <span>Admin</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/reseaux"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <span>Réseaux</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/options"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <span>Options</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/services"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <span>Services</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/garanties"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <span>Garanties</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/gammes"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <span>Gammes</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/images"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <span>Images</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/remisesMontures"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <span>Remises montures</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/reglesGestion"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <span>Règles de gestion</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/tarifs"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <span>Tarifs</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/appSettings/1/edit"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <span>Paramètres</span>
                </NavLink>
              </li>
            </ul>

            <div className="user-block">
              <div className="user-site">
                {values(sites).length > 1 && (
                  <SelectField
                    classNamePrefix="react-select"
                    classNameReactSelect="react-select"
                    placeholder="Choix du site"
                    input={{
                      value: site,
                      onChange: redirectToPath
                    }}
                    options={map(sites, s => ({
                      label: s.siteName,
                      value: s.site_id
                    }))}
                  />
                )}
                {values(sites).length === 1 && (
                  <NavLink
                    className="btn"
                    to={`/clients/sites/${values(sites)[0].site_id}/reports`}
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                  >
                    <i className="fas fa-warehouse" />
                    <span>{values(sites)[0].siteName}</span>
                  </NavLink>
                )}
              </div>

              <div className="user-profil">
                <div className="user-avatar-block">
                  <div className="user-avatar">
                    <img
                      src="//www.gravatar.com/avatar/60d44261bab4309c41da7218addde9db?d=404"
                      alt="NL"
                    />
                  </div>
                </div>
                <div className="user-name">{account.username}</div>
              </div>
              <div className="user-actions">
                <IconButton title="Déconnexion" fontIcon="fal fa-sign-out" onClick={doLogout} />
              </div>
            </div>
          </nav>
          <div
            className={classNames(
              "menu-mask",
              { "animated opened": menuIsOpen === true },
              { "animated closed": menuIsOpen === false }
            )}
            onClick={() => setConf("menuIsOpen", false)}
          />
          <IconButton
            onClick={() => setConf("menuIsOpen", true)}
            fontIcon="far fa-bars"
            title="Menu"
            className={classNames("menu-button", { "menu-opened": menuIsOpen })}
          />
        </div>
      </div>
    </header>
  );
};

HeaderAdmin.propTypes = {
  menuIsOpen: PropTypes.bool,
  menuIsTiny: PropTypes.bool,
  account: PropTypes.shape({
    username: PropTypes.string.isRequired
  }).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()),

  setConf: PropTypes.func.isRequired,
  doLogout: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  state => ({
    menuIsOpen: state.data.config.menuIsOpen,
    menuIsTiny: state.data.config.menuIsTiny,
    sites: state.data.entities.sites,
    account: state.auth.user
  }),
  { setConf: setConfig, doLogout: logout }
)(withRouter(HeaderAdmin));
