import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const AccountDetailView = ({ account = {}, baseUrl }) => (
  <div>
    <div>
      Usernmae:&nbsp;
      {account.username}
    </div>
    <div>
      Email:&nbsp;
      {account.email}
    </div>
    <NavLink to={`${baseUrl}/edit`}>Editer</NavLink>
  </div>
);

AccountDetailView.propTypes = {
  account: PropTypes.shape({
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  }).isRequired,

  baseUrl: PropTypes.string.isRequired
};

export default AccountDetailView;
