import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, ImageRadioField, RadioField } from "react-components-linaia";
import { reduxForm, reset, formValueSelector } from "redux-form";
import { values, map, isEmpty, isNull } from "lodash";
import { withRouter } from "react-router-dom";
import BaseLayout from "../shared/BaseLayout";
import { CLASSES, CLASSESMIN } from "../../constants/Properties";
import { IMAGE_API_URL } from "../../constants/Config";
import { setConfig } from "../../store/modules/globals";
import { isMinereaux } from "../../utils/PropsUtils";

const Home = ({
  history: { push },
  reseaux,
  reseauxObjet,
  handleSubmit,
  setConfig,
  marques,
  marque,
  reseau,
  values,
  test,
  data,
  classe,
}) => {
  
  useEffect(() => {
    //ici les marques sont chargés
    if (!isEmpty(marques)) {
      if (isEmpty(localStorage.getItem("marque"))) {
        let marque = marques.find((m) => m.libelle === "Nikon");
        localStorage.setItem("marque", marque.marque_id);
        setConfig("marque", marque);
      } else {
        setConfig(
          "marque",
          marques.find((m) => m.marque_id === parseInt(localStorage.getItem("marque")))
        );
      }
    }
  }, [marques]);

  const submitWithoutNextPage = (values, dispatch) => {
    let reseau = reseaux.find((r) => r.reseau_id === values.reseau);
    setConfig("reseau", reseau);
    setConfig("classe", values.classe);
    setConfig("correction", {});
  };

  const submit = (values, dispatch) => {
    if (values.classe === "classeA") {
      push(`/verre/classeA`);
    } else if (values.classe === "classeB" && reseau === 5) {
      push(`/correction/kalixia`);
    } else if (values.classe === "classeB") {
      push(`/correction`);
    } else if (values.classe === "mineraux" && data.reseau.mineraux === true) {
      push(`/mineraux/` + values.reseau);
    }
  };

  return (
    <BaseLayout title="MyContainer">
      <form
        className=""
        onSubmit={handleSubmit(submit)}
        name="homeForm"
        onChange={() => {
          setTimeout(handleSubmit(submitWithoutNextPage));
        }}
      >
        <div className="gridWrapper">
          <div className="grid">
            <Field
              name="reseau"
              component={ImageRadioField}
              parse={(value) => Number(value)}
              options={map(reseaux, (reseau) =>
                reseau.image
                  ? {
                      value: reseau.reseau_id,
                      img: IMAGE_API_URL + "/uploads/" + reseau.image,
                      label: reseau.nom,
                    }
                  : {
                      value: reseau.reseau_id,
                      label: reseau.nom,
                    }
              )}
              placeholder="Réseau"
              label="Réseau"
              className="col-12"
              fieldClassName="custom-radio-wrapper custom-radio-imageOnly"
              classNameGrid="cssGrid"
              classNameCol="cssGrid-item"
            />

            {reseauxObjet[reseau] && reseauxObjet[reseau].libelle_classeA ? (
              <Field
                name="classe"
                component={ImageRadioField}
                options={
                  (reseauxObjet &&
                    isMinereaux(reseauxObjet[reseau]) &&
                    map(CLASSESMIN, (obj) =>
                      obj.value === "classeA" && reseauxObjet[reseau].libelle_classeA
                        ? {
                            ...obj,
                            label: obj.label + "<br>" + reseauxObjet[reseau].libelle_classeA,
                          }
                        : obj
                    )) ||
                  map(CLASSES, (obj) =>
                    obj.value === "classeA" && reseauxObjet[reseau].libelle_classeA
                      ? {
                          ...obj,
                          label: obj.label + "<br>" + reseauxObjet[reseau].libelle_classeA,
                        }
                      : obj
                  )
                }
                placeholder="Classe"
                label="Classe"
                className="col-12"
                fieldClassName="custom-radio-wrapper"
                classNameGrid="cssGrid"
                classNameCol="cssGrid-item"
              />
            ) : (
              <Field
                name="classe"
                component={ImageRadioField}
                options={
                  (reseauxObjet && isMinereaux(reseauxObjet[reseau]) && CLASSESMIN) || CLASSES
                }
                placeholder="Classe"
                label="Classe"
                className="col-12"
                fieldClassName="custom-radio-wrapper"
                classNameGrid="cssGrid"
                classNameCol="cssGrid-item"
              />
            )}
          </div>
        </div>
        <div className="workspace-footer">
          <div className="grid-center-noBottom">
            <div className="col flexbox justifyEnd">
              <button className="btn btn-primary btn-big" type="submit">
                <span> Suivant </span> <i className="far fa-long-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </BaseLayout>
  );
};
const selector = formValueSelector("HomeForm");

export default withRouter(
  connect(
    (state) => ({
      data: state.data.config,
      reseaux: values(state.data.entities.reseaux),
      reseauxObjet: state.data.entities.reseaux,
      options: values(state.data.entities.options),
      services: values(state.data.entities.services),
      garanties: values(state.data.entities.garanties),
      gammes: values(state.data.entities.gammes),
      marques: values(state.data.entities.marques),
      images: values(state.data.entities.images),
      remisesMontures: values(state.data.entities.remisesMontures),
      reglesGestion: values(state.data.entities.reglesGestion),
      marque: state.data.config.marque,
      initialValues: {
        reseau: state.data.config.reseau.reseau_id,
        classe: state.data.config.classe,
      },
      test: (state.form.HomeForm && state.form.HomeForm.values.classe) || null,

      reseau: selector(state, "reseau") || null,
      classe: selector(state, "classe") || null,
    }),
    {
      setConfig,
    }
  )(
    reduxForm({
      form: "HomeForm",
      validate: (values = {}) => {
        const errors = {};

        if (!values.reseau) errors.reseau = "Champ requis";
        //if (!values.classe) errors.classe = "Champ requis";

        return errors;
      },
    })(Home)
  )
);
