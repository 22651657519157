import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { capitalize, get, orderBy, isNull, isUndefined } from "lodash";
import { connect } from "react-redux";
import { ButtonConfirmModal } from "react-components-linaia";

import TableListView from "../../shared/TableListView";

const RemisesMonturesTableListView = ({
  history: { push },
  remisesMontures,
  reseaux,
  baseUrl,
  onClickDelete,
  isAdmin,
}) => (
  <TableListView
    className="table-boxed full-height"
    data={remisesMontures}
    cols={{
      reseau_id: {
        label: "Nom du réseau",
        className: "col",
        value: (reseau_id) => get(reseaux, `${reseau_id}.nom`),
      },

      montant_mini: {
        label: "Prix > à",
        className: "col-2_sm-12 text-center",
        value: (value) => (!isUndefined(value) && !isNull(value) ? value + " €" : ""),
      },
      montant_maxi: {
        label: "Prix <= à",
        className: "col-2_sm-12 text-center",
        value: (value) => (!isUndefined(value) && !isNull(value) ? value + " €" : ""),
      },
      remise: {
        label: "Remise",
        className: "col-2_sm-12 text-center",
        value: (value) => value + " %",
      },
    }}
    search={["reseau_id"]}
    searchLabel={"Rechercher une remise"}
    sortByDefault="remise_monture_id"
    sortOrderDefault="asc"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <i className="far fa-plus" />
          <span>Ajouter une remise</span>
        </NavLink>
      </div>
    }
    rowButtons={(remiseMonture) => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {remiseMonture && (
            <NavLink className="btn" to={`${baseUrl}/${remiseMonture.remise_monture_id}/edit`}>
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {remiseMonture && (
            <ButtonConfirmModal
              onConfirmationButton={() => onClickDelete(remiseMonture.remise_monture_id)}
              message="Voulez-vous vraiment supprimer cette remise ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

RemisesMonturesTableListView.propTypes = {
  remisesMontures: PropTypes.object,
  reseaux: PropTypes.object,
  isAdmin: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect((state, { montureRemiseId }) => ({
  remisesMontures: state.data.entities.remisesMontures,
  reseaux: state.data.entities.reseaux,
  montureRemiseId,
}))(withRouter(RemisesMonturesTableListView));
