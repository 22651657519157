import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get } from "lodash";

import { loadReseaux, createOrUpdateReseau, deleteReseau } from "../../store/modules/reseaux";

import ReseauEditForm from "../../components/admin/reseaux/ReseauEditForm";

import modalDefaultClass from "../../utils/ModalDefaultClass";
import ReseauxTableListView from "../../components/admin/reseaux/ReseauxTableListView";

import AdminLayout from "../shared/AdminLayout";

const RenderEditDetailsModalsRouter = ({ reseauId, url, editReseau, push, reseaux }) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ReseauEditForm
            initialValues={{
              services: false,
              mineraux: false,
              classea_nikon: false,
              image: "",
            }}
            onSubmit={(v) => {
              editReseau(v).then(() => push(url));
            }}
            baseUrl={url}
            reseauId={reseauId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:reseauId/edit`}
      render={(subProps) =>
        get(reseaux, subProps.match.params.reseauId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <ReseauEditForm
              initialValues={reseaux[subProps.match.params.reseauId]}
              onSubmit={(v) => {
                editReseau(v).then(() => push(url));
              }}
              baseUrl={url}
              reseauId={reseauId}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editReseau: PropTypes.func.isRequired,
  delReseau: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  reseaux: PropTypes.object.isRequired,
};

const ReseauxAdminContainer = ({
  reseaux,
  reseauId,
  match: { url },
  history: { push },
  ...props
}) => (
  <AdminLayout>
    <h1>Réseaux</h1>
    <ReseauxTableListView
      reseaux={reseaux}
      baseUrl={url}
      onClickDelete={(id) => {
        props.deleteReseau(id).then(() => push(url));
      }}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editReseau={props.createOrUpdateReseau}
      delReseau={props.deleteReseau}
      push={push}
      reseaux={reseaux}
    />
  </AdminLayout>
);

ReseauxAdminContainer.propTypes = {
  createOrUpdateReseau: PropTypes.func.isRequired,
  deleteReseau: PropTypes.func.isRequired,
  reseaux: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state) => ({
    reseaux: state.data.entities.reseaux,
  }),
  { createOrUpdateReseau, deleteReseau, loadReseaux }
)(withRouter(ReseauxAdminContainer));
