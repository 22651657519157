export function ReseauIsCompleted(data) {
  if (data.reseau.code === undefined) {
    return false;
  } else return true;
}

export function ReseauIsCompletedClasseB(data) {
  if (data.reseau.code !== undefined) {
    if (data.classe === "classeB") {
      return true;
    }
    return false;
  }
}

export function ServiceIsCompleted(data) {
  if (data.reseau.services === true) {
    return true;
  } else return false;
}

// reseau  = 5 (id)
export function isMinereaux(reseau) {
  if (reseau && reseau.mineraux === true) {
    return true;
  } else return false;
}

export function CorrectionIsCompleted(data) {
  if (data.correction.type_correction === undefined) {
    return false;
  } else return true;
}

export function showPrecalibrage(reseau, sphere, cylindre, addition) {
  return reseau.code === "KALIXIA" && sphere + cylindre + (addition ? addition : 0) >= 2;
}

export function showEquilibrage(reseau, correction) {
  return (
    reseau.code === "KALIXIA" &&
    correction &&
    Math.abs(
      correction.od_cylindre +
        correction.od_sphere +
        (correction.od_addition ? correction.od_addition : 0)
    ) -
      Math.abs(
        correction.og_cylindre +
          correction.og_sphere +
          (correction.og_addition ? correction.og_addition : 0)
      ) >=
      1 &&
    ((correction.og_sphere <= 0 && correction.od_sphere <= 0) ||
      (correction.og_sphere >= 0 && correction.od_sphere >= 0) ||
      correction.od_sphere ^ (correction.og_sphere < 0))
  );
}

export function showPrecalibrageCas2(reseau, correction) {
  return (
    reseau.code === "KALIXIA" &&
    Math.abs(correction.od_sphere - correction.og_sphere) >= 1 &&
    correction.od_sphere <= 0 &&
    correction.og_sphere <= 0
  );
}

export function showPrecalibrageCas3(reseau, correction) {
  return (
    reseau.code === "KALIXIA" &&
    Math.abs(correction.od_sphere - correction.og_sphere) >= 1 &&
    correction.od_sphere >= 0 &&
    correction.og_sphere >= 0
  );
}

export function showPrecalibrageCas4(reseau, correction) {
  return (
    reseau.code === "KALIXIA" &&
    Math.abs(correction.od_sphere - correction.og_sphere) >= 1 &&
    ((correction.od_sphere > 0 && correction.og_sphere < 0) ||
      (correction.od_sphere < 0 && correction.og_sphere > 0))
  );
}
