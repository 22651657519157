import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import AdminLayout from "../shared/AdminLayout";
import TarifEditForm from "../../components/admin/tarifs/TarifEditForm";

const TarifsAdminContainer = ({ match: { url }, history: { push }, ...props }) => (
  <AdminLayout>
    <TarifEditForm initialValues={{}} baseUrl={url} />
  </AdminLayout>
);

TarifsAdminContainer.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(TarifsAdminContainer);
