import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { capitalize, get, orderBy, find } from "lodash";
import { connect } from "react-redux";
import { ButtonConfirmModal } from "react-components-linaia";

import TableListView from "../../shared/TableListView";

import { UPLOADS_URL } from "../../../constants/Config";
import { pagesData } from "../../../utils/dataGlobals";

const ImagesTableListView = ({
  history: { push },
  images,
  reseaux,
  baseUrl,
  onClickDelete,
  isAdmin,
}) => (
  <TableListView
    className="table-boxed full-height"
    data={images}
    cols={{
      reseau_id: {
        label: "Nom du réseau",
        className: "col",
        value: (reseau_id) => get(reseaux, `${reseau_id}.nom`),
      },
      page: {
        label: "Page",
        className: "col-4_sm-12",
        value: (vPage) =>
          find(pagesData, (obj) => obj.value === vPage) &&
          find(pagesData, (obj) => obj.value === vPage).label,
      },
    }}
    search={["reseau_id"]}
    searchLabel={"Rechercher une image"}
    sortByDefault="image_id"
    sortOrderDefault="asc"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <i className="far fa-plus" />
          <span>Ajouter une image</span>
        </NavLink>
      </div>
    }
    rowButtons={(image) => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {image && (
            <NavLink className="btn" to={`${baseUrl}/${image.image_id}/edit`}>
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {image && (
            <ButtonConfirmModal
              onConfirmationButton={() => onClickDelete(image.image_id)}
              message="Voulez-vous vraiment supprimer cette image ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

ImagesTableListView.propTypes = {
  images: PropTypes.object,
  reseaux: PropTypes.object,
  isAdmin: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect((state, { imageId }) => ({
  images: state.data.entities.images,
  reseaux: state.data.entities.reseaux,
  imageId,
}))(withRouter(ImagesTableListView));
