import { flow, set } from "lodash/fp";
import { omit, isUndefined } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_OPTION_VERRES_REQUEST = "arwa/optionVerres/FETCH_ALL_OPTION_VERRES_REQUEST";
export const FETCH_ALL_OPTION_VERRES_SUCCESS = "arwa/optionVerres/FETCH_ALL_OPTION_VERRES_SUCCESS";
const FETCH_ALL_OPTION_VERRES_FAILURE = "arwa/optionVerres/FETCH_ALL_OPTION_VERRES_FAILURE";
const FETCH_OPTION_VERRE_REQUEST = "arwa/optionVerres/FETCH_OPTION_VERRE_REQUEST";
const FETCH_OPTION_VERRE_SUCCESS = "arwa/optionVerres/FETCH_OPTION_VERRE_SUCCESS";
const FETCH_OPTION_VERRE_FAILURE = "arwa/optionVerres/FETCH_OPTION_VERRE_FAILURE";
const SET_OPTION_VERRE_REQUEST = "arwa/optionVerres/SET_OPTION_VERRE_REQUEST";
const SET_OPTION_VERRE_SUCCESS = "arwa/optionVerres/SET_OPTION_VERRE_SUCCESS";
const SET_OPTION_VERRE_FAILURE = "arwa/optionVerres/SET_OPTION_VERRE_FAILURE";
const DELETE_OPTION_VERRE_REQUEST = "arwa/optionVerres/DELETE_OPTION_VERRE_REQUEST";
const DELETE_OPTION_VERRE_SUCCESS = "arwa/optionVerres/DELETE_OPTION_VERRE_SUCCESS";
const DELETE_OPTION_VERRE_FAILURE = "arwa/optionVerres/DELETE_OPTION_VERRE_FAILURE";

export const optionVerresActionsHandlers = {
  [FETCH_ALL_OPTION_VERRES_REQUEST]: state =>
    flow(set("loaded.optionVerres", false), set("loading.optionVerres", true))(state),
  [FETCH_OPTION_VERRE_REQUEST]: state => {
    console.log("fetch optionVerre");
    return flow(set("loaded.optionVerres", false), set("loading.optionVerres", true))(state);
  },
  [FETCH_ALL_OPTION_VERRES_SUCCESS]: (state, action) => {
    return flow(
      set("entities.optionVerres", action.response.entities.optionVerres || {}),
      set("loaded.optionVerres", true),
      set("loading.optionVerres", false)
    )(state);
  },
  [FETCH_ALL_OPTION_VERRES_FAILURE]: state =>
    flow(set("loaded.optionVerres", false), set("loading.optionVerres", false))(state),
  [FETCH_OPTION_VERRE_FAILURE]: state =>
    flow(set("loaded.optionVerres", false), set("loading.optionVerres", false))(state),
  [FETCH_OPTION_VERRE_SUCCESS]: (state, action) => {
    console.log("optionVerre success");
    console.log(action.id);
    console.log(action.response.entities.optionVerres[action.id]);
    return flow(
      set(`entities.optionVerres.${action.id}`, action.response.entities.optionVerres[action.id]),
      set("loaded.optionVerres", true),
      set("loading.optionVerres", false)
    )(state);
  },
  [SET_OPTION_VERRE_REQUEST]: state =>
    flow(set("loaded.optionVerres", false), set("loading.optionVerres", true))(state),
  [SET_OPTION_VERRE_SUCCESS]: (state, action) =>
    flow(
      set("entities.optionVerres", {
        ...state.entities.optionVerres,
        ...action.response.entities.optionVerres
      }),
      set("loaded.optionVerres", true),
      set("loading.optionVerres", false)
    )(state),
  [SET_OPTION_VERRE_FAILURE]: (state, action) =>
    flow(set("loaded.optionVerres", false), set("loading.optionVerres", false))(state),
  [DELETE_OPTION_VERRE_SUCCESS]: (state, action) =>
    flow(set("entities.optionVerres", omit(state.entities.optionVerres, action.id)))(state)
};

export function loadOptionVerre(id) {
  console.log("load optionVerre");
  return {
    id,
    [CALL_API]: {
      types: [FETCH_OPTION_VERRE_REQUEST, FETCH_OPTION_VERRE_SUCCESS, FETCH_OPTION_VERRE_FAILURE],
      method: "GET",
      endpoint: `/optionVerres/${id}`,
      schema: Schemas.OPTION_VERRE,
      successMessage: "L'Options Verres a bien été chargée"
    }
  };
}

export function loadOptionVerres() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_OPTION_VERRES_REQUEST,
        FETCH_ALL_OPTION_VERRES_SUCCESS,
        FETCH_ALL_OPTION_VERRES_FAILURE
      ],
      method: "GET",
      endpoint: "/optionVerres",
      schema: Schemas.OPTION_VERRE_ARRAY,
      successMessage: "Les Options Verres ont bien été chargées"
    }
  };
}

export function createOrUpdateOptionVerre(optionVerre) {
  return {
    [CALL_API]: {
      types: [SET_OPTION_VERRE_REQUEST, SET_OPTION_VERRE_SUCCESS, SET_OPTION_VERRE_FAILURE],
      method:
        !optionVerre ||
        (!isUndefined(optionVerre.optionVerre_id) &&
          !Number.isInteger(optionVerre.optionVerre_id)) ||
        isUndefined(optionVerre.optionVerre_id)
          ? "POST"
          : "PUT",
      endpoint:
        !optionVerre ||
        (!isUndefined(optionVerre.optionVerre_id) &&
          !Number.isInteger(optionVerre.optionVerre_id)) ||
        isUndefined(optionVerre.optionVerre_id)
          ? "/optionVerres"
          : `/optionVerres/${optionVerre.optionVerre_id}`,
      schema: Schemas.OPTION_VERRE,
      body: optionVerre,
      successMessage: "L'Option Verres est enregistrée avec succès"
    }
  };
}

export function deleteOptionVerre(id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_OPTION_VERRE_REQUEST,
        DELETE_OPTION_VERRE_SUCCESS,
        DELETE_OPTION_VERRE_FAILURE
      ],
      method: "DELETE",
      endpoint: `/optionVerres/${id}`,
      successMessage: "L'Option Verres a été supprimée"
    }
  };
}
