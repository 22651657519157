import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get } from "lodash";

import {
  loadRemisesMontures,
  createOrUpdateRemiseMonture,
  deleteRemiseMonture
} from "../../store/modules/remisesMontures";

import RemiseMontureEditForm from "../../components/admin/remisesMontures/RemiseMontureEditForm";

import modalDefaultClass from "../../utils/ModalDefaultClass";
import RemisesMonturesTableListView from "../../components/admin/remisesMontures/RemisesMonturesTableListView";

import AdminLayout from "../shared/AdminLayout";

const RenderEditDetailsModalsRouter = ({
  remiseMontureId,
  url,
  editRemiseMonture,
  push,
  remisesMontures
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <RemiseMontureEditForm
            initialValues={{
              montant_mini: null,
              montant_mini: null
            }}
            onSubmit={v => {
              editRemiseMonture(v).then(() => push(url));
            }}
            baseUrl={url}
            remiseMontureId={remiseMontureId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:remiseMontureId/edit`}
      render={subProps =>
        get(remisesMontures, subProps.match.params.remiseMontureId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <RemiseMontureEditForm
              initialValues={remisesMontures[subProps.match.params.remiseMontureId]}
              onSubmit={v => {
                editRemiseMonture(v).then(() => push(url));
              }}
              baseUrl={url}
              remiseMontureId={remiseMontureId}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editRemiseMonture: PropTypes.func.isRequired,
  delRemiseMonture: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  reseaux: PropTypes.object.isRequired
};

const RemisesMonturesAdminContainer = ({
  remisesMontures,
  match: { url },
  history: { push },
  ...props
}) => (
  <AdminLayout>
    <h1>Remises montures</h1>
    <RemisesMonturesTableListView
      remisesMontures={remisesMontures}
      baseUrl={url}
      onClickDelete={id => {
        props.deleteRemiseMonture(id).then(() => push(url));
      }}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editRemiseMonture={props.createOrUpdateRemiseMonture}
      delRemiseMonture={props.deleteRemiseMonture}
      push={push}
      remisesMontures={remisesMontures}
    />
  </AdminLayout>
);

RemisesMonturesAdminContainer.propTypes = {
  createOrUpdateRemiseMonture: PropTypes.func.isRequired,
  deleteRemiseMonture: PropTypes.func.isRequired,
  remisesMontures: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  state => ({
    remisesMontures: state.data.entities.remisesMontures
  }),
  { createOrUpdateRemiseMonture, deleteRemiseMonture, loadRemisesMontures }
)(withRouter(RemisesMonturesAdminContainer));
