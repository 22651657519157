import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get, values } from "lodash";

import { createOrUpdateAccount, deleteAccount, loadAccounts } from "../../store/modules/accounts";

import AdministrateurEditForm from "../../components/admin/administrateurs/AdministrateurEditForm";
import AdministrateursTableListView from "../../components/admin/administrateurs/AdministrateursTableListView";

import modalDefaultClass from "../../utils/ModalDefaultClass";

import AdminLayout from "../shared/AdminLayout";

import { loadOptions } from "../../store/modules/options";
import { loadServices } from "../../store/modules/services";
import { loadGaranties } from "../../store/modules/garanties";
import { loadReseaux } from "../../store/modules/reseaux";
import { loadGammes } from "../../store/modules/gammes";
import { loadMarques } from "../../store/modules/marques";
import { loadImages } from "../../store/modules/images";
import { loadRemisesMontures } from "../../store/modules/remisesMontures";
import { loadReglesGestion } from "../../store/modules/reglesGestion";
import { loadTarifs } from "../../store/modules/tarifs";
import { loadAppSettings } from "../../store/modules/appSettings";

const RenderEditDetailsModalsRouter = ({ accountId, url, editAccount, push, accounts }) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <AdministrateurEditForm
            initialValues={{}}
            onSubmit={v => {
              editAccount(v).then(() => push(url));
            }}
            baseUrl={url}
            accountId={accountId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:accountId/edit`}
      render={subProps =>
        get(accounts, subProps.match.params.accountId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <AdministrateurEditForm
              initialValues={accounts[subProps.match.params.accountId]}
              onSubmit={v => {
                editAccount(v).then(() => push(url));
              }}
              baseUrl={url}
              accounts={accounts}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editAccount: PropTypes.func.isRequired,
  delAccount: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  accounts: PropTypes.object.isRequired
};

const AdmininistateursAdminContainer = ({
  accounts,
  accountId,
  loadAccounts,
  loadReseaux,
  loadOptions,
  loadServices,
  loadGaranties,
  loadGammes,
  loadAppSettings,
  loadMarques,
  loadImages,
  loadRemisesMontures,
  loadReglesGestion,
  loadTarifs,
  match: { url },
  history: { push },
  ...props
}) => {
  useEffect(() => {
    async function fetchData() {
      await loadReseaux();
      await loadOptions();
      await loadServices();
      await loadGaranties();
      await loadGammes();
      await loadAppSettings();
      await loadMarques();
      await loadImages();
      await loadRemisesMontures();
      await loadReglesGestion();
      await loadTarifs();
    }
    fetchData();
  }, []);

  return (
    <AdminLayout>
      <h1>Administrateurs</h1>
      <AdministrateursTableListView
        accounts={accounts}
        baseUrl={url}
        onClickDelete={id => {
          props.deleteAccount(id).then(() => push(url));
        }}
      />
      <RenderEditDetailsModalsRouter
        url={url}
        editAccount={props.createOrUpdateAccount}
        delAccount={props.deleteAccount}
        push={push}
        accounts={accounts}
      />
    </AdminLayout>
  );
};
AdmininistateursAdminContainer.propTypes = {
  createOrUpdateAccount: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  accounts: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  state => ({
    accounts: state.data.entities.accounts,
    gammes: state.data.entities.gammes,
    reseaux: values(state.data.entities.reseaux),
    options: values(state.data.entities.options),
    services: values(state.data.entities.services),
    garanties: values(state.data.entities.garanties),
    gammes: values(state.data.entities.gammes),
    marques: values(state.data.entities.marques),
    images: values(state.data.entities.images),
    remisesMontures: values(state.data.entities.remisesMontures),
    reglesGestion: values(state.data.entities.reglesGestion),
    tarifs: values(state.data.entities.tarifs)
  }),
  {
    createOrUpdateAccount,
    deleteAccount,
    loadAccounts,
    loadReseaux,
    loadOptions,
    loadServices,
    loadGaranties,
    loadGammes,
    loadAppSettings,
    loadMarques,
    loadImages,
    loadRemisesMontures,
    loadReglesGestion,
    loadTarifs
  }
)(withRouter(AdmininistateursAdminContainer));
