import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";
import { values, isUndefined } from "lodash";

import { loadRemisesMontures } from "../../../store/modules/remisesMontures";
import { Field, FileField, InputField, ToggleField, SelectField } from "react-components-linaia";

import { uploadFiles, removeFile } from "../../../store/modules/uploads";

import { UPLOADS_URL } from "../../../constants/Config";

const RemiseMontureEditForm = ({
  handleSubmit,
  baseUrl,
  reseaux,
  initialValues,
  upFiles,
  rmFile
}) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.remise_monture_id >= 0 ? "Modifier une remise" : "Ajouter une remise"}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="reseau_id"
            component={SelectField}
            options={reseaux}
            optionsKeys={{ value: "reseau_id", label: "nom" }}
            label="Reseau *"
            className="col-12"
            classNamePrefix="react-select"
          />
          <Field
            name="montant_mini"
            component={InputField}
            label="Montant mini"
            type="number"
            className="col-4_sm-12"
          />
          <Field
            name="montant_maxi"
            component={InputField}
            label="Montant maxi"
            type="number"
            className="col-4_sm-12"
          />
          <Field
            name="remise"
            component={InputField}
            type="number"
            label="Remise % *"
            className="col-4_sm-12"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-tertiary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

RemiseMontureEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired
};

export default reduxForm({
  form: "RemiseMontureEditForm",
  validate: (values = {}) => {
    const errors = {};
    if (isUndefined(values.reseau_id)) errors.reseau_id = "Merci de renseigner le nom du réseau";
    if (!values.remise) errors.remise = "Merci de renseigner une remise";
    return errors;
  }
})(
  connect(
    (state, { remiseMontureId }) => ({
      remisesMontures: state.data.entities.remisesMontures,
      reseaux: state.data.entities.reseaux
    }),
    { upFiles: uploadFiles, rmFile: removeFile, loadRemisesMontures }
  )(RemiseMontureEditForm)
);
