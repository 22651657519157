import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Field, InputField, validateEmail } from "react-components-linaia";
import logo from "../../../assets/images/logo-kharo.svg";
import { loadOptions } from "../../store/modules/options";
import { loadServices } from "../../store/modules/services";
import { loadGaranties } from "../../store/modules/garanties";
import { loadReseaux } from "../../store/modules/reseaux";
import { loadGammes } from "../../store/modules/gammes";
import { loadMarques } from "../../store/modules/marques";
import { loadImages } from "../../store/modules/images";
import { loadRemisesMontures } from "../../store/modules/remisesMontures";
import { loadReglesGestion } from "../../store/modules/reglesGestion";
import { loadAppSettings } from "../../store/modules/appSettings";

const ConnexionForm = ({
  handleSubmit,
  loadReseaux,
  loadOptions,
  loadServices,
  loadGaranties,
  loadGammes,
  loadMarques,
  loadImages,
  loadRemisesMontures,
  loadReglesGestion,
  onClickRecoverPassword,
  loadAppSettings,
}) => {
  useEffect(() => {
    async function fetchData() {
      // You can await here
      await loadReseaux();
      await loadOptions();
      await loadServices();
      await loadGaranties();
      await loadGammes();
      await loadMarques();
      await loadImages();
      await loadRemisesMontures();
      await loadReglesGestion();
      await loadAppSettings();
      // ...
    }
    fetchData();
  }, []);
  return (
    <div className="box animated">
      <div className="box-header">
        <img src={logo} alt="Logo" className="logo" />

        <div className="separator" />
      </div>
      <div className="box-content">
        <form onSubmit={handleSubmit}>
          <Field
            name="password"
            component={InputField}
            type="password"
            placeholder="Mot de passe"
            className="field"
            autoComplete={false}
          />
          <button className="btn btn-full" type="submit">
            Se connecter
          </button>
          <div className="form-separator" />
        </form>
      </div>
    </div>
  );
};

ConnexionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClickRecoverPassword: PropTypes.func.isRequired,
};

export default connect((state) => ({}), {
  loadReseaux,
  loadOptions,
  loadServices,
  loadGaranties,
  loadGammes,
  loadMarques,
  loadImages,
  loadRemisesMontures,
  loadReglesGestion,
  loadAppSettings,
})(
  reduxForm({
    form: "ConnexionForm",
    validate: (values = {}) => {
      const errors = {};
    },
  })(ConnexionForm)
);
