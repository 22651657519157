import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get } from "lodash";

import {
  loadReglesGestion,
  createOrUpdateRegleGestion,
  deleteRegleGestion
} from "../../store/modules/reglesGestion";

import RegleGestionEditForm from "../../components/admin/reglesGestion/RegleGestionEditForm";

import modalDefaultClass from "../../utils/ModalDefaultClass";
import ReglesGestionTableListView from "../../components/admin/reglesGestion/ReglesGestionTableListView";

import AdminLayout from "../shared/AdminLayout";

const RenderEditDetailsModalsRouter = ({
  regleGestionId,
  url,
  editRegleGestion,
  push,
  reglesGestion
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <RegleGestionEditForm
            initialValues={{
              sphere_ope_bas: null,
              sphere_valeur_bas: null,
              sphere_ope_haut: null,
              sphere_valeur_haut: null,
              cylindre_ope_bas: null,
              cylindre_valeur_bas: null,
              cylindre_ope_haut: null,
              cylindre_valeur_haut: null
            }}
            onSubmit={v => {
              editRegleGestion(v).then(() => push(url));
            }}
            baseUrl={url}
            regleGestionId={regleGestionId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:regleGestionId/edit`}
      render={subProps =>
        get(reglesGestion, subProps.match.params.regleGestionId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <RegleGestionEditForm
              initialValues={reglesGestion[subProps.match.params.regleGestionId]}
              onSubmit={v => {
                editRegleGestion(v).then(() => push(url));
              }}
              baseUrl={url}
              regleGestionId={regleGestionId}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editRegleGestion: PropTypes.func.isRequired,
  delRegleGestion: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  reglesGestion: PropTypes.object.isRequired
};

const ReglesGestionAdminContainer = ({
  reglesGestion,
  optionId,
  match: { url },
  history: { push },
  ...props
}) => (
  <AdminLayout>
    <h1>Règles de gestion</h1>
    <ReglesGestionTableListView
      reglesGestion={reglesGestion}
      baseUrl={url}
      onClickDelete={id => {
        props.deleteRegleGestion(id).then(() => push(url));
      }}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editRegleGestion={props.createOrUpdateRegleGestion}
      delRegleGestion={props.deleteRegleGestion}
      push={push}
      reglesGestion={reglesGestion}
    />
  </AdminLayout>
);

ReglesGestionAdminContainer.propTypes = {
  createOrUpdateRegleGestion: PropTypes.func.isRequired,
  deleteRegleGestion: PropTypes.func.isRequired,
  reglesGestion: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  state => ({
    reglesGestion: state.data.entities.reglesGestion
  }),
  { createOrUpdateRegleGestion, deleteRegleGestion, loadReglesGestion }
)(withRouter(ReglesGestionAdminContainer));
