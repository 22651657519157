import { schema } from "normalizr";

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/gaearon/normalizr

const accountSchema = new schema.Entity(
  "accounts",
  {},
  {
    idAttribute: "account_id"
  }
);
const modelSchema = new schema.Entity(
  "models",
  {},
  {
    idAttribute: "model_id"
  }
);
const postSchema = new schema.Entity(
  "posts",
  {},
  {
    idAttribute: "id"
  }
);
const uploadContentSchema = new schema.Entity(
  "uploadContents",
  {},
  {
    idAttribute: "upload_content_id"
  }
);
const tarifSchema = new schema.Entity(
  "tarifs",
  {},
  {
    idAttribute: "tarif_id"
  }
);
const regleGestionSchema = new schema.Entity(
  "reglesGestion",
  {},
  {
    idAttribute: "regle_gestion_id"
  }
);
const remiseMontureSchema = new schema.Entity(
  "remisesMontures",
  {},
  {
    idAttribute: "remise_monture_id"
  }
);
const gammeSchema = new schema.Entity(
  "gammes",
  {},
  {
    idAttribute: "gamme_id"
  }
);
const imageSchema = new schema.Entity(
  "images",
  {},
  {
    idAttribute: "image_id"
  }
);
const garantieSchema = new schema.Entity(
  "garanties",
  {},
  {
    idAttribute: "garantie_id"
  }
);
const serviceSchema = new schema.Entity(
  "services",
  {},
  {
    idAttribute: "service_id"
  }
);
const optionVerreSchema = new schema.Entity(
  "optionVerres",
  {},
  {
    idAttribute: "option_verre_id"
  }
);
const reseauSchema = new schema.Entity(
  "reseaux",
  {},
  {
    idAttribute: "reseau_id"
  }
);
const optionSchema = new schema.Entity(
  "options",
  {},
  {
    idAttribute: "option_id"
  }
);
const marqueSchema = new schema.Entity(
  "marques",
  {},
  {
    idAttribute: "marque_id"
  }
);
const appSettingSchema = new schema.Entity(
  "appSettings",
  {},
  {
    idAttribute: "app_setting_id"
  }
);

// Schemas for API responses.
export const Schemas = {
  ACCOUNT: accountSchema,
  ACCOUNT_ARRAY: new schema.Array(accountSchema),

  MODEL: modelSchema,
  MODEL_ARRAY: new schema.Array(modelSchema),

  POST: postSchema,
  POST_ARRAY: new schema.Array(postSchema),

  UPLOAD_CONTENT: uploadContentSchema,
  UPLOAD_CONTENT_ARRAY: new schema.Array(uploadContentSchema),

  TARIF: tarifSchema,
  TARIF_ARRAY: new schema.Array(tarifSchema),

  REGLE_GESTION: regleGestionSchema,
  REGLE_GESTION_ARRAY: new schema.Array(regleGestionSchema),

  REMISE_MONTURE: remiseMontureSchema,
  REMISE_MONTURE_ARRAY: new schema.Array(remiseMontureSchema),

  GAMME: gammeSchema,
  GAMME_ARRAY: new schema.Array(gammeSchema),

  IMAGE: imageSchema,
  IMAGE_ARRAY: new schema.Array(imageSchema),

  GARANTIE: garantieSchema,
  GARANTIE_ARRAY: new schema.Array(garantieSchema),

  SERVICE: serviceSchema,
  SERVICE_ARRAY: new schema.Array(serviceSchema),

  OPTION_VERRE: optionVerreSchema,
  OPTION_VERRE_ARRAY: new schema.Array(optionVerreSchema),

  RESEAU: reseauSchema,
  RESEAU_ARRAY: new schema.Array(reseauSchema),

  OPTION: optionSchema,
  OPTION_ARRAY: new schema.Array(optionSchema),

  MARQUE: marqueSchema,
  MARQUE_ARRAY: new schema.Array(marqueSchema),

  APPSETTING: appSettingSchema,
  APPSETTING_ARRAY: new schema.Array(appSettingSchema),
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol("Call API");
