import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get } from "lodash";

import {
  loadGaranties,
  createOrUpdateGarantie,
  deleteGarantie
} from "../../store/modules/garanties";

import GarantieEditForm from "../../components/admin/garanties/GarantieEditForm";

import modalDefaultClass from "../../utils/ModalDefaultClass";
import GarantiesTableListView from "../../components/admin/garanties/GarantiesTableListView";

import AdminLayout from "../shared/AdminLayout";

const RenderEditDetailsModalsRouter = ({ garantieId, url, editGarantie, push, garanties }) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <GarantieEditForm
            initialValues={{}}
            onSubmit={v => {
              editGarantie(v).then(() => push(url));
            }}
            baseUrl={url}
            reseauId={garantieId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:garantieId/edit`}
      render={subProps =>
        get(garanties, subProps.match.params.garantieId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <GarantieEditForm
              initialValues={garanties[subProps.match.params.garantieId]}
              onSubmit={v => {
                editGarantie(v).then(() => push(url));
              }}
              baseUrl={url}
              garantieId={garanties}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editGarantie: PropTypes.func.isRequired,
  delGarantie: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  garanties: PropTypes.object.isRequired
};

const GarantiesAdminContainer = ({
  garanties,
  garantieId,
  match: { url },
  history: { push },
  ...props
}) => (
  <AdminLayout>
    <h1>Garanties</h1>
    <GarantiesTableListView
      garanties={garanties}
      baseUrl={url}
      onClickDelete={id => {
        props.deleteGarantie(id).then(() => push(url));
      }}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editGarantie={props.createOrUpdateGarantie}
      delGarantie={props.deleteGarantie}
      push={push}
      garanties={garanties}
    />
  </AdminLayout>
);

GarantiesAdminContainer.propTypes = {
  createOrUpdateGarantie: PropTypes.func.isRequired,
  deleteGarantie: PropTypes.func.isRequired,
  garanties: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  state => ({
    garanties: state.data.entities.garanties
  }),
  { createOrUpdateGarantie, deleteGarantie, loadGaranties }
)(withRouter(GarantiesAdminContainer));
