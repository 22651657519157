import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { isUserAgentMobile } from "react-components-linaia";

import { loadAccounts } from "../../store/modules/accounts";
import { loadOption } from "../../store/modules/options";
import { loadModels } from "../../store/modules/models";
import { loadUploadContents } from "../../store/modules/uploadContents";
import { loadPosts } from "../../store/modules/posts";

import { isAdmin, isUser } from "../../utils/RolesUtils";

@connect(
  state => ({
    user: state.auth.user,
    loaded: state.data.loaded,
    loading: state.data.loading
  }),
  {
    loadAccounts,
    loadOption,
    loadModels,
    loadUploadContents,
    loadPosts
  }
)
class AuthenticatedComponent extends React.PureComponent {
  static propTypes = {
    loadAccounts: PropTypes.func.isRequired,
    loadOption: PropTypes.func.isRequired,
    loadModels: PropTypes.func.isRequired,
    loadUploadContents: PropTypes.func.isRequired,
    loadPosts: PropTypes.func.isRequired,

    children: PropTypes.node.isRequired,

    user: PropTypes.shape({
      client_id: PropTypes.number,
      roles: PropTypes.arrayOf(PropTypes.string).isRequired
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired
    }).isRequired,
    loaded: PropTypes.shape({
      appstorage: PropTypes.bool.isRequired,
      accounts: PropTypes.bool.isRequired,
      options: PropTypes.bool.isRequired,
      models: PropTypes.bool.isRequired,
      uploadContents: PropTypes.bool.isRequired,
      posts: PropTypes.bool.isRequired
    }).isRequired,
    loading: PropTypes.shape({
      accounts: PropTypes.bool.isRequired,
      options: PropTypes.bool.isRequired,
      models: PropTypes.bool.isRequired,
      uploadContents: PropTypes.bool.isRequired,
      posts: PropTypes.bool.isRequired
    }).isRequired,
    match: PropTypes.shape().isRequired
  };

  state = { preventRender: true };

  static getDerivedStateFromProps(props) {
    // Waiting for appstorage loadingc
    if (!props.loaded.appstorage) {
      return { preventRender: true };
    }

    const redirectLocation = (pathname = null) => {
      if (pathname !== null) {
        props.history.replace(pathname);
      }
      return { preventRender: true };
    };

    // Check router path
    if (!props.user) {
      // oops, not logged in, so can't be here!
      return redirectLocation(
        props.location.pathname !== "/login" ? `/login?next=${props.location.pathname}` : null
      );
    }
    if (!isUser(props.user) && props.location.pathname.indexOf("/user") === 0) {
      // Only User access to /user
      return redirectLocation("/");
    }
    if (!isAdmin(props.user) && props.location.pathname.indexOf("/admin/") === 0) {
      // Only Admin access to /admin
      return redirectLocation("/");
    }

    // Load application data if not currently loaded
    const isNotLoaded = entityKey =>
      !get(props, `loading.${entityKey}`) && !get(props, `loaded.${entityKey}`);
    if (isNotLoaded("options")) {
      props.loadOption("welcomeMessage");
    }
    // Only Admins
    if (isAdmin(props.user)) {
      if (isNotLoaded("accounts")) {
        props.loadAccounts();
      }
      if (isNotLoaded("models")) {
        props.loadModels();
      }
      if (isNotLoaded("uploadContents")) {
        props.loadUploadContents();
      }
    }
    // Only desktop
    if (!isUserAgentMobile) {
      if (isNotLoaded("posts")) {
        props.loadPosts();
      }
    }

    return { preventRender: false };
  }

  render() {
    const { children, match } = this.props;
    const { preventRender } = this.state;

    return !preventRender && children ? React.cloneElement(children, { match }) : null;
  }
}

export default withRouter(AuthenticatedComponent);
