import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get } from "lodash";

import { loadServices, createOrUpdateService, deleteService } from "../../store/modules/services";

import ServiceEditForm from "../../components/admin/services/ServiceEditForm";

import modalDefaultClass from "../../utils/ModalDefaultClass";
import ServicesTableListView from "../../components/admin/services/ServicesTableListView";

import AdminLayout from "../shared/AdminLayout";

const RenderEditDetailsModalsRouter = ({ serviceId, url, editService, push, services }) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ServiceEditForm
            initialValues={{
              reseau_id: "",
              libelle: "",
              service: ""
            }}
            onSubmit={v => {
              editService(v).then(() => push(url));
            }}
            baseUrl={url}
            serviceId={serviceId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:serviceId/edit`}
      render={subProps =>
        get(services, subProps.match.params.serviceId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <ServiceEditForm
              initialValues={services[subProps.match.params.serviceId]}
              onSubmit={v => {
                editService(v).then(() => push(url));
              }}
              baseUrl={url}
              serviveId={serviceId}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editService: PropTypes.func.isRequired,
  delService: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  services: PropTypes.object.isRequired
};

const ServicesAdminContainer = ({
  services,
  serviceId,
  match: { url },
  history: { push },
  ...props
}) => (
  <AdminLayout>
    <h1>Services</h1>
    <ServicesTableListView
      services={services}
      baseUrl={url}
      onClickDelete={id => {
        props.deleteService(id).then(() => push(url));
      }}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editService={props.createOrUpdateService}
      deleteService={props.deleteService}
      push={push}
      services={services}
    />
  </AdminLayout>
);

ServicesAdminContainer.propTypes = {
  createOrUpdateService: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  services: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  state => ({
    services: state.data.entities.services
  }),
  { createOrUpdateService, deleteService, loadServices }
)(withRouter(ServicesAdminContainer));
