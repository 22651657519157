import { flow, set } from "lodash/fp";
import { omit, isUndefined } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_TARIFS_REQUEST = "arwa/tarifs/FETCH_ALL_TARIFS_REQUEST";
export const FETCH_ALL_TARIFS_SUCCESS = "arwa/tarifs/FETCH_ALL_TARIFS_SUCCESS";
const FETCH_ALL_TARIFS_FAILURE = "arwa/tarifs/FETCH_ALL_TARIFS_FAILURE";
const FETCH_TARIF_REQUEST = "arwa/tarifs/FETCH_TARIF_REQUEST";
const FETCH_TARIF_SUCCESS = "arwa/tarifs/FETCH_TARIF_SUCCESS";
const FETCH_TARIF_FAILURE = "arwa/tarifs/FETCH_TARIF_FAILURE";
const SET_TARIF_REQUEST = "arwa/tarifs/SET_TARIF_REQUEST";
const SET_TARIF_SUCCESS = "arwa/tarifs/SET_TARIF_SUCCESS";
const SET_TARIF_FAILURE = "arwa/tarifs/SET_TARIF_FAILURE";
const DELETE_TARIF_REQUEST = "arwa/tarifs/DELETE_TARIF_REQUEST";
const DELETE_TARIF_SUCCESS = "arwa/tarifs/DELETE_TARIF_SUCCESS";
const DELETE_TARIF_FAILURE = "arwa/tarifs/DELETE_TARIF_FAILURE";

const CHECK_CSV_TARIFS_REQUEST = "arwa/tarifs/CHECK_CSV_TARIFS_REQUEST";
const CHECK_CSV_TARIFS_SUCCESS = "arwa/tarifs/CHECK_CSV_TARIFS_SUCCESS";
const CHECK_CSV_TARIFS_FAILURE = "arwa/tarifs/CHECK_CSV_TARIFS_FAILURE";

const INSERT_TARIFS_REQUEST = "arwa/tarifs/INSERT_TARIFS_REQUEST";
const INSERT_TARIFS_SUCCESS = "arwa/tarifs/INSERT_TARIFS_SUCCESS";
const INSERT_TARIFS_FAILURE = "arwa/tarifs/INSERT_TARIFS_FAILURE";

const CLEAR_CHECK_CSV = "arwa/tarifs/CLEAR_CHECK_CSV";

export const tarifsActionsHandlers = {
  [FETCH_ALL_TARIFS_REQUEST]: (state) =>
    flow(set("loaded.tarifs", false), set("loading.tarifs", true))(state),
  [FETCH_TARIF_REQUEST]: (state) => {
    return flow(set("loaded.tarifs", false), set("loading.tarifs", true))(state);
  },
  [FETCH_ALL_TARIFS_SUCCESS]: (state, action) => {
    return flow(
      set("entities.tarifs", action.response.entities.tarifs || {}),
      set("loaded.tarifs", true),
      set("loading.tarifs", false)
    )(state);
  },

  [FETCH_ALL_TARIFS_FAILURE]: (state) =>
    flow(set("loaded.tarifs", false), set("loading.tarifs", false))(state),
  [FETCH_TARIF_FAILURE]: (state) =>
    flow(set("loaded.tarifs", false), set("loading.tarifs", false))(state),
  [FETCH_TARIF_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.tarifs.${action.id}`, action.response.entities.tarifs[action.id]),
      set("loaded.tarifs", true),
      set("loading.tarifs", false)
    )(state);
  },
  [SET_TARIF_REQUEST]: (state) =>
    flow(set("loaded.tarifs", false), set("loading.tarifs", true))(state),
  [SET_TARIF_SUCCESS]: (state, action) =>
    flow(
      set("entities.tarifs", {
        ...state.entities.tarifs,
        ...action.response.entities.tarifs,
      }),
      set("loaded.tarifs", true),
      set("loading.tarifs", false)
    )(state),
  [SET_TARIF_FAILURE]: (state, action) =>
    flow(set("loaded.tarifs", false), set("loading.tarifs", false))(state),
  [DELETE_TARIF_SUCCESS]: (state, action) =>
    flow(set("entities.tarifs", omit(state.entities.tarifs, action.id)))(state),

  [CHECK_CSV_TARIFS_REQUEST]: (state) =>
    flow(
      set("config.isCheckCSV", false),
      set("config.errorsCheckCSV", []),
      set("config.tarifsData", []),
      set("config.insertTarifsSuccess", false),
      set("loaded.tarifs", false),
      set("loading.tarifs", true),
      set("loading.checkCSV", true)
    )(state),
  [CHECK_CSV_TARIFS_SUCCESS]: (state, action) => {
    return flow(
      set("config.isCheckCSV", true),
      set("config.insertTarifsSuccess", false),
      set("config.tarifsData", action.response),
      set("config.errorsCheckCSV", null),
      set("loading.checkCSV", false)
    )(state);
  },

  [CHECK_CSV_TARIFS_FAILURE]: (state, action) => {
    return flow(
      set("config.isCheckCSV", false),
      set("config.errorsCheckCSV", action.data),
      set("config.insertTarifsSuccess", false),
      set("loaded.tarifs", false),
      set("loading.tarifs", false),
      set("loading.checkCSV", false)
    )(state);
  },
  [INSERT_TARIFS_REQUEST]: (state) =>
    flow(
      set("config.isCheckCSV", false),
      set("config.errorsCheckCSV", []),
      set("config.insertTarifsSuccess", false),
      set("config.tarifsData", []),
      set("loaded.tarifs", false),
      set("loading.tarifs", true),
      set("loading.insertTarifs", true)
    )(state),
  [INSERT_TARIFS_SUCCESS]: (state, action) => {
    return flow(
      set("config.isCheckCSV", true),
      set("config.insertTarifsSuccess", true),
      set("config.tarifsData", action.response),
      set("config.errorsCheckCSV", null),
      set("loading.insertTarifs", false)
    )(state);
  },

  [INSERT_TARIFS_FAILURE]: (state, action) => {
    return flow(
      set("config.isCheckCSV", false),
      set("config.insertTarifsSuccess", false),
      set("config.errorsCheckCSV", action.data),
      set("loaded.tarifs", false),
      set("loading.tarifs", false),
      set("loading.insertTarifs", false)
    )(state);
  },

  [CLEAR_CHECK_CSV]: (state, action) => {
    return flow(
      set("config.isCheckCSV", false),
      set("config.insertTarifsSuccess", false),
      set("config.errorsCheckCSV", null),
      set("loaded.tarifs", false),
      set("loading.tarifs", false)
    )(state);
  },
};

export function checkCSVFile(filename) {
  return {
    filename,
    [CALL_API]: {
      types: [CHECK_CSV_TARIFS_REQUEST, CHECK_CSV_TARIFS_SUCCESS, CHECK_CSV_TARIFS_FAILURE],
      method: "POST",
      endpoint: "/tarifs/checkCSV",
      body: {
        filename,
      },
      successMessage: "Votre fichier CSV est conforme",
    },
  };
}
export function clearCheckCSV() {
  console.log("clearCheckCSV");
  return {
    type: CLEAR_CHECK_CSV,
  };
}
export function insertTarifs(reseauId, data) {
  return {
    data,
    [CALL_API]: {
      types: [INSERT_TARIFS_REQUEST, INSERT_TARIFS_SUCCESS, INSERT_TARIFS_FAILURE],
      method: "POST",
      endpoint: "/tarifs/insertTarifs",
      body: {
        reseauId,
        data,
      },
      successMessage: "Les tarifs ont été enregistrés",
    },
  };
}
export function loadTarif(id) {
  console.log("load tarif");
  return {
    id,
    [CALL_API]: {
      types: [FETCH_TARIF_REQUEST, FETCH_TARIF_SUCCESS, FETCH_TARIF_FAILURE],
      method: "GET",
      endpoint: `/tarifs/${id}`,
      schema: Schemas.TARIF,
      successMessage: "Les tarifs ont bien été chargés",
    },
  };
}

export function loadTarifs() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_TARIFS_REQUEST, FETCH_ALL_TARIFS_SUCCESS, FETCH_ALL_TARIFS_FAILURE],
      method: "GET",
      endpoint: "/tarifs",
      schema: Schemas.TARIF_ARRAY,
      successMessage: "Les tarifs ont bien été chargés",
    },
  };
}

export function createOrUpdateTarif(tarif) {
  return {
    [CALL_API]: {
      types: [SET_TARIF_REQUEST, SET_TARIF_SUCCESS, SET_TARIF_FAILURE],
      method:
        !tarif ||
        (!isUndefined(tarif.tarif_id) && !Number.isInteger(tarif.tarif_id)) ||
        isUndefined(tarif.tarif_id)
          ? "POST"
          : "PUT",
      endpoint:
        !tarif ||
        (!isUndefined(tarif.tarif_id) && !Number.isInteger(tarif.tarif_id)) ||
        isUndefined(tarif.tarif_id)
          ? "/tarifs"
          : `/tarifs/${tarif.tarif_id}`,
      schema: Schemas.TARIF,
      body: tarif,
      successMessage: "Votre Fichier est enregistré avec succès",
    },
  };
}

export function deleteTarif(id) {
  return {
    id,
    [CALL_API]: {
      types: [DELETE_TARIF_REQUEST, DELETE_TARIF_SUCCESS, DELETE_TARIF_FAILURE],
      method: "DELETE",
      endpoint: `/tarifs/${id}`,
      successMessage: "Tarif supprimé",
    },
  };
}
