import React from "react";
import { Provider } from "react-redux";
import "react-datepicker/dist/react-datepicker.min.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-sweet-progress/lib/style.css";

import configureStore from "../store/configureStore";

import Router from "./Router";

import "../../assets/stylesheets/app.scss";

// Import Favicons directory

import "../../assets/favicons/android-chrome-192x192.png";
import "../../assets/favicons/android-chrome-512x512.png";
import "../../assets/favicons/apple-touch-icon.png";
import "../../assets/favicons/browserconfig.xml";
import "../../assets/favicons/favicon-16x16.png";
import "../../assets/favicons/favicon-32x32.png";
import "../../assets/favicons/favicon.ico";
import "../../assets/favicons/site.webmanifest";
import "../../assets/favicons/mstile-150x150.png";
import "../../assets/favicons/safari-pinned-tab.svg";

import "../../assets/splash/apple-splash-2732-2048.png";
import "../../assets/splash/apple-splash-640-1136.png";
import "../../assets/splash/apple-splash-750-1334.png";
import "../../assets/splash/apple-splash-828-1792.png";
import "../../assets/splash/apple-splash-1125-2436.png";
import "../../assets/splash/apple-splash-1136-640.png";
import "../../assets/splash/apple-splash-1242-2208.png";
import "../../assets/splash/apple-splash-1242-2688.png";
import "../../assets/splash/apple-splash-1334-750.png";
import "../../assets/splash/apple-splash-1536-2048.png";
import "../../assets/splash/apple-splash-1668-2224.png";
import "../../assets/splash/apple-splash-1668-2388.png";
import "../../assets/splash/apple-splash-1792-828.png";
import "../../assets/splash/apple-splash-2048-1536.png";
import "../../assets/splash/apple-splash-2048-2732.png";
import "../../assets/splash/apple-splash-2208-1242.png";
import "../../assets/splash/apple-splash-2224-1668.png";
import "../../assets/splash/apple-splash-2388-1668.png";
import "../../assets/splash/apple-splash-2436-1125.png";
import "../../assets/splash/apple-splash-2688-1242.png";

const store = configureStore();

const App = () => (
  <Provider store={store}>
    <Router />
  </Provider>
);

export default App;
