import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect, dispatch } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";

import PulseLoader from "react-spinners/PulseLoader";

import { values, isUndefined, map, get, isNull, flatMap, compact, flatten } from "lodash";
import { Field, FileField, SelectField } from "react-components-linaia";
import { uploadFiles, removeFile } from "../../../store/modules/uploads";
import { checkCSVFile, insertTarifs, clearCheckCSV } from "../../../store/modules/tarifs";

import { UPLOADS_URL } from "../../../constants/Config";

const TarifEditForm = ({
  handleSubmit,
  checkCSVFile,
  insertTarifsSuccess,
  insertTarifs,
  clearCheckCSV,
  reseaux,
  upFiles,
  rmFile,
  filename,
  errorsCheckCSV,
  isCheckCSV,
  reseauId,
  reset,
  change,
  tarifsData,
  loadingInsert,
  loadingCheckCSV,
}) => {
  const resetCheck = (props) => {
    reset();
    //change("reseau_id", null);

    clearCheckCSV();
  };

  const monRenderdeCSV = (item, index, onClickRemove) => {
    console.log("DEBUG monRenderdeCSV", item);
    return (
      <div className="file">
        <span className="file-name">
          <i className="fas fa-file-spreadsheet" />
          {item}
        </span>
        <span className="file-btn" onClick={() => onClickRemove()}>
          <i className="far fa-times" />
        </span>
      </div>
    );
  };
  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="box">
          <div className="box-content">
            <div className="grid">
              <Field
                name="reseau_id"
                component={SelectField}
                options={reseaux}
                optionsKeys={{ value: "reseau_id", label: "nom" }}
                label="Réseau *"
                className="col-6_xs-12"
                classNamePrefix="react-select"
              />
              <Field
                name="csv_url"
                label="Fichier CSV *"
                component={FileField}
                base64="true"
                uploadFiles={upFiles}
                removeFile={rmFile}
                getUrl={(item) => {
                  console.log("[DEBUG] - TarifEditForm : ,", item);
                  return `${UPLOADS_URL}/${item}`;
                }}
                acceptedFiles={[]}
                buttonLabel="Importer un fichier CSV"
                className="col-6_xs-12"
                fieldClassName="csv-dropzone"
                renderFileBlock={monRenderdeCSV}
              />
            </div>

            {isCheckCSV && !insertTarifsSuccess ? (
              <div className="form-success-bloc" style={{ marginBottom: "1.5em" }}>
                <ul>Le fichier CSV est conforme</ul>
              </div>
            ) : null}
            {filename && reseauId && isNull(errorsCheckCSV) && !loadingCheckCSV && (
              <div className="btn-group center">
                {!isCheckCSV && (
                  <React.Fragment>
                    <button
                      className="btn btn-tertiary"
                      onClick={() => resetCheck(reseauId, tarifsData)}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-tertiary"
                      style={{ marginBottom: "1em", marginTop: "1em" }}
                      onClick={() => checkCSVFile(filename)}
                    >
                      Analyser le fichier CSV
                    </button>
                  </React.Fragment>
                )}
              </div>
            )}
            {isCheckCSV && !insertTarifsSuccess && !loadingCheckCSV && !loadingInsert && (
              <div className="btn-group center">
                <button
                  className="btn btn-tertiary"
                  onClick={() => resetCheck(reseauId, tarifsData)}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-tertiary"
                  onClick={() => insertTarifs(reseauId, tarifsData)}
                >
                  Mettre à jour les données
                </button>
              </div>
            )}
            <div style={{ margin: "0 auto" }} className="sweet-loading ">
              <PulseLoader size={15} color={"#075576"} loading={loadingCheckCSV || loadingInsert} />
            </div>
            {insertTarifsSuccess && (
              <React.Fragment>
                <div className="form-success-bloc">
                  <ul>Les données ont été mises à jour avec succès</ul>
                </div>
                <div className="btn-group center">
                  <button
                    onClick={() => resetCheck()}
                    style={{ marginTop: "1em" }}
                    className="btn btn-tertiary"
                  >
                    OK
                  </button>
                </div>
              </React.Fragment>
            )}
            {filename && errorsCheckCSV && errorsCheckCSV.length > 0 && (
              <div>
                <p style={{ textAlign: "center" }}>
                  Il y a {(errorsCheckCSV.length > 1 && "des erreurs") || "une erreur"} dans le
                  fichier CSV !
                </p>
                {map(flatten(errorsCheckCSV), (error) => {
                  return (
                    <div className="form-error-bloc">
                      <ul>
                        <li>{error}</li>
                      </ul>
                    </div>
                  );
                })}

                <div className="btn-group center">
                  <button
                    onClick={() => resetCheck()}
                    style={{ marginTop: "1em" }}
                    className="btn btn-tertiary"
                  >
                    OK
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </Fragment>
  );
};
TarifEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired,
};

const selector = formValueSelector("TarifEditForm");

export default connect(
  (state) => ({
    reseaux: state.data.entities.reseaux,
    gammes: state.data.entities.gammes,
    isCheckCSV: state.data.config.isCheckCSV,
    loadingInsert: state.data.loading.insertTarifs || null,
    loadingCheckCSV: state.data.loading.checkCSV || null,
    errorsCheckCSV: state.data.config.errorsCheckCSV || null,
    insertTarifsSuccess: state.data.config.insertTarifsSuccess || null,
    tarifsData: state.data.config.tarifsData || null,
    filename: selector(state, "csv_url") || null,
    reseauId: isUndefined(selector(state, "reseau_id")) ? null : selector(state, "reseau_id"),
  }),
  {
    upFiles: uploadFiles,
    rmFile: removeFile,
    checkCSVFile,
    insertTarifs,
    clearCheckCSV,
  }
)(
  reduxForm({
    form: "TarifEditForm",
    validate: (values = {}) => {
      const errors = {};
      if (isUndefined(values.reseau_id)) errors.reseau_id = "Merci de renseigner un réseau";
      return errors;
    },
  })(TarifEditForm)
);
