import { flow, set } from "lodash/fp";
import { omit } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_REGLES_GESTION_REQUEST = "ksa/reglesGestion/FETCH_ALL_REGLES_GESTION_REQUEST";
export const FETCH_ALL_REGLES_GESTION_SUCCESS = "kwa/reglesGestion/FETCH_REGLES_GESTION_SUCCESS";

const FETCH_ALL_REGLES_GESTION_FAILURE = "ksa/reglesGestion/FETCH_ALL_REGLES_GESTION_FAILURE";

const FETCH_REGLE_GESTION_REQUEST = "ksa/reglesGestion/FETCH_REGLE_GESTION_REQUEST";
const FETCH_REGLE_GESTION_SUCCESS = "ksa/reglesGestion/FETCH_REGLE_GESTION_SUCCESS";
const FETCH_REGLE_GESTION_FAILURE = "ksa/reglesGestion/FETCH_REGLE_GESTION_FAILURE";

const SET_REGLE_GESTION_REQUEST = "ksa/reglesGestion/SET_REGLE_GESTION_REQUEST";
const SET_REGLE_GESTION_SUCCESS = "ksa/reglesGestion/SET_REGLE_GESTION_SUCCESS";
const SET_REGLE_GESTION_FAILURE = "ksa/reglesGestion/SET_REGLE_GESTION_FAILURE";

const DELETE_REGLE_GESTION_REQUEST = "ksa/reglesGestion/DELETE_REGLE_GESTION_REQUEST";
const DELETE_REGLE_GESTION_SUCCESS = "ksa/reglesGestion/DELETE_REGLE_GESTION_SUCCESS";
const DELETE_REGLE_GESTION_FAILURE = "ksa/reglesGestion/DELETE_REGLE_GESTION_FAILURE";

export const reglesGestionActionsHandlers = {
  [FETCH_ALL_REGLES_GESTION_REQUEST]: (state) =>
    flow(set("loaded.reglesGestion", false), set("loading.reglesGestion", true))(state),
  [FETCH_REGLE_GESTION_REQUEST]: (state) => {
    return flow(set("loaded.reglesGestion", false), set("loading.reglesGestion", true))(state);
  },
  [FETCH_ALL_REGLES_GESTION_SUCCESS]: (state, action) => {
    return flow(
      set("entities.reglesGestion", action.response.entities.reglesGestion || {}),
      set("loaded.reglesGestion", true),
      set("loading.reglesGestion", false)
    )(state);
  },

  [FETCH_ALL_REGLES_GESTION_FAILURE]: (state) =>
    flow(set("loaded.reglesGestion", false), set("loading.reglesGestion", false))(state),
  [FETCH_REGLE_GESTION_FAILURE]: (state) =>
    flow(set("loaded.reglesGestion", false), set("loading.reglesGestion", false))(state),

  [FETCH_REGLE_GESTION_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.reglesGestion.${action.id}`, action.response.entities.reglesGestion[action.id]),
      set("loaded.reglesGestion", true),
      set("loading.reglesGestion", false)
    )(state);
  },

  [SET_REGLE_GESTION_REQUEST]: (state) =>
    flow(set("loaded.reglesGestion", false), set("loading.reglesGestion", true))(state),
  [SET_REGLE_GESTION_SUCCESS]: (state, action) =>
    flow(
      set("entities.reglesGestion", {
        ...state.entities.reglesGestion,
        ...action.response.entities.reglesGestion,
      }),
      set("loaded.reglesGestion", true),
      set("loading.reglesGestion", false)
    )(state),
  [SET_REGLE_GESTION_FAILURE]: (state, action) =>
    flow(set("loaded.reglesGestion", false), set("loading.reglesGestion", false))(state),

  [DELETE_REGLE_GESTION_REQUEST]: (state, action) =>
    flow(set("entities.reglesGestion", omit(state.entities.reglesGestion, action.id)))(state),
};

export function loadRegleGestion(id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_REGLE_GESTION_REQUEST,
        FETCH_REGLE_GESTION_SUCCESS,
        FETCH_REGLE_GESTION_FAILURE,
      ],
      method: "GET",
      endpoint: `/reglesGestion/${id}`,
      schema: Schemas.REGLE_GESTION,
      successMessage: "La règle de gestion a bien été chargée",
    },
  };
}

export function loadReglesGestion() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_REGLES_GESTION_REQUEST,
        FETCH_ALL_REGLES_GESTION_SUCCESS,
        FETCH_ALL_REGLES_GESTION_FAILURE,
      ],
      method: "GET",
      endpoint: "/reglesGestion",
      schema: Schemas.REGLE_GESTION_ARRAY,
    },
  };
}

export function createOrUpdateRegleGestion(regleGestion) {
  return {
    [CALL_API]: {
      types: [SET_REGLE_GESTION_REQUEST, SET_REGLE_GESTION_SUCCESS, SET_REGLE_GESTION_FAILURE],
      method: !regleGestion || !Number.isInteger(regleGestion.regle_gestion_id) ? "POST" : "PUT",
      endpoint:
        !regleGestion || !Number.isInteger(regleGestion.regle_gestion_id)
          ? "/reglesGestion"
          : `/reglesGestion/${regleGestion.regle_gestion_id}`,
      schema: Schemas.REGLE_GESTION,
      body: regleGestion,
      successMessage: "La règle de gestion à bien été enregistré",
    },
  };
}

export function deleteRegleGestion(id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_REGLE_GESTION_REQUEST,
        DELETE_REGLE_GESTION_SUCCESS,
        DELETE_REGLE_GESTION_FAILURE,
      ],
      method: "DELETE",
      endpoint: `/reglesGestion/${id}`,
      successMessage: "La règle de gestion a été supprimé avec succès",
    },
  };
}
