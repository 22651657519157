import { flow, set } from "lodash/fp";
import { omit, isUndefined } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_REMISES_MONTURES_REQUEST = "ksa/remisesMontures/FETCH_ALL_REMISES_MONTURES_REQUEST";
export const FETCH_ALL_REMISES_MONTURES_SUCCESS =
  "kwa/remisesMontures/FETCH_ALL_REMISES_MONTURES_SUCCESS";

const FETCH_ALL_REMISES_MONTURES_FAILURE = "ksa/remisesMontures/FETCH_ALL_REMISES_MONTURES_FAILURE";

const FETCH_REMISE_MONTURE_REQUEST = "ksa/remisesMontures/FETCH_REMISE_MONTURE_REQUEST";
const FETCH_REMISE_MONTURE_SUCCESS = "ksa/remisesMontures/FETCH_REMISE_MONTURE_SUCCESS";
const FETCH_REMISE_MONTURE_FAILURE = "ksa/remisesMontures/FETCH_REMISE_MONTURE_FAILURE";

const SET_REMISE_MONTURE_REQUEST = "ksa/remisesMontures/SET_REMISE_MONTURE_REQUEST";
const SET_REMISE_MONTURE_SUCCESS = "ksa/remisesMontures/SET_REMISE_MONTURE_SUCCESS";
const SET_REMISE_MONTURE_FAILURE = "ksa/remisesMontures/SET_REMISE_MONTURE_FAILURE";

const DELETE_REMISE_MONTURE_REQUEST = "ksa/remisesMontures/DELETE_REMISE_MONTURE_REQUEST";
const DELETE_REMISE_MONTURE_SUCCESS = "ksa/remisesMontures/DELETE_REMISE_MONTURE_SUCCESS";
const DELETE_REMISE_MONTURE_FAILURE = "ksa/remisesMontures/DELETE_REMISE_MONTURE_FAILURE";

export const remisesMonturesActionsHandlers = {
  [FETCH_ALL_REMISES_MONTURES_REQUEST]: (state) =>
    flow(set("loaded.remisesMontures", false), set("loading.remisesMontures", true))(state),
  [FETCH_REMISE_MONTURE_REQUEST]: (state) => {
    return flow(set("loaded.remisesMontures", false), set("loading.remisesMontures", true))(state);
  },
  [FETCH_ALL_REMISES_MONTURES_SUCCESS]: (state, action) => {
    return flow(
      set("entities.remisesMontures", action.response.entities.remisesMontures || {}),
      set("loaded.remisesMontures", true),
      set("loading.remisesMontures", false)
    )(state);
  },

  [FETCH_ALL_REMISES_MONTURES_FAILURE]: (state) =>
    flow(set("loaded.remisesMontures", false), set("loading.remisesMontures", false))(state),
  [FETCH_REMISE_MONTURE_FAILURE]: (state) =>
    flow(set("loaded.remisesMontures", false), set("loading.remisesMontures", false))(state),

  [FETCH_REMISE_MONTURE_SUCCESS]: (state, action) => {
    return flow(
      set(
        `entities.remisesMontures.${action.id}`,
        action.response.entities.remisesMontures[action.id]
      ),
      set("loaded.remisesMontures", true),
      set("loading.remisesMontures", false)
    )(state);
  },

  [SET_REMISE_MONTURE_REQUEST]: (state) =>
    flow(set("loaded.remisesMontures", false), set("loading.remisesMontures", true))(state),
  [SET_REMISE_MONTURE_SUCCESS]: (state, action) =>
    flow(
      set("entities.remisesMontures", {
        ...state.entities.remisesMontures,
        ...action.response.entities.remisesMontures,
      }),
      set("loaded.remisesMontures", true),
      set("loading.remisesMontures", false)
    )(state),
  [SET_REMISE_MONTURE_FAILURE]: (state, action) =>
    flow(set("loaded.remisesMontures", false), set("loading.remisesMontures", false))(state),

  [DELETE_REMISE_MONTURE_SUCCESS]: (state, action) =>
    flow(set("entities.remisesMontures", omit(state.entities.remisesMontures, action.id)))(state),
};

export function loadRemiseMonture(id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_REMISE_MONTURE_REQUEST,
        FETCH_REMISE_MONTURE_SUCCESS,
        FETCH_REMISE_MONTURE_FAILURE,
      ],
      method: "GET",
      endpoint: `/remisesMontures/${id}`,
      schema: Schemas.REMISE_MONTURE,
      successMessage: "La remise a bien été chargée",
    },
  };
}

export function loadRemisesMontures() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_REMISES_MONTURES_REQUEST,
        FETCH_ALL_REMISES_MONTURES_SUCCESS,
        FETCH_ALL_REMISES_MONTURES_FAILURE,
      ],
      method: "GET",
      endpoint: "/remisesMontures",
      schema: Schemas.REMISE_MONTURE_ARRAY,
    },
  };
}

export function createOrUpdateRemiseMonture(remiseMonture) {
  console.log("createOrUpdateReseau()", remiseMonture.remise_monture_id);

  return {
    [CALL_API]: {
      types: [SET_REMISE_MONTURE_REQUEST, SET_REMISE_MONTURE_SUCCESS, SET_REMISE_MONTURE_FAILURE],
      method: !remiseMonture || !Number.isInteger(remiseMonture.remise_monture_id) ? "POST" : "PUT",
      endpoint:
        !remiseMonture || !Number.isInteger(remiseMonture.remise_monture_id)
          ? "/remisesMontures"
          : `/remisesMontures/${remiseMonture.remise_monture_id}`,
      schema: Schemas.REMISE_MONTURE,
      body: remiseMonture,
      successMessage: "Le remise à bien été enregistré",
    },
  };
}

export function deleteRemiseMonture(id) {
  console.log("deleteRemiseMonture() id===", id);
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_REMISE_MONTURE_REQUEST,
        DELETE_REMISE_MONTURE_SUCCESS,
        DELETE_REMISE_MONTURE_FAILURE,
      ],
      method: "DELETE",
      endpoint: `/remisesMontures/${id}`,
      successMessage: "La remise a été supprimé avec succès",
    },
  };
}
