import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { capitalize, get, orderBy } from "lodash";
import { connect } from "react-redux";
import { ButtonConfirmModal } from "react-components-linaia";

import TableListView from "../../shared/TableListView";

const GammesTableListView = ({
  history: { push },
  gammes,
  marques,
  baseUrl,
  onClickDelete,
  isAdmin,
}) => (
  <TableListView
    className="table-boxed full-height"
    data={gammes}
    cols={{
      numero_order: {
        label: "Numéro d'ordre",
        className: "col",
        value: (value) => value,
      },
      marque_id: {
        label: "Marque",
        className: "col",
        value: (marque_id) => get(marques, `${marque_id}.libelle`),
      },
      libelle: {
        label: "Libelle",
        className: "col-2_sm-12 text-center",
        value: capitalize,
      },
      masquee: {
        label: "Masquée",
        className: "col-2_sm-12 text-center",
        value: (value) => (value ? "Oui" : "Non"),
      },
      ensavoirplus: {
        label: "En savoir plus",
        className: "col-2_sm-12 text-center",
        value: (value) => (value ? "Oui" : "Non"),
      },
      k_sph_cyl_inf_3: {
        label: "Sphère cylindrique inférieur",
        className: "col-2_sm-12 text-center",
        value: (value) => (value ? "Oui" : "Non"),
      },
      k_aspherisation: {
        label: "Aspherisation Kalixia",
        className: "col-2_sm-12 text-center",
        value: (value) => (value ? "Oui" : "Non"),
      },
    }}
    search={["marque_id", "libelle"]}
    searchLabel={"Rechercher une gamme"}
    sortByDefault="numero_order"
    sortOrderDefault="asc"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <i className="far fa-plus" />
          <span>Ajouter une gamme</span>
        </NavLink>
      </div>
    }
    rowButtons={(gamme) => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {gamme && (
            <NavLink className="btn" to={`${baseUrl}/${gamme.gamme_id}/edit`}>
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {gamme && (
            <ButtonConfirmModal
              onConfirmationButton={() => onClickDelete(gamme.gamme_id)}
              message="Voulez-vous vraiment supprimer cette gamme ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

GammesTableListView.propTypes = {
  gammes: PropTypes.object,
  isAdmin: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect((state, { gammeId }) => ({
  gammes: state.data.entities.gammes,
  marques: state.data.entities.marques,
  gammeId,
}))(withRouter(GammesTableListView));
