import { flow, set } from "lodash/fp";
import { omit, isUndefined } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_GAMMES_REQUEST = "ksa/gammes/FETCH_ALL_GAMMES_REQUEST";
export const FETCH_ALL_GAMMES_SUCCESS = "kwa/gammes/FETCH_ALL_GAMMES_SUCCESS";

const FETCH_ALL_GAMMES_FAILURE = "ksa/gammes/FETCH_ALL_RESEAUX_FAILURE";

const FETCH_GAMME_REQUEST = "ksa/gammes/FETCH_GAMME_REQUEST";
const FETCH_GAMME_SUCCESS = "ksa/gammes/FETCH_GAMME_SUCCESS";
const FETCH_GAMME_FAILURE = "ksa/gammes/FETCH_GAMME_FAILURE";

const SET_GAMME_REQUEST = "ksa/gammes/SET_GAMME_REQUEST";
const SET_GAMME_SUCCESS = "ksa/gammes/SET_GAMME_SUCCESS";
const SET_GAMME_FAILURE = "ksa/gammes/SET_GAMME_FAILURE";

const DELETE_GAMME_REQUEST = "ksa/gammes/DELETE_GAMME_REQUEST";
const DELETE_GAMME_SUCCESS = "ksa/gammes/DELETE_GAMME_SUCCESS";
const DELETE_GAMME_FAILURE = "ksa/gammes/DELETE_GAMME_FAILURE";

export const gammesActionsHandlers = {
  [FETCH_ALL_GAMMES_REQUEST]: (state) =>
    flow(set("loaded.gammes", false), set("loading.gammes", true))(state),
  [FETCH_GAMME_REQUEST]: (state) => {
    return flow(set("loaded.gammes", false), set("loading.gammes", true))(state);
  },
  [FETCH_ALL_GAMMES_SUCCESS]: (state, action) => {
    return flow(
      set("entities.gammes", action.response.entities.gammes || {}),
      set("loaded.gammes", true),
      set("loading.gammes", false)
    )(state);
  },

  [FETCH_ALL_GAMMES_FAILURE]: (state) =>
    flow(set("loaded.gammes", false), set("loading.gammes", false))(state),
  [FETCH_GAMME_FAILURE]: (state) =>
    flow(set("loaded.gammes", false), set("loading.gammes", false))(state),

  [FETCH_GAMME_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.gammes.${action.id}`, action.response.entities.gammes[action.id]),
      set("loaded.gammes", true),
      set("loading.gammes", false)
    )(state);
  },

  [SET_GAMME_REQUEST]: (state) =>
    flow(set("loaded.gammes", false), set("loading.gammes", true))(state),
  [SET_GAMME_SUCCESS]: (state, action) =>
    flow(
      set("entities.gammes", {
        ...state.entities.gammes,
        ...action.response.entities.gammes,
      }),
      set("loaded.gammes", true),
      set("loading.gammes", false)
    )(state),
  [SET_GAMME_FAILURE]: (state, action) =>
    flow(set("loaded.gammes", false), set("loading.gammes", false))(state),

  [DELETE_GAMME_SUCCESS]: (state, action) =>
    flow(set("entities.gammes", omit(state.entities.gammes, action.id)))(state),
};

export function loadGamme(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_GAMME_REQUEST, FETCH_GAMME_SUCCESS, FETCH_GAMME_FAILURE],
      method: "GET",
      endpoint: `/gammes/${id}`,
      schema: Schemas.GAMME,
      successMessage: "La gamme a bien été chargée",
    },
  };
}

export function loadGammes() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_GAMMES_REQUEST, FETCH_ALL_GAMMES_SUCCESS, FETCH_ALL_GAMMES_FAILURE],
      method: "GET",
      endpoint: "/gammes",
      schema: Schemas.GAMME_ARRAY,
    },
  };
}

export function createOrUpdateGamme(gamme) {
  return {
    [CALL_API]: {
      types: [SET_GAMME_REQUEST, SET_GAMME_SUCCESS, SET_GAMME_FAILURE],
      method: !gamme || !Number.isInteger(gamme.gamme_id) ? "POST" : "PUT",
      endpoint:
        !gamme || !Number.isInteger(gamme.gamme_id) ? "/gammes" : `/gammes/${gamme.gamme_id}`,
      schema: Schemas.GAMME,
      body: gamme,
      successMessage: "Gamme enregistré avec succès",
    },
  };
}

export function deleteGamme(id) {
  console.log("deleteGamme() id===", id);
  return {
    id,
    [CALL_API]: {
      types: [DELETE_GAMME_REQUEST, DELETE_GAMME_SUCCESS, DELETE_GAMME_FAILURE],
      method: "DELETE",
      endpoint: `/gammes/${id}`,
      successMessage: "Gamme supprimé avec succès",
    },
  };
}
