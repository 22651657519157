import React from "react";
import { connect } from "react-redux";
import BaseLayout from "../../containers/shared/BaseLayout";
import { withRouter, NavLink } from "react-router-dom";

const ClasseAView = ({ history: { push }, history, reseau }) => {
  return (
    <BaseLayout title="Classe A">
      <div className="gridWrapper">
        <div className="grid-center">
          <div className="col-6_xs-12_lg-10">
            <div className="grid-center">
              <div className="col-6_xs-12">
                <div className="box box-classeA box-secondary">
                  <div className="box-header">
                    <div className="box-title text-center">Panier A</div>
                  </div>
                  <div className="box-content">
                    <div className="highlight-bloc">
                      Offre mise en place par le ministère de la santé, offre de base de la sécurité
                      sociale équivalent à la CMU donnant un accès au soin à tous.
                    </div>
                    <h3>Corrige</h3>
                    <ul>
                      <li>La vue</li>
                    </ul>
                    <h3>Atténue</h3>
                    <ul>
                      <li>Les reflets</li>
                      <li>Les rayures</li>
                      <li>L’épaisseur</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-6_xs-12">
                <div className="box box-classeA panierB">
                  <div className="box-header">
                    <div className="box-title text-center">Panier B</div>
                  </div>
                  <div className="box-content">
                    <div className="highlight-bloc">
                      <strong>Une offre personnalisée</strong>
                      Une offre qui permet de satisfaire et répondre aux besoins de vos clients
                    </div>
                    <h3>Protège</h3>
                    <ul>
                      <li>Des UV</li>
                      <li>De la lumière bleue</li>
                      <li>De la sensibilité à la lumière</li>
                    </ul>
                    <h3>Optimise</h3>
                    <ul>
                      <li>La précision optique</li>
                      <li>Le confort visuel</li>
                      <li>La réduction de fatigue visuelle</li>
                      <li>La vitesse d’adaptation</li>
                      <li>L’esthétique</li>
                      <li>Le clarté de vision</li>
                      <li>La facilité d’entretien</li>
                      <li>La durabilité</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="workspace-footer">
        <div className="grid-center-noBottom">
          {/* Colonne de gauche */}
          <div className="col flexbox">
            <button className="btn btn-secondary btn-big" onClick={() => history.goBack()}>
              <i className="item-icon far fa-long-arrow-left" />
            </button>
          </div>
          {reseau && reseau.classea_nikon === true ? (
            <div className="col flexbox justifyEnd">
              <NavLink className="btn btn-primary btn-big" to={`/verres-nikon/` + reseau.reseau_id}>
                Verres Panier A
              </NavLink>
              <NavLink
                className="btn btn-primary btn-big"
                to={`/classeA/seconde-paire/` + reseau.reseau_id}
              >
                2ème Paire
              </NavLink>
            </div>
          ) : (
            <div className="col flexbox justifyEnd">
              <NavLink
                className="btn btn-primary btn-big"
                to={`/classeA/seconde-paire/` + reseau.reseau_id}
              >
                2ème Paire
              </NavLink>
            </div>
          )}
          {/* Colonne de droite */}
        </div>
      </div>
    </BaseLayout>
  );
};

// export default ClasseAView;

export default withRouter(
  connect(
    (state) => ({
      reseau: state.data.config.reseau,
    }),
    {}
  )(ClasseAView)
);
