export default {
  entities: {
    accounts: {},
    options: {},
    models: {},
    uploadContents: {},
    posts: {},
    tarifs: {},
    reglesGestion: {},
    remisesMontures: {},
    gammes: {},
    images: {},
    garanties: {},
    services: {},
    optionVerres: {},
    reseaux: {},
    appSettings: {},
    marques: {}
  },
  loaded: {
    appstorage: false,
    accounts: false,
    options: false,
    models: false,
    uploadContents: false,
    posts: false,
    tarifs: false,
    reglesGestion: false,
    remisesMontures: false,
    gammes: false,
    images: false,
    garanties: false,
    services: false,
    optionVerres: false,
    reseaux: false,
    appSettings: false,
    marques: false
  },
  loading: {
    accounts: false,
    models: false,
    uploadContents: false,
    posts: false,
    tarifs: false,
    options: false,
    reglesGestion: false,
    remisesMontures: false,
    gammes: false,
    images: false,
    garanties: false,
    services: false,
    optionVerres: false,
    reseaux: false,
    appSettings: false,
    marques: false
  },
  message: {
    text: "",
    messageType: "",
    delay: 0
  },
  config: {
    menuIsTiny: null,
    menuIsOpen: null,
    reseau: {},
    correction: {},
    marque: {},
  }
};
