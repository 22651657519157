import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { capitalize, get, orderBy } from "lodash";
import { connect } from "react-redux";
import { ButtonConfirmModal } from "react-components-linaia";

import TableListView from "../../shared/TableListView";

import { UPLOADS_URL } from "../../../constants/Config";

const OptionsTableListView = ({
  history: { push },
  options,
  reseaux,
  baseUrl,
  onClickDelete,
  isAdmin,
}) => (
  <TableListView
    className="table-boxed full-height"
    data={options}
    cols={{
      reseau_id: {
        label: "Nom du réseau",
        className: "col",
        value: (reseau_id) => get(reseaux, `${reseau_id}.nom`),
      },

      libelle: {
        label: "Options (tarif par verre)",
        className: "col",
        value: capitalize,
      },
      tarif_unifocaux: {
        label: "Tarif verre unifocal",
        className: "col-2_sm-12 text-center",
        value: capitalize,
      },
      tarif_progressifs: {
        label: "Tarif verre progressif",
        className: "col-2_sm-12 text-center",
        value: capitalize,
      },
    }}
    search={["reseau_id", "libelle"]}
    searchLabel={"Rechercher une option"}
    sortByDefault="option_id"
    sortOrderDefault="asc"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <i className="far fa-plus" />
          <span>Ajouter une option</span>
        </NavLink>
      </div>
    }
    rowButtons={(option) => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {option && (
            <NavLink className="btn" to={`${baseUrl}/${option.option_id}/edit`}>
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {option && (
            <ButtonConfirmModal
              onConfirmationButton={() => onClickDelete(option.option_id)}
              message="Voulez-vous vraiment supprimer cette option ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

OptionsTableListView.propTypes = {
  options: PropTypes.object,
  reseaux: PropTypes.object,
  isAdmin: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect((state, { optionId }) => ({
  options: state.data.entities.options,
  reseaux: state.data.entities.reseaux,
  optionId,
}))(withRouter(OptionsTableListView));
