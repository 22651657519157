import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";
import { values, isUndefined } from "lodash";
import { pagesData } from "../../../utils/dataGlobals";
import { loadImages } from "../../../store/modules/images";
import { Field, FileField, InputField, ToggleField, SelectField } from "react-components-linaia";

import { uploadFiles, removeFile } from "../../../store/modules/uploads";

import { UPLOADS_URL } from "../../../constants/Config";

const ImageEditForm = ({ handleSubmit, baseUrl, reseaux, initialValues, upFiles, rmFile }) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.image_id >= 0 ? "Modifier l'image" : "Ajouter une image"}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="reseau_id"
            component={SelectField}
            label="Nom du réseau *"
            options={reseaux}
            optionsKeys={{ value: "reseau_id", label: "nom" }}
            className="col-12"
            classNamePrefix="react-select"
          />
          <Field
            name="page"
            component={SelectField}
            options={pagesData}
            optionsKeys={{ value: "value", label: "label" }}
            label="Page *"
            className="col-12"
            classNamePrefix="react-select"
          />
          <Field
            name="url"
            label="Image *"
            component={FileField}
            uploadFiles={upFiles}
            removeFile={rmFile}
            getUrl={item => `${UPLOADS_URL}/${item}`}
            buttonLabel="Choisir un Fichier"
            className="col-12"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-tertiary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

ImageEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired
};

export default reduxForm({
  form: "ImageEditForm",
  validate: (values = {}) => {
    const errors = {};
    if (isUndefined(values.reseau_id)) errors.reseau_id = "Merci de renseigner le nom du réseau";
    if (!values.page) errors.page = "Merci de renseigner une page";
    if (!values.url) errors.url = "Merci d'ajouter une image";
    return errors;
  }
})(
  connect(
    (state, { imageId }) => ({
      images: state.data.entities.images,
      reseaux: state.data.entities.reseaux
    }),
    { upFiles: uploadFiles, rmFile: removeFile, loadImages }
  )(ImageEditForm)
);
