import { flow, set } from "lodash/fp";
import { omit, isUndefined } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_APPSETTINGS_REQUEST = "ksa/appSettings/FETCH_ALL_APPSETTINGS_REQUEST";
export const FETCH_ALL_APPSETTINGS_SUCCESS = "kwa/appSettings/FETCH_ALL_APPSETTINGS_SUCCESS";

const FETCH_ALL_APPSETTINGS_FAILURE = "ksa/appSettings/FETCH_ALL_APPSETTINGS_FAILURE";

const FETCH_APPSETTING_REQUEST = "ksa/appSettings/FETCH_APPSETTING_REQUEST";
const FETCH_APPSETTING_SUCCESS = "ksa/appSettings/FETCH_APPSETTING_SUCCESS";
const FETCH_APPSETTING_FAILURE = "ksa/appSettings/FETCH_APPSETTING_FAILURE";

const SET_APPSETTING_REQUEST = "ksa/appSettings/SET_APPSETTING_REQUEST";
const SET_APPSETTING_SUCCESS = "ksa/appSettings/SET_APPSETTING_SUCCESS";
const SET_APPSETTING_FAILURE = "ksa/appSettings/SET_APPSETTING_FAILURE";

const DELETE_APPSETTING_REQUEST = "ksa/appSettings/DELETE_APPSETTING_REQUEST";
const DELETE_APPSETTING_SUCCESS = "ksa/appSettings/DELETE_APPSETTING_SUCCESS";
const DELETE_APPSETTING_FAILURE = "ksa/appSettings/DELETE_APPSETTING_FAILURE";

export const appSettingsActionsHandlers = {
  [FETCH_ALL_APPSETTINGS_REQUEST]: (state) =>
    flow(set("loaded.appSettings", false), set("loading.appSettings", true))(state),
  [FETCH_APPSETTING_REQUEST]: (state) => {
    return flow(set("loaded.appSettings", false), set("loading.appSettings", true))(state);
  },
  [FETCH_ALL_APPSETTINGS_SUCCESS]: (state, action) => {
    return flow(
      set("entities.appSettings", action.response.entities.appSettings || {}),
      set("loaded.appSettings", true),
      set("loading.appSettings", false)
    )(state);
  },

  [FETCH_ALL_APPSETTINGS_FAILURE]: (state) =>
    flow(set("loaded.appSettings", false), set("loading.appSettings", false))(state),
  [FETCH_APPSETTING_FAILURE]: (state) =>
    flow(set("loaded.appSettings", false), set("loading.appSettings", false))(state),

  [FETCH_APPSETTING_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.appSettings.${action.id}`, action.response.entities.appSettings[action.id]),
      set("loaded.appSettings", true),
      set("loading.appSettings", false)
    )(state);
  },

  [SET_APPSETTING_REQUEST]: (state) =>
    flow(set("loaded.appSettings", false), set("loading.appSettings", true))(state),
  [SET_APPSETTING_SUCCESS]: (state, action) =>
    flow(
      set("entities.appSettings", {
        ...state.entities.appSettings,
        ...action.response.entities.appSettings,
      }),
      set("loaded.appSettings", true),
      set("loading.appSettings", false)
    )(state),
  [SET_APPSETTING_FAILURE]: (state, action) =>
    flow(set("loaded.appSettings", false), set("loading.appSettings", false))(state),

  [DELETE_APPSETTING_SUCCESS]: (state, action) =>
    flow(set("entities.appSettings", omit(state.entities.appSettings, action.id)))(state),
};

export function loadAppSetting(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_APPSETTING_REQUEST, FETCH_APPSETTING_SUCCESS, FETCH_APPSETTING_FAILURE],
      method: "GET",
      endpoint: `/appSettings/${id}`,
      schema: Schemas.APPSETTING,
      successMessage: "Les paramètres de l'application a bien été chargé",
    },
  };
}

export function loadAppSettings() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_APPSETTINGS_REQUEST, FETCH_ALL_APPSETTINGS_SUCCESS, FETCH_ALL_APPSETTINGS_FAILURE],
      method: "GET",
      endpoint: "/appSettings",
      schema: Schemas.APPSETTING_ARRAY,
    },
  };
}

export function createOrUpdateAppSetting(appSetting) {
  console.log("createOrUpdateAppSetting()", appSetting.app_setting_id);

  return {
    [CALL_API]: {
      types: [SET_APPSETTING_REQUEST, SET_APPSETTING_SUCCESS, SET_APPSETTING_FAILURE],
      method: !appSetting || !Number.isInteger(appSetting.app_setting_id) ? "POST" : "PUT",
      endpoint:
        !appSetting || !Number.isInteger(appSetting.app_setting_id)
          ? "/appSettings"
          : `/appSettings/${appSetting.app_setting_id}`,
      schema: Schemas.APPSETTING,
      body: appSetting,
      successMessage: "Le réseau a bien été enregistré",
    },
  };
}

export function deleteAppSetting(id) {
  console.log("deleteAppSetting() id===", id);
  return {
    id,
    [CALL_API]: {
      types: [DELETE_APPSETTING_REQUEST, DELETE_APPSETTING_SUCCESS, DELETE_APPSETTING_FAILURE],
      method: "DELETE",
      endpoint: `/appSettings/${id}`,
      successMessage: "Le réseau a été supprimé avec succès",
    },
  };
}
