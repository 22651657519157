import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InputField, Field, RadioField, ToggleField } from "react-components-linaia";
import { reduxForm, reset, formValueSelector, change } from "redux-form";
import { values, isUndefined } from "lodash";
import { withRouter } from "react-router-dom";

import BaseLayout from "../../containers/shared/BaseLayout";
import { CORRECTION, PERCEE_NYLOR, ANTIREFLET } from "../../constants/Properties";
import { setConfig } from "../../store/modules/globals";

const KalixiaForm = ({
  reseaux,
  history: { push },
  history,
  handleSubmit,
  loadReseaux,
  typeCorrection,
  change,
  reset,
  od_sphere,
  od_cylindre,
  od_addition,
}) => {
  const submit = (values, dispatch, props) => {
    props.setConfig("correction", props.values);
    push(`/modele-verre`);
  };
  function duplicate() {
    change("og_sphere", od_sphere);
    change("og_cylindre", od_cylindre);
    change("og_addition", od_addition);
  }
  function clearAll() {
    change("og_sphere", null);
    change("og_cylindre", null);
    change("od_sphere", null);
    change("od_cylindre", null);
    change("galbe", null);
    change("distance_vo", null);
    change("angle", null);
    change("diff_vlvp", null);
    change("price", null);
    change("type_correction", "U");
    change("prisme", false);
    change("moins_16", false);
  }
  const resetSign_od_sphere = () => change("od_sphere", "");
  const resetSign_od_cylindre = () => change("od_cylindre", "");
  const resetSign_og_sphere = () => change("og_sphere", "");
  const resetSign_og_cylindre = () => change("og_cylindre", "");

  const resetSign_galbe = () => change("galbe", "");
  const resetSign_distance_vo = () => change("distance_vo", "");
  const resetSign_angle = () => change("angle", "");
  const resetSign_diff_vlvp = () => change("diff_vlvp", "");

  const decimalReplacer = (value) => value.replace(",", ".");

  return (
    <BaseLayout>
      <form className="" onSubmit={handleSubmit(submit)} name="kalixiaForm">
        <div className="gridWrapper">
          <div className="grid-spaceBetween-middle">
            <div className="col">
              <div className="grid-noBottom">
                <Field
                  name="type_correction"
                  component={RadioField}
                  options={CORRECTION}
                  placeholder=""
                  label=""
                  className="col-0"
                  classNameGroup="radio-toggle"
                />
                <div className="col-0">
                  <button
                    class="btn btn-tertiary"
                    type="button"
                    onClick={() => {
                      return Promise.resolve().then(() => clearAll());
                    }}
                  >
                    <i class="fas fa-trash-alt"></i>
                    <span>Clear All</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-0">
              <div className="grid-noBottom">
                <div className="col-0">
                  <div className="box box-form">
                    <Field
                      name="moins_16"
                      component={ToggleField}
                      placeholder=""
                      className="box-content"
                      fieldClassName="checkbox"
                      label="-16 ans"
                    />
                  </div>
                </div>
                <div className="col-0">
                  <div className="box box-form">
                    <Field
                      name="prisme"
                      component={ToggleField}
                      placeholder=""
                      className="box-content"
                      fieldClassName="checkbox"
                      label="Prisme"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-8">
              <div class="grid">
                <div className="col">
                  <div className="box box-form">
                    <div className="box-header">
                      <div className="box-title">Oeil droit</div>
                    </div>
                    <div className="box-content">
                      <button
                        className="btn btn-tertiary btn-duplicate"
                        type="button"
                        onClick={() => duplicate()}
                      >
                        <i className="fas fa-copy" />
                        <span>Dupliquer</span>
                      </button>
                      <div className="grid">
                        <Field
                          name="od_sphere"
                          component={InputField}
                          type="text"
                          inputmode="decimal"
                          normalize={decimalReplacer}
                          placeholder=""
                          onFocus={() => resetSign_od_sphere()}
                          step="0.25"
                          label="Sphère"
                          className="col-12"
                          fieldClassName="field inline wrap"
                        />

                        <Field
                          name="od_cylindre"
                          component={InputField}
                          type="text"
                          inputmode="decimal"
                          normalize={decimalReplacer}
                          placeholder=""
                          onFocus={() => resetSign_od_cylindre()}
                          step="0.25"
                          label="Cylindre (+)"
                          className="col-12"
                          fieldClassName="field inline wrap"
                        />

                        {typeCorrection && typeCorrection === "P" && (
                          <Field
                            name="od_addition"
                            component={InputField}
                            type="text"
                            inputmode="decimal"
                            normalize={decimalReplacer}
                            placeholder=""
                            label=""
                            step="0.25"
                            label="Addition"
                            className="col-12"
                            fieldClassName="field inline wrap"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="box box-form">
                    <div className="box-header">
                      <div className="box-title">Oeil gauche</div>
                    </div>
                    <div className="box-content">
                      <div className="grid">
                        <Field
                          name="og_sphere"
                          component={InputField}
                          type="text"
                          inputmode="decimal"
                          normalize={decimalReplacer}
                          placeholder=""
                          onFocus={() => resetSign_og_sphere()}
                          step="0.25"
                          label="Sphère"
                          className="col-12"
                          fieldClassName="field inline wrap"
                        />
                        <Field
                          name="og_cylindre"
                          component={InputField}
                          type="text"
                          inputmode="decimal"
                          normalize={decimalReplacer}
                          placeholder=""
                          onFocus={() => resetSign_og_cylindre()}
                          step="0.25"
                          label="Cylindre (+)"
                          className="col-12"
                          fieldClassName="field inline wrap"
                        />

                        {typeCorrection && typeCorrection === "P" && (
                          <Field
                            name="od_addition"
                            component={InputField}
                            type="text"
                            inputmode="decimal"
                            normalize={decimalReplacer}
                            placeholder=""
                            disabled
                            step="0.25"
                            label="Addition"
                            className="col-12"
                            fieldClassName="field inline wrap"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-center">
                <div className="col-6">
                  <div className="box box-form box-mesures">
                    <div className="box-header">
                      <div className="box-title">Mesures individualisées</div>
                    </div>
                    <div className="box-content">
                      <div className="grid">
                        <Field
                          name="galbe"
                          component={InputField}
                          type="text"
                          inputmode="numeric"
                          placeholder=""
                          onFocus={() => resetSign_galbe()}
                          label="Galbe de la face"
                          sublabel="5 à 7"
                          step="1"
                          className="col-12"
                          fieldClassName="field inline wrap"
                        />
                        <Field
                          name="distance_vo"
                          component={InputField}
                          type="text"
                          inputmode="numeric"
                          placeholder=""
                          onFocus={() => resetSign_distance_vo()}
                          sublabel="11 à 13"
                          step="1"
                          label="Distance verre oeil"
                          className="col-12"
                          fieldClassName="field inline wrap"
                        />

                        <Field
                          name="angle"
                          component={InputField}
                          type="text"
                          inputmode="numeric"
                          placeholder=""
                          onFocus={() => resetSign_angle()}
                          sublabel="7 à 9"
                          step="1"
                          label="Angle pantoscopique"
                          className="col-12"
                          fieldClassName="field inline wrap"
                        />
                        <Field
                          name="diff_vlvp"
                          component={InputField}
                          type="text"
                          inputmode="decimal"
                          normalize={decimalReplacer}
                          placeholder=""
                          onFocus={() => resetSign_diff_vlvp()}
                          sublabel="2,4 à 2,6"
                          step="0.1"
                          label="Différence VL/VP"
                          className="col-12"
                          fieldClassName="field inline wrap"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="box box-form">
                <div className="box-header">
                  <div className="box-title">Monture</div>
                </div>
                <div className="box-content">
                  <div className="grid">
                    <Field
                      name="price"
                      component={InputField}
                      type="text"
                      inputmode="decimal"
                      normalize={decimalReplacer}
                      placeholder=""
                      step="0.01"
                      label="Prix de vente"
                      className="col-12"
                      fieldClassName="field inline wrap"
                    />
                    <Field
                      name="percee_nylor"
                      component={RadioField}
                      options={PERCEE_NYLOR}
                      placeholder=""
                      label=""
                      className="col-0"
                      classNameGroup="radio-toggle"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="question-subsidiaire">
          <div>
            <i className="fas  fa-exclamation-triangle" />
            Le client souhaite t-il l'antireflet lumière bleue ?
          </div>
          <Field name="blue" component={RadioField} className="radio-light" options={ANTIREFLET} />
        </div>
        <div className="workspace-footer">
          <div className="grid-center-noBottom">
            {/* Colonne de gauche */}
            <div className="col flexbox">
              <button className="btn btn-big btn-secondary" onClick={() => history.goBack()}>
                <i className="item-icon far fa-long-arrow-left" />
              </button>
            </div>
            {/* Colonne de droite */}
            <div className="col flexbox justifyEnd">
              <button className="btn btn-primary btn-big" type="submit">
                <span>Suivant</span>
                <i className="far fa-long-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </BaseLayout>
  );
};

const selector = formValueSelector("KalixiaForm");

export default withRouter(
  connect(
    (state) => ({
      typeCorrection: selector(state, "type_correction"),
      og_sphere: selector(state, "og_sphere"),
      og_cylindre: selector(state, "og_cylindre"),
      og_addition: selector(state, "og_addition"),
      od_sphere: selector(state, "od_sphere"),
      od_cylindre: selector(state, "od_cylindre"),
      od_addition: selector(state, "od_addition"),
      initialValues: {
        ...state.data.config.correction,
        type_correction: "U",
        percee_nylor: "classique",
      },
    }),
    { setConfig, change }
  )(
    reduxForm({
      form: "KalixiaForm",
      validate: (values = {}) => {
        const errors = {};

        if (values.og_sphere && (100 * parseFloat(values.og_sphere)) % 25 !== 0)
          errors.og_sphere = "le pas doit être de 0.25";
        if (values.og_cylindre && (100 * parseFloat(values.og_cylindre)) % 25 !== 0)
          errors.og_cylindre = "le pas doit être de 0.25";
        if (values.od_sphere && (100 * parseFloat(values.od_sphere)) % 25 !== 0)
          errors.od_sphere = "le pas doit être de 0.25";
        if (values.od_cylindre && (100 * parseFloat(values.od_cylindre)) % 25 !== 0)
          errors.od_cylindre = "le pas doit être de 0.25";
        if (values.od_addition && (100 * parseFloat(values.od_addition)) % 25 !== 0)
          errors.od_addition = "le pas doit être de 0.25";
        if (values.galbe && isNaN(values.galbe)) errors.galbe = "n'est pas un nombre";
        if (values.distance_vo && isNaN(values.distance_vo))
          errors.distance_vo = "n'est pas un nombre";
        if (values.angle && isNaN(values.angle)) errors.angle = "n'est pas un nombre";
        if (values.diff_vlvp && (100 * parseFloat(values.diff_vlvp)) % 10 !== 0)
          errors.diff_vlvp = "le pas doit être de 0.10";
        if (values.price && (100 * parseFloat(values.price)) % 1 !== 0)
          errors.price = "le pas doit être de 0.01";

        if (isUndefined(values.og_sphere)) errors.og_sphere = "Champ requis";
        if (isUndefined(values.og_cylindre)) errors.og_cylindre = "Champ requis";
        if (isUndefined(values.od_sphere)) errors.od_sphere = "Champ requis";
        if (isUndefined(values.od_cylindre)) errors.od_cylindre = "Champ requis";
        if (isUndefined(values.od_addition)) errors.od_addition = "Champ requis";
        if (isUndefined(values.blue))
          errors.blue = "Veuillez répondre à la question avant de passer à l'étape suivante";
        if (isUndefined(values.galbe)) errors.galbe = "Champ requis";
        if (values.galbe > 18 || values.galbe < 0) errors.galbe = "Veuillez ajuster la monture";
        if (isUndefined(values.distance_vo)) errors.distance_vo = "Champ requis";
        if (values.distance_vo > 25 || values.distance_vo < 8)
          errors.distance_vo = "Veuillez ajuster la monture";
        if (isUndefined(values.angle)) errors.angle = "Champ requis";
        if (values.angle > 20 || values.angle < 0) errors.angle = "Veuillez ajuster la monture";
        if (isUndefined(values.diff_vlvp)) errors.diff_vlvp = "Champ requis";
        if (values.diff_vlvp > 5 || values.diff_vlvp < 0)
          errors.diff_vlvp = "Veuillez ajuster la monture";
        return errors;
      },
    })(KalixiaForm)
  )
);
