import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get } from "lodash";

import { loadImages, createOrUpdateImage, deleteImage } from "../../store/modules/images";

import ImageEditForm from "../../components/admin/images/ImageEditForm";

import modalDefaultClass from "../../utils/ModalDefaultClass";
import ImagesTableListView from "../../components/admin/images/ImagesTableListView";

import AdminLayout from "../shared/AdminLayout";

const RenderEditDetailsModalsRouter = ({ imageId, url, editImage, push, images }) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ImageEditForm
            initialValues={{}}
            onSubmit={(v) => {
              editImage(v).then(() => push(url));
            }}
            baseUrl={url}
            imageId={imageId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:imageId/edit`}
      render={(subProps) =>
        get(images, subProps.match.params.imageId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <ImageEditForm
              initialValues={images[subProps.match.params.imageId]}
              onSubmit={(v) => {
                editImage(v).then(() => push(url));
              }}
              baseUrl={url}
              imageId={imageId}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editImage: PropTypes.func.isRequired,
  delImage: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  images: PropTypes.object.isRequired,
};

const ImagesAdminContainer = ({ images, imageId, match: { url }, history: { push }, ...props }) => (
  <AdminLayout>
    <h1>Images</h1>
    <ImagesTableListView
      images={images}
      baseUrl={url}
      onClickDelete={(id) => {
        props.deleteImage(id).then(() => push(url));
      }}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editImage={props.createOrUpdateImage}
      delImage={props.deleteImage}
      push={push}
      images={images}
    />
  </AdminLayout>
);

ImagesAdminContainer.propTypes = {
  createOrUpdateImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  images: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state) => ({
    images: state.data.entities.images,
  }),
  { createOrUpdateImage, deleteImage, loadImages }
)(withRouter(ImagesAdminContainer));
