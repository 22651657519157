import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get, sortBy, last } from "lodash";

import { loadGammes, createOrUpdateGamme, deleteGamme } from "../../store/modules/gammes";

import GammeEditForm from "../../components/admin/gammes/GammeEditForm";

import modalDefaultClass from "../../utils/ModalDefaultClass";
import GammesTableListView from "../../components/admin/gammes/GammesTableListView";

import AdminLayout from "../shared/AdminLayout";

const RenderEditDetailsModalsRouter = ({ gammeId, url, editGamme, push, gammes }) => {
  const lastNumeroOrder = gammes && last(sortBy(gammes, ["numero_order"])).numero_order;
  return (
    <Switch>
      <Route
        exact
        path={`${url}/new`}
        render={() => (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <GammeEditForm
              initialValues={{
                marque_id: 0,
                numero_order: (lastNumeroOrder && lastNumeroOrder + 1) || 1,
                masquee: false,
                ensavoirplus: false,
                k_sph_cyl_inf_3: false,
                k_aspherisation: false,
                image_argumentaire: "",
                image_infos_techniques: "",
                image_prise_mesures: "",
                libelle: "",
              }}
              onSubmit={(v) => {
                editGamme(v).then(() => push(url));
              }}
              baseUrl={url}
              gammeId={gammeId}
            />
          </Modal>
        )}
      />
      <Route
        exact
        path={`${url}/:gammeId/edit`}
        render={(subProps) =>
          get(gammes, subProps.match.params.gammeId, null) && (
            <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
              <GammeEditForm
                initialValues={gammes[subProps.match.params.gammeId]}
                onSubmit={(v) => {
                  editGamme(v).then(() => push(url));
                }}
                baseUrl={url}
                gammes={gammes}
              />
            </Modal>
          )
        }
      />
    </Switch>
  );
};

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editGamme: PropTypes.func.isRequired,
  delGamme: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  gammes: PropTypes.object.isRequired,
};

const GammesAdminContainer = ({ gammes, gammeId, match: { url }, history: { push }, ...props }) => (
  <AdminLayout>
    <h1>Gammes</h1>
    <GammesTableListView
      gammes={gammes}
      baseUrl={url}
      onClickDelete={(id) => {
        props.deleteGamme(id).then(() => push(url));
      }}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editGamme={props.createOrUpdateGamme}
      delGamme={props.deleteGamme}
      push={push}
      gammes={gammes}
    />
  </AdminLayout>
);

GammesAdminContainer.propTypes = {
  createOrUpdateGamme: PropTypes.func.isRequired,
  deleteGamme: PropTypes.func.isRequired,
  gammes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state) => ({
    gammes: state.data.entities.gammes,
  }),
  { createOrUpdateGamme, deleteGamme, loadGammes }
)(withRouter(GammesAdminContainer));
