import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { IconButton } from 'react-components-linaia';

import { setConfig } from '../../store/modules/globals';
import { sendSocket } from '../../store/modules/socket';

const PageHeaderAdmin = ({
  title,
  pageHeaderComponent,
  className,
  /* history: { push },*/ menuIsTiny,
  setConf,
}) => (
  <div className={classNames('page-header', className)}>
    <div className="container">
      <div className="page-header-left">
        <IconButton
          onClick={() => setConf('menuIsTiny', !menuIsTiny)}
          fontIcon="far fa-bars"
          className="menu-button btn"
        />
        <div style={{ color: 'black' }} className="page-header-title">
          {title}
        </div>
        {pageHeaderComponent}
      </div>
    </div>
  </div>
);

PageHeaderAdmin.propTypes = {
  pageHeaderComponent: PropTypes.element,
  className: PropTypes.string,
  menuIsTiny: PropTypes.bool,
  setConf: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    menuIsTiny: state.data.config.menuIsTiny,
  }),
  { setConf: setConfig, sendSocket },
)(withRouter(PageHeaderAdmin));
