import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get } from "lodash";

import { loadAppSettings, createOrUpdateAppSetting, deleteAppSetting } from "../../store/modules/appSettings";

import AppSettingEditForm from "../../components/admin/appSettings/AppSettingEditForm";

import modalDefaultClass from "../../utils/ModalDefaultClass";

import AdminLayout from "../shared/AdminLayout";

const RenderEditDetailsModalsRouter = ({ appsettingId, url, editAppSetting, push, appsettings }) => (
  <Switch>
    <Route
      exact
      path={`${url}/:appsettingId/edit`}
      render={subProps =>
        get(appsettings, subProps.match.params.appsettingId, null) && (
          <AppSettingEditForm
            initialValues={appsettings[subProps.match.params.appsettingId]}
            onSubmit={v => {
              editAppSetting(v).then(() => push(url));
            }}
            baseUrl={url}
            appsettingId={appsettingId}
          />
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editAppSetting: PropTypes.func.isRequired,
  delAppSetting: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  appsettings: PropTypes.object.isRequired
};

const AppSettingsAdminContainer = ({
  appsettings,
  appsettingId,
  match: { url },
  history: { push },
  ...props
}) => (
  <AdminLayout>
    <h1>Paramètres du site</h1>
    <RenderEditDetailsModalsRouter
      url={url}
      editAppSetting={props.createOrUpdateAppSetting}
      delAppSetting={props.deleteAppSetting}
      push={push}
      appsettings={appsettings}
    />
  </AdminLayout>
);

AppSettingsAdminContainer.propTypes = {
  createOrUpdateAppSetting: PropTypes.func.isRequired,
  deleteAppSetting: PropTypes.func.isRequired,
  appsettings: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  state => ({
    appsettings: state.data.entities.appSettings
  }),
  { createOrUpdateAppSetting, deleteAppSetting, loadAppSettings }
)(withRouter(AppSettingsAdminContainer));
