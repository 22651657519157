import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";
import { values, isUndefined } from "lodash";

import { loadGammes } from "../../../store/modules/gammes";
import { Field, FileField, InputField, ToggleField, SelectField } from "react-components-linaia";

import { uploadFiles, removeFile } from "../../../store/modules/uploads";

import { UPLOADS_URL } from "../../../constants/Config";

const GammeEditForm = ({ handleSubmit, baseUrl, marques, initialValues, upFiles, rmFile }) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.gamme_id >= 0 ? "Modifier la gamme" : "Ajouter une gamme"}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="numero_order"
            component={InputField}
            type="number"
            label="Numéro d'ordre *"
            className="col-12"
          />
          <Field
            name="marque_id"
            component={SelectField}
            options={marques}
            optionsKeys={{ value: "marque_id", label: "libelle" }}
            label="Marque *"
            className="col-12"
            classNamePrefix="react-select"
          />
          <Field name="libelle" component={InputField} label="Libellé *" className="col-12" />
          <Field
            name="masquee"
            component={ToggleField}
            label="Masquée"
            className="col-12"
            fieldClassName="checkbox"
          />
          <Field
            name="ensavoirplus"
            component={ToggleField}
            label="En savoir plus"
            className="col-12"
            fieldClassName="checkbox"
          />
          <Field
            name="k_aspherisation"
            component={ToggleField}
            label="Aspherisation"
            className="col-12"
            fieldClassName="checkbox"
          />
          <Field
            name="k_sph_cyl_inf_3"
            component={ToggleField}
            label="Sphère cylindrique inférieur"
            className="col-12"
            fieldClassName="checkbox"
          />
          <Field
            name="image_argumentaire"
            label="Image argumentaire"
            component={FileField}
            uploadFiles={upFiles}
            removeFile={rmFile}
            getUrl={(item) => `${UPLOADS_URL}/${item}`}
            buttonLabel="Choisir un Fichier"
            className="col-12"
          />
          <Field
            name="image_infos_techniques"
            label="Image infos techniques"
            component={FileField}
            uploadFiles={upFiles}
            removeFile={rmFile}
            getUrl={(item) => `${UPLOADS_URL}/${item}`}
            buttonLabel="Choisir un Fichier"
            className="col-12"
          />
          <Field
            name="image_prise_mesures"
            label="Image prise de mesures"
            component={FileField}
            uploadFiles={upFiles}
            removeFile={rmFile}
            getUrl={(item) => `${UPLOADS_URL}/${item}`}
            buttonLabel="Choisir un Fichier"
            className="col-12"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-tertiary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

GammeEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "GammeEditForm",
  validate: (values = {}) => {
    const errors = {};
    if (isUndefined(values.marque_id)) errors.marque_id = "Merci de renseigner une marque";
    if (!values.libelle) errors.libelle = "Merci de renseigner un libelle";
    if (isUndefined(values.numero_order))
      errors.numero_order = "Merci de renseigner un numéro d'ordre";
    return errors;
  },
})(
  connect(
    (state, { gammeId }) => ({
      gammes: state.data.entities.gammes,
      marques: state.data.entities.marques,
    }),
    { upFiles: uploadFiles, rmFile: removeFile, loadGammes }
  )(GammeEditForm)
);
