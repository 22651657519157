import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get } from "lodash";

import { loadOptions, createOrUpdateOption, deleteOption } from "../../store/modules/options";

import OptionEditForm from "../../components/admin/options/OptionEditForm";

import modalDefaultClass from "../../utils/ModalDefaultClass";
import OptionsTableListView from "../../components/admin/options/OptionsTableListView";

import AdminLayout from "../shared/AdminLayout";

const RenderEditDetailsModalsRouter = ({ optionId, url, editOption, push, options }) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <OptionEditForm
            initialValues={{}}
            onSubmit={v => {
              editOption(v).then(() => push(url));
            }}
            baseUrl={url}
            optionId={optionId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:optionId/edit`}
      render={subProps =>
        get(options, subProps.match.params.optionId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <OptionEditForm
              initialValues={options[subProps.match.params.optionId]}
              onSubmit={v => {
                editOption(v).then(() => push(url));
              }}
              baseUrl={url}
              optionId={optionId}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editOption: PropTypes.func.isRequired,
  delOption: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired
};

const OptionsAdminContainer = ({
  options,
  optionId,
  match: { url },
  history: { push },
  ...props
}) => (
  <AdminLayout>
    <h1>Options</h1>
    <OptionsTableListView
      options={options}
      baseUrl={url}
      onClickDelete={id => {
        props.deleteOption(id).then(() => push(url));
      }}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editOption={props.createOrUpdateOption}
      delOption={props.deleteOption}
      push={push}
      options={options}
    />
  </AdminLayout>
);

OptionsAdminContainer.propTypes = {
  createOrUpdateOption: PropTypes.func.isRequired,
  deleteOption: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  state => ({
    options: state.data.entities.options
  }),
  { createOrUpdateOption, deleteOption, loadOptions }
)(withRouter(OptionsAdminContainer));
