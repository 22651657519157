import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { map } from "lodash";

const AccountsListView = ({ accounts, baseUrl }) => (
  <div className="table-boxed full-height">
    <div className="toolbar">
      <div className="toolbar-title">Administrateurs</div>
      <div className="toolbar-actions">
        <NavLink className="btn" to={`${baseUrl}/new`}>
          Créer un utilisateur
        </NavLink>
      </div>
    </div>
    <div className="table">
      <div className="thead">
        <div className="tr">
          <div className="grid">
            <div className="col">
              <div className="th">Utilisateur</div>
            </div>
            <div className="col">
              <div className="th">Email</div>
            </div>
            <div className="col">
              <div className="th">Actions</div>
            </div>
          </div>
        </div>
      </div>
      <div className="tbody">
        {map(accounts, account => (
          <div className="tr" key={account.account_id}>
            <div className="grid">
              <div className="col">
                <div className="td">
                  <NavLink to={`${baseUrl}/${account.account_id}`}>{account.username}</NavLink>
                </div>
              </div>
              <div className="col">
                <div className="td">{account.email}</div>
              </div>
              <div className="col">
                <button>modifier</button>
                <button>delete</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

AccountsListView.propTypes = {
  accounts: PropTypes.objectOf(
    PropTypes.shape({
      account_id: PropTypes.number.isRequired,
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  ).isRequired,

  baseUrl: PropTypes.string.isRequired
};

export default AccountsListView;
