import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getJSONFromUrlParams } from "react-components-linaia";

import { loginFront, recoverPassword, RECOVER_PASSWORD_SUCCESS } from "../../store/modules/auth";
import { setMessage } from "../../store/modules/globals";

//import LoginForm from "../../components/login/LoginForm";
import PasswordRecoverForm from "../../components/login/PasswordRecoverForm";
import MessageBox from "../shared/MessageBox";
import ConnexionForm from "../../components/login/ConnexionForm";

const Login = ({ history, doLogin, doRecoverPassword, setMsg }) => {
  const [forgottenPassword, setForgottenPassword] = useState(false);

  const submitLogin = (values) =>
    doLogin(values.password).then(() => {
      const params = getJSONFromUrlParams();
      return history.push(params.next || "/home");
    });

  const submitRecoverPassword = (values) =>
    doRecoverPassword(values.email).then((action) => {
      if (action.type === RECOVER_PASSWORD_SUCCESS) {
        setForgottenPassword(false);
        setMsg(
          "Un lien de réinitialisation du mot de passe vous a été envoyé par email.",
          "info",
          5000
        );
      }
    });

  return (
    <main className="login" version={`env-${process.env.NODE_ENV}-v${process.env.VERSION}`}>
      <MessageBox />
      <div className="login-overflow">
        {!forgottenPassword ? (
          <ConnexionForm
            onSubmit={(v) => submitLogin(v)}
            onClickRecoverPassword={() => setForgottenPassword(true)}
          />
        ) : (
          <PasswordRecoverForm
            onSubmit={(v) => submitRecoverPassword(v)}
            onClickCancel={() => setForgottenPassword(false)}
          />
        )}
      </div>
    </main>
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,

  doLogin: PropTypes.func.isRequired,
  doRecoverPassword: PropTypes.func.isRequired,
  setMsg: PropTypes.func.isRequired,
};

export default connect(null, {
  doLogin: loginFront,
  doRecoverPassword: recoverPassword,
  setMsg: setMessage,
})(withRouter(Login));
