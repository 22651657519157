import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";
import { values, isUndefined } from "lodash";

import { loadServices } from "../../../store/modules/services";
import { Field, FileField, InputField, ToggleField, SelectField } from "react-components-linaia";

import { uploadFiles, removeFile } from "../../../store/modules/uploads";

import { UPLOADS_URL } from "../../../constants/Config";

const ServiceEditForm = ({ handleSubmit, baseUrl, initialValues, reseaux, upFiles, rmFile }) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.service_id >= 0 ? "Modifier le service" : "Ajouter un service"}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="reseau_id"
            component={SelectField}
            options={reseaux}
            optionsKeys={{ value: "reseau_id", label: "nom" }}
            label="Reseau"
            className="col-12"
            classNamePrefix="react-select"
          />

          <Field name="libelle" component={InputField} label="Libelle" className="col-12" />

          <Field name="service" component={InputField} label="Service" className="col-12" />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-tertiary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

ServiceEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired
};

export default reduxForm({
  form: "ServiceEditForm",
  validate: (values = {}) => {
    const errors = {};
    if (isUndefined(values.reseau_id)) errors.reseau_id = "Merci de renseigner un réseau";
    if (!values.libelle) errors.libelle = "Merci de renseigner un libelle";
    if (!values.service) errors.service = "Merci de renseigner un service";
    return errors;
  }
})(
  connect(
    (state, { serviceId }) => ({
      services: state.data.entities.services,
      reseaux: state.data.entities.reseaux
    }),
    { upFiles: uploadFiles, rmFile: removeFile, loadServices }
  )(ServiceEditForm)
);
