import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";
import { values, isUndefined } from "lodash";

import { Field, InputField } from "react-components-linaia";

const AdministrateurEditForm = ({ handleSubmit, baseUrl, accounts, initialValues }) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.account_id >= 0 ? "Modifier l'administrateur" : "Ajouter un administrateur"}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field name="username" component={InputField} label="Pseudonyme *" className="col-12" />
          <Field name="email" component={InputField} label="Email *" className="col-12" />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-tertiary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

AdministrateurEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired
};

export default reduxForm({
  form: "AdministrateurEditForm",
  validate: (values = {}) => {
    const errors = {};

    if (!values.username) errors.username = "Merci de renseigner un pseudonyme";
    if (!values.email) errors.email = "Merci de renseigner un email";
    return errors;
  }
})(
  connect(
    (state, { accountId }) => ({
      accounts: state.data.entities.accounts
    }),
    {}
  )(AdministrateurEditForm)
);
