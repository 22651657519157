import { flow, set } from "lodash/fp";
import { CALL_API } from "../Schemas";

const UPLOADS_REQUEST = "kwa/uploads/UPLOADS_REQUEST";
const UPLOADS_SUCCESS = "kwa/uploads/UPLOADS_SUCCESS";
const UPLOADS_FAILURE = "kwa/uploads/UPLOADS_FAILURE";
const DELETE_REQUEST = "kwa/uploads/DELETE_REQUEST";
const DELETE_SUCCESS = "kwa/uploads/DELETE_SUCCESS";
const DELETE_FAILURE = "kwa/uploads/DELETE_FAILURE";

export const uploadsActionsHandlers = {
  [DELETE_REQUEST]: (state) =>
    flow(
      set("config.isCheckCSV", false),
      set("config.insertTarifsSuccess", false),
      set("config.errorsCheckCSV", []),
      set("config.tarifsData", [])
    )(state),
};

export function uploadFiles(files) {
  return {
    [CALL_API]: {
      types: [UPLOADS_REQUEST, UPLOADS_SUCCESS, UPLOADS_FAILURE],
      method: "POST",
      endpoint: "/uploads",
      body: {
        files: Array.isArray(files) ? files : [files],
      },
      upload: true,
    },
  };
}

export function removeFile(filesToRemove) {
  return {
    [CALL_API]: {
      types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
      method: "DELETE",
      endpoint: "/uploads",
      body: {
        filesToRemove,
      },
      upload: true,
    },
  };
}
