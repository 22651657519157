import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { IconButton } from "react-components-linaia";
import { IMAGE_API_URL } from "../../constants/Config";
import { isEmpty } from "lodash";

import { setConfig } from "../../store/modules/globals";
import { logout } from "../../store/modules/auth";

import { isAdmin } from "../../utils/RolesUtils";
import {
  ReseauIsCompleted,
  ReseauIsCompletedClasseB,
  CorrectionIsCompleted,
  ServiceIsCompleted,
} from "../../utils/PropsUtils";

import logo from "../../../assets/images/logo-kharo.svg";
import mobileLogo from "../../../assets/images/logo-kharo.svg";

const Header = ({
  menuIsOpen,
  menuIsTiny,
  data,
  account,
  setConf,
  doLogout,
  reseau,
  marque,
  classe,
  updateDate,
}) => {
  return (
    <header className="header">
      <div className={classNames("header-menu", { tiny: menuIsTiny })}>
        <div className="container">
          <nav
            className={classNames(
              "main-nav",
              { "animated opened": menuIsOpen === true },
              { "animated closed": menuIsOpen === false }
            )}
          >
            <div href="/home" className="mobile-logo-block">
              <img src={mobileLogo} alt="Logo" className="mobile-logo" />
            </div>
            {isAdmin(account) && (
              <Fragment>
                <div className="menu-title">Administrateurs :</div>
                <div className="user-block">
                  <div className="user-profil">
                    <div className="user-avatar-block">
                      <div className="user-avatar">
                        <img
                          src="//www.gravatar.com/avatar/60d44261bab4309c41da7218addde9db?d=404"
                          alt="NL"
                        />
                      </div>
                    </div>
                    <div className="user-name">{account && account.username}</div>
                  </div>
                  <div className="user-actions">
                    <NavLink
                      to="/user/account"
                      onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                      to="/user/account"
                      onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                      exact
                      activeClassName="active"
                      className="btn"
                    >
                      <i className="item-icon fal fa-cog" />
                    </NavLink>
                  </div>
                </div>
                <IconButton title="Déconnexion" fontIcon="fal fa-sign-out" onClick={doLogout} />
              </Fragment>
            )}

            {/* HEADER COTE NON ADMIN*/}

            <ul>
              {marque.libelle && (
                <li>
                  <NavLink
                    to="/marque"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                    className="marque"
                  >
                    <img
                      src={IMAGE_API_URL + "/uploads/" + marque.libelle.toLowerCase() + ".png"}
                    />
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  to="/home"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                >
                  <i className="item-icon fal fa-code-branch" />
                  <span>Réseaux</span>
                </NavLink>
              </li>
              {reseau && reseau.code === "KALIXIA" ? (
                <li>
                  <NavLink
                    to="/correction/kalixia"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                    className={!ReseauIsCompletedClasseB(data) && "disabled"}
                  >
                    <i className="item-icon fal fa-eye" />
                    <span>Correction</span>
                  </NavLink>
                </li>
              ) : (
                <li>
                  <NavLink
                    to="/correction"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                    className={!ReseauIsCompletedClasseB(data) && "disabled"}
                  >
                    <i className="item-icon fal fa-eye" />
                    <span>Correction</span>
                  </NavLink>
                </li>
              )}

              <li>
                <NavLink
                  to="/modele-verre"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                  disabled
                  isActive={false}
                  className={!CorrectionIsCompleted(data) && "disabled"}
                >
                  <i className="item-icon fal fa-glasses" />
                  <span>Verre</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/options"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                  className={!ReseauIsCompleted(data) && "disabled"}
                  //className={isEmpty(reseau.nom) && "disabled"}
                >
                  <i className="item-icon fal fa-sliders-h" />
                  <span>Options</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/garanties"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                  className={!ReseauIsCompleted(data) && "disabled"}
                  //className={isEmpty(reseau.nom) && "disabled"}
                >
                  <i className="item-icon fal fa-shield-check" />
                  <span>Garanties</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/services"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                  className={!ServiceIsCompleted(data) && "disabled"}
                  //className={(isEmpty(reseau.nom) && "disabled") || (reseau && reseau.nom == "Hors Réseaux" && "disabled")}
                >
                  <i className="item-icon fal fa-percentage" />
                  <span>Services</span>
                </NavLink>
              </li>
              {reseau && reseau.reseau_id && classe && classe === "classeB" && (
                <li>
                  <NavLink className="paire" to={`/seconde-paire/` + reseau.reseau_id}>
                    <i className="item-icon fal fa-glasses">
                      <i className="fad fa-sparkles"></i>
                    </i>
                    <span>
                      2<sup>ème</sup> Paire
                    </span>
                  </NavLink>
                </li>
              )}

              {reseau.nom && (
                <div class="config-wrapper">
                  <li>
                    <NavLink
                      to="/home"
                      onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                      exact
                      activeClassName="active"
                      className="reseau"
                    >
                      {reseau.image === "" ? (
                        <span>{reseau.nom}</span>
                      ) : (
                        <img src={IMAGE_API_URL + "/uploads/" + reseau.image} />
                      )}
                    </NavLink>
                  </li>
                  <li>
                    {updateDate}
                    <br />
                    V1.2.6
                  </li>
                </div>
              )}
            </ul>
          </nav>
          <div
            className={classNames(
              "menu-mask",
              { "animated opened": menuIsOpen === true },
              { "animated closed": menuIsOpen === false }
            )}
            onClick={() => setConf("menuIsOpen", false)}
          />
          <IconButton
            onClick={() => setConf("menuIsOpen", true)}
            fontIcon="far fa-bars"
            title="Menu"
            className={classNames("menu-button", { "menu-opened": menuIsOpen })}
            onClick={() => setConf("menuIsOpen", true)}
            fontIcon="far fa-bars"
            title="Menu"
            className={classNames("menu-button", { "menu-opened": menuIsOpen })}
          />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  menuIsOpen: PropTypes.bool,
  menuIsTiny: PropTypes.bool,
  account: PropTypes.shape({
    username: PropTypes.string,
  }),

  setConf: PropTypes.func.isRequired,
  doLogout: PropTypes.func,
};

export default connect(
  (state) => ({
    menuIsOpen: state.data.config.menuIsOpen,
    menuIsTiny: state.data.config.menuIsTiny,
    account: state.auth.user,
    reseau: state.data.config.reseau,
    classe: state.data.config.classe,
    marque: state.data.config.marque,
    updateDate:
      state.data.entities.appSettings &&
      state.data.entities.appSettings[1] &&
      state.data.entities.appSettings[1].update_date,

    data: state.data.config,
  }),
  { setConf: setConfig, doLogout: logout }
)(Header);
