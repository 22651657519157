import { flow, set } from "lodash/fp";
import { omit, isUndefined } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_IMAGES_REQUEST = "ksa/images/FETCH_ALL_IMAGES_REQUEST";
export const FETCH_ALL_IMAGES_SUCCESS = "kwa/images/FETCH_ALL_IMAGES_SUCCESS";

const FETCH_ALL_IMAGES_FAILURE = "ksa/images/FETCH_ALL_IMAGES_FAILURE";

const FETCH_IMAGE_REQUEST = "ksa/images/FETCH_IMAGE_REQUEST";
const FETCH_IMAGE_SUCCESS = "ksa/images/FETCH_IMAGE_SUCCESS";
const FETCH_IMAGE_FAILURE = "ksa/images/FETCH_IMAGE_FAILURE";

const SET_IMAGE_REQUEST = "ksa/images/SET_IMAGE_REQUEST";
const SET_IMAGE_SUCCESS = "ksa/images/SET_IMAGE_SUCCESS";
const SET_IMAGE_FAILURE = "ksa/images/SET_IMAGE_FAILURE";

const DELETE_IMAGE_REQUEST = "ksa/images/DELETE_IMAGE_REQUEST";
const DELETE_IMAGE_SUCCESS = "ksa/images/DELETE_IMAGE_SUCCESS";
const DELETE_IMAGE_FAILURE = "ksa/images/DELETE_IMAGE_FAILURE";

export const imagesActionsHandlers = {
  [FETCH_ALL_IMAGES_REQUEST]: (state) =>
    flow(set("loaded.images", false), set("loading.images", true))(state),
  [FETCH_IMAGE_REQUEST]: (state) => {
    return flow(set("loaded.images", false), set("loading.images", true))(state);
  },
  [FETCH_ALL_IMAGES_SUCCESS]: (state, action) => {
    return flow(
      set("entities.images", action.response.entities.images || {}),
      set("loaded.images", true),
      set("loading.images", false)
    )(state);
  },

  [FETCH_ALL_IMAGES_FAILURE]: (state) =>
    flow(set("loaded.images", false), set("loading.images", false))(state),
  [FETCH_IMAGE_FAILURE]: (state) =>
    flow(set("loaded.images", false), set("loading.images", false))(state),

  [FETCH_IMAGE_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.images.${action.id}`, action.response.entities.images[action.id]),
      set("loaded.images", true),
      set("loading.images", false)
    )(state);
  },

  [SET_IMAGE_REQUEST]: (state) =>
    flow(set("loaded.images", false), set("loading.images", true))(state),
  [SET_IMAGE_SUCCESS]: (state, action) =>
    flow(
      set("entities.images", {
        ...state.entities.images,
        ...action.response.entities.images,
      }),
      set("loaded.images", true),
      set("loading.images", false)
    )(state),
  [SET_IMAGE_FAILURE]: (state, action) =>
    flow(set("loaded.images", false), set("loading.images", false))(state),

  [DELETE_IMAGE_SUCCESS]: (state, action) =>
    flow(set("entities.images", omit(state.entities.images, action.id)))(state),
};

export function loadImage(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_IMAGE_REQUEST, FETCH_IMAGE_SUCCESS, FETCH_IMAGE_FAILURE],
      method: "GET",
      endpoint: `/images/${id}`,
      schema: Schemas.IMAGE,
      successMessage: "L'image a bien été chargée",
    },
  };
}

export function loadImages() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_IMAGES_REQUEST, FETCH_ALL_IMAGES_SUCCESS, FETCH_ALL_IMAGES_FAILURE],
      method: "GET",
      endpoint: "/images",
      schema: Schemas.IMAGE_ARRAY,
    },
  };
}

export function createOrUpdateImage(image) {
  console.log("createOrUpdateImage()", image.image_id);

  return {
    [CALL_API]: {
      types: [SET_IMAGE_REQUEST, SET_IMAGE_SUCCESS, SET_IMAGE_FAILURE],
      method: !image || !Number.isInteger(image.image_id) ? "POST" : "PUT",
      endpoint:
        !image || !Number.isInteger(image.image_id) ? "/images" : `/images/${image.image_id}`,
      schema: Schemas.IMAGE,
      body: image,
      successMessage: "Image enregistré avec succès",
    },
  };
}

export function deleteImage(id) {
  console.log("deleteImage() id===", id);
  return {
    id,
    [CALL_API]: {
      types: [DELETE_IMAGE_REQUEST, DELETE_IMAGE_SUCCESS, DELETE_IMAGE_FAILURE],
      method: "DELETE",
      endpoint: `/images/${id}`,
      successMessage: "Image supprimé avec succès",
    },
  };
}
