import { flow, set } from "lodash/fp";

import { CALL_API } from "../Schemas";

const LOGIN_REQUEST = "kwa/auth/LOGIN_REQUEST";
const LOGINFRONT_REQUEST = "kwa/auth/LOGINFRONT_REQUEST";

const LOGIN_SUCCESS = "kwa/auth/LOGIN_SUCCESS";
const LOGINFRONT_SUCCESS = "kwa/auth/LOGINFRONT_SUCCESS";
const LOGINFRONT_FAIL = "kwa/auth/LOGIN_FAIL";

const LOGIN_FAIL = "kwa/auth/LOGIN_FAIL";
const LOGOUT_REQUEST = "kwa/auth/LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "kwa/auth/LOGOUT_SUCCESS";
const LOGOUT_FAIL = "kwa/auth/LOGOUT_FAIL";
const RECOVER_PASSWORD_REQUEST = "kwa/auth/RECOVER_PASSWORD_REQUEST";
export const RECOVER_PASSWORD_SUCCESS = "kwa/auth/RECOVER_PASSWORD_SUCCESS";
const RECOVER_PASSWORD_FAILURE = "kwa/auth/RECOVER_PASSWORD_FAILURE";
const RESET_PASSWORD_REQUEST = "kwa/auth/RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "kwa/auth/RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAILURE = "kwa/auth/RESET_PASSWORD_FAILURE";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return flow(set("loggingIn", false), set("user", action.response))(state);
    case LOGINFRONT_SUCCESS:
      return flow(set("loggingIn", false), set("userFront", action.response))(state);
    case LOGIN_FAIL:
      return flow(set("loggingIn", false), set("user", null))(state);
    case LOGINFRONT_FAIL:
      return flow(set("loggingIn", false), set("userFront", null))(state);
    case LOGOUT_REQUEST:
      return flow(set("loggingOut", true))(state);
    case LOGOUT_SUCCESS:
      return flow(set("loggingOut", false), set("user", null))(state);
    case LOGOUT_FAIL:
      return flow(set("loggingOut", false))(state);
    default:
      return state;
  }
}

export function login(email, password) {
  return {
    [CALL_API]: {
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL],
      method: "POST",
      endpoint: "/auth/login",
      body: {
        email,
        password
      }
    }
  };
}

export function loginFront(password) {
  return {
    [CALL_API]: {
      types: [LOGINFRONT_REQUEST, LOGINFRONT_SUCCESS, LOGINFRONT_FAIL],
      method: "POST",
      endpoint: "/auth/loginFront",
      body: {
        password
      }
    }
  };
}

export function logout() {
  return {
    type: LOGOUT_SUCCESS
  };
}

export function recoverPassword(email) {
  return {
    [CALL_API]: {
      types: [RECOVER_PASSWORD_REQUEST, RECOVER_PASSWORD_SUCCESS, RECOVER_PASSWORD_FAILURE],
      method: "PUT",
      endpoint: "/auth/password",
      body: {
        email
      }
    }
  };
}

export function resetPassword(accountId, resetToken, password) {
  return {
    [CALL_API]: {
      types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
      method: "PUT",
      endpoint: "/auth/reset-password",
      body: {
        accountId,
        resetToken,
        password
      }
    }
  };
}
