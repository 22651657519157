import { flow, set } from "lodash/fp";
import { omit, isUndefined } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_RESEAUX_REQUEST = "ksa/reseaux/FETCH_ALL_RESEAUX_REQUEST";
export const FETCH_ALL_RESEAUX_SUCCESS = "kwa/reseaux/FETCH_ALL_RESEAUX_SUCCESS";

const FETCH_ALL_RESEAUX_FAILURE = "ksa/reseaux/FETCH_ALL_RESEAUX_FAILURE";

const FETCH_RESEAU_REQUEST = "ksa/reseaux/FETCH_RESEAU_REQUEST";
const FETCH_RESEAU_SUCCESS = "ksa/reseaux/FETCH_RESEAU_SUCCESS";
const FETCH_RESEAU_FAILURE = "ksa/reseaux/FETCH_RESEAU_FAILURE";

const SET_RESEAU_REQUEST = "ksa/reseaux/SET_RESEAU_REQUEST";
const SET_RESEAU_SUCCESS = "ksa/reseaux/SET_RESEAU_SUCCESS";
const SET_RESEAU_FAILURE = "ksa/reseaux/SET_RESEAU_FAILURE";

const DELETE_RESEAU_REQUEST = "ksa/reseaux/DELETE_RESEAU_REQUEST";
const DELETE_RESEAU_SUCCESS = "ksa/reseaux/DELETE_RESEAU_SUCCESS";
const DELETE_RESEAU_FAILURE = "ksa/reseaux/DELETE_RESEAU_FAILURE";

export const reseauxActionsHandlers = {
  [FETCH_ALL_RESEAUX_REQUEST]: (state) =>
    flow(set("loaded.reseaux", false), set("loading.reseaux", true))(state),
  [FETCH_RESEAU_REQUEST]: (state) => {
    return flow(set("loaded.reseaux", false), set("loading.reseaux", true))(state);
  },
  [FETCH_ALL_RESEAUX_SUCCESS]: (state, action) => {
    return flow(
      set("entities.reseaux", action.response.entities.reseaux || {}),
      set("loaded.reseaux", true),
      set("loading.reseaux", false)
    )(state);
  },

  [FETCH_ALL_RESEAUX_FAILURE]: (state) =>
    flow(set("loaded.reseaux", false), set("loading.reseaux", false))(state),
  [FETCH_RESEAU_FAILURE]: (state) =>
    flow(set("loaded.reseaux", false), set("loading.reseaux", false))(state),

  [FETCH_RESEAU_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.reseaux.${action.id}`, action.response.entities.reseaux[action.id]),
      set("loaded.reseaux", true),
      set("loading.reseaux", false)
    )(state);
  },

  [SET_RESEAU_REQUEST]: (state) =>
    flow(set("loaded.reseaux", false), set("loading.reseaux", true))(state),
  [SET_RESEAU_SUCCESS]: (state, action) =>
    flow(
      set("entities.reseaux", {
        ...state.entities.reseaux,
        ...action.response.entities.reseaux,
      }),
      set("loaded.reseaux", true),
      set("loading.reseaux", false)
    )(state),
  [SET_RESEAU_FAILURE]: (state, action) =>
    flow(set("loaded.reseaux", false), set("loading.reseaux", false))(state),

  [DELETE_RESEAU_SUCCESS]: (state, action) =>
    flow(set("entities.reseaux", omit(state.entities.reseaux, action.id)))(state),
};

export function loadReseau(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_RESEAU_REQUEST, FETCH_RESEAU_SUCCESS, FETCH_RESEAU_FAILURE],
      method: "GET",
      endpoint: `/reseaux/${id}`,
      schema: Schemas.RESEAU,
      successMessage: "Le réseau a bien été chargé",
    },
  };
}

export function loadReseaux() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_RESEAUX_REQUEST, FETCH_ALL_RESEAUX_SUCCESS, FETCH_ALL_RESEAUX_FAILURE],
      method: "GET",
      endpoint: "/reseaux",
      schema: Schemas.RESEAU_ARRAY,
    },
  };
}

export function createOrUpdateReseau(reseau) {
  console.log("createOrUpdateReseau()", reseau.reseau_id);

  return {
    [CALL_API]: {
      types: [SET_RESEAU_REQUEST, SET_RESEAU_SUCCESS, SET_RESEAU_FAILURE],
      method: !reseau || !Number.isInteger(reseau.reseau_id) ? "POST" : "PUT",
      endpoint:
        !reseau || !Number.isInteger(reseau.reseau_id)
          ? "/reseaux"
          : `/reseaux/${reseau.reseau_id}`,
      schema: Schemas.RESEAU,
      body: reseau,
      successMessage: "Le réseau a bien été enregistré",
    },
  };
}

export function deleteReseau(id) {
  console.log("deleteReseau() id===", id);
  return {
    id,
    [CALL_API]: {
      types: [DELETE_RESEAU_REQUEST, DELETE_RESEAU_SUCCESS, DELETE_RESEAU_FAILURE],
      method: "DELETE",
      endpoint: `/reseaux/${id}`,
      successMessage: "Le réseau a été supprimé avec succès",
    },
  };
}
