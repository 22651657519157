import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import Modal from "react-modal";

import MessageBox from "./MessageBox";

import Header from "../../components/shared/Header";

import { isAdmin } from "../../utils/RolesUtils";
import HeaderAdmin from "../../components/shared/HeaderAdmin";
import PageHeaderAdmin from "../../components/shared/PageHeaderAdmin";

Modal.setAppElement("body");

const AdminLayout = ({ title, hideHeader, className, pageHeaderClassName, children, account }) => (
  <div
    id="wrapper"
    className={`env-${process.env.NODE_ENV}-v${process.env.VERSION} ${
      isAdmin(account) ? "admin" : "client"
    }`}
  >
    <MessageBox />
    <HeaderAdmin />
    <main>
      <div className={classnames("workspace", className)}>
        <div className="container">{children}</div>
      </div>
      {/*
      {process.env.NODE_ENV === "staging" && (
        <div className="env-banner">!!! Environnement de recette !!!</div>
      )}
      */}
    </main>
  </div>
);

AdminLayout.propTypes = {
  children: PropTypes.node,
  hideHeader: PropTypes.bool,

  title: PropTypes.string,
  pageHeaderClassName: PropTypes.string,
  className: PropTypes.string,

  account: PropTypes.shape(),
};

export default connect((state) => ({
  account: state.auth.user,
}))(AdminLayout);
