import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import * as storage from "redux-storage";
import createIndexedDBEngine from "redux-storage-engine-indexed-db";
import storageFilter from "redux-storage-decorator-filter";

import api from "./middleware/api";
import simpleActionMiddleware from "./middleware/simpleActionMiddleware";
import rootReducer from "./modules/reducer";
import { API_URL } from "../constants/Config";

const logger = createLogger();

const storageEngine = storageFilter(createIndexedDBEngine("krys-webapp"), [
  "auth",
  ["data", "entities", "models"],
  ["data", "entities", "accounts"],
  ["data", "entities", "tarifs"],
  ["data", "entities", "options"],
  ["data", "entities", "reglesGestions"],
  ["data", "entities", "remisesMontures"],
  ["data", "entities", "gammes"],
  ["data", "entities", "images"],
  ["data", "entities", "garanties"],
  ["data", "entities", "services"],
  ["data", "entities", "optionVerres"],
  ["data", "entities", "reseaux"],
  ["data", "entities", "appSettings"],
  ["data", "entities", "marques"],
]);

const finalCreateStore = composeWithDevTools(
  compose(
    applyMiddleware(thunk, simpleActionMiddleware),
    applyMiddleware(thunk, api),
    applyMiddleware(logger),
    applyMiddleware(storage.createMiddleware(storageEngine))
  )
)(createStore);

const finalReducer = storage.reducer(rootReducer);
const load = storage.createLoader(storageEngine);

export default function configureStore(initialState) {
  const store = finalCreateStore(finalReducer, initialState);
  load(store);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./modules/reducer", () => {
      const nextRootReducer = require("./modules/reducer"); // eslint-disable-line
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
