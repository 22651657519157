export const SOCKET_BASE_TYPE = "kwa/socket/";

const NOTIFICATION_RECEIVED = "kwa/socket/NOTIFICATION_RECEIVED";
const SEND_MESSAGE = "kwa/socket/SEND_MESSAGE";

export const socketActionsHandlers = {
  [NOTIFICATION_RECEIVED]: (state, action) => ({
    ...state,
    message: {
      text: action.text,
      messageType: "info",
      delay: 5000
    }
  })
};

export function sendSocket(message) {
  return {
    type: SEND_MESSAGE,
    message,
    other: "other option"
  };
}
