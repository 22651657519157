/*****************************************************************************
 * Properties
 *****************************************************************************/

/*
 * ACCOUNTS_ROLES
 */
export const ACCOUNTS_ROLES = {
  ADMIN: "ADMIN",
  USER: "USER",
  REPORTER: "REPORTER",
};

/*
 * POSTS_STATUS
 */
export const POSTS_STATUS = {
  created: "Créé",
  archived: "Archivé",
};

/*
 * OPT_COURTESY_TITLE
 */
export const OPT_COURTESY_TITLE = {
  mr: { short: "M.", long: "Monsieur" },
  mme: { short: "Mme", long: "Madame" },
};

export const CLASSES = [
  { value: "classeA", label: "Classe A", isHtml: true },
  { value: "classeB", label: "Classe B" },
];
export const CLASSESMIN = [
  { value: "classeA", label: "Classe A", isHtml: true },
  { value: "classeB", label: "Classe B" },
  { value: "mineraux", label: "Minéraux" },
];

export const CORRECTION = {
  U: "Unifocaux",
  P: "Progressifs",
  D: "Dégressifs",
};

export const ANTIREFLET = {
  O: "Oui",
  N: "Non",
};

export const TYPE_VERRES = {
  blue: "Blue",
  ndriveBoost: "NDrive boost",
  blancs: "AR",
  sansAntiReflet: "Durci",
  photochromique: "Photochromique",
  polarises: "Polarisés",
};

export const VERRES_COULEURS = {
  BR: "Brun",
  GR: "Gris",
  GG: "Gris US",
};
export const TYPE_IMAGES = {
  image_argumentaire: "Argumentaire",
  image_infos_techniques: "Infos techniques",
  image_prise_mesures: "Prises de mesures",
};
export const TYPE_IMAGES_2EME_PAIRE = {
  unifocaux: "UNIF",
  progressifs: "PROG",
  degressifs: "DEGRESSIF _ C&G",
};

export const TYPE_IMAGES_2EME_PAIRE_SHORT = {
  unifocaux: "2U",
  progressifs: "2P",
  degressifs: "2D",
};

export const PERCEE_NYLOR = {
  classique: "Classique",
  percee_nylor: "Percée/Nylor",
};
