import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Login from "./login/Login";
import Home from "./home/Home";
import ResetPassword from "./login/ResetPassword";

import AuthenticatedComponent from "./shared/AuthenticatedComponent";
import AuthenticatedFrontComponent from "./shared/AuthenticatedFrontComponent";
import PageNotFound from "./shared/PageNotFound";

import AccountsListContainer from "./accounts/AccountsListContainer";
import OptionsContainer from "./options/OptionsContainer";
import ModelsContainer from "./models/ModelsContainer";
import UploadContentsContainer from "./uploadContents/UploadContentsContainer";

import CorrectionForm from "../components/correction/CorrectionForm";
import OptionForm from "../components/options/OptionForm";
import GarantieForm from "../components/garantie/GarantieForm";
import ServiceForm from "../components/service/ServiceForm";
import KalixiaForm from "../components/correction/KalixiaForm";

import MyAccountContainer from "./accounts/MyAccountContainer";

import LoginAdmin from "./admin/LoginAdmin";
import ReseauxAdminContainer from "./admin/ReseauxAdminContainer";
import OptionsAdminContainer from "./admin/OptionsAdminContainer";
import ServicesAdminContainer from "./admin/ServicesAdminContainer";
import ModeleVerreForm from "../components/modeleVerres/ModeleVerreForm";
import ClasseAView from "../components/classeA/ClasseAView";
import MarqueForm from "../components/marque/MarqueForm";

import GarantiesAdminContainer from "./admin/GarantiesAdminContainer";
import GammesAdminContainer from "./admin/GammesAdminContainer";
import ImagesAdminContainer from "./admin/ImagesAdminContainer";
import RemisesMonturesAdminContainer from "./admin/RemisesMonturesAdminContainer";
import ImageView from "../components/shared/ImageView";

import ReglesGestionAdminContainer from "./admin/ReglesGestionAdminContainer";
import AdministrateursAdminContainer from "./admin/AdministrateursAdminContainer";
import TarifsAdminContainer from "./admin/TarifsAdminContainer";
import AppSettingsContainer from "./admin/AppSettingsAdminContainer";

function requireAuthentication(Component) {
  return () => (
    <AuthenticatedComponent>
      <Component />
    </AuthenticatedComponent>
  );
}

function requireAuthenticationFront(Component) {
  return () => (
    <AuthenticatedFrontComponent>
      <Component />
    </AuthenticatedFrontComponent>
  );
}

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/reset-password" component={ResetPassword} />

      <Route exact path="/home" render={requireAuthenticationFront(Home)} />
      <Route exact path="/correction" render={requireAuthenticationFront(CorrectionForm)} />
      <Route exact path="/correction/kalixia" render={requireAuthenticationFront(KalixiaForm)} />
      <Route exact path="/modele-verre" render={requireAuthenticationFront(ModeleVerreForm)} />

      <Route exact path="/options" render={requireAuthenticationFront(OptionForm)} />
      <Route exact path="/garanties" render={requireAuthenticationFront(GarantieForm)} />
      <Route exact path="/services" render={requireAuthenticationFront(ServiceForm)} />
      <Route exact path="/verre/classeA" render={requireAuthenticationFront(ClasseAView)} />
      <Route exact path="/marque" render={requireAuthenticationFront(MarqueForm)} />

      <Route
        exact
        path="/modele-verre/:gammeId"
        render={requireAuthenticationFront((subProps) => {
          return (
            <ImageView
              title="ModeleVerre"
              type="modele-verre"
              image_code="M"
              gammeId={subProps.match.params.gammeId}
              className=""
            />
          );
        })}
      />

      <Route
        exact
        path="/mineraux/:reseauId"
        render={requireAuthenticationFront((subProps) => {
          return (
            <ImageView
              title="Minéraux"
              type="minereaux"
              image_code="M"
              reseauId={subProps.match.params.reseauId}
              className=""
            />
          );
        })}
      />

      <Route
        exact
        path="/seconde-paire/:reseauId"
        render={requireAuthenticationFront((subProps) => {
          return (
            <ImageView
              title="2ème paire"
              type="seconde-paire"
              image_code="2"
              reseauId={subProps.match.params.reseauId}
              className=""
            />
          );
        })}
      />

      <Route
        exact
        path="/verres-nikon/:reseauId"
        render={requireAuthenticationFront((subProps) => {
          return (
            <ImageView
              title="Verres Panier A"
              image_code="N"
              reseauId={subProps.match.params.reseauId}
              className=""
            />
          );
        })}
      />

      <Route
        exact
        path="/classeA/seconde-paire/:reseauId"
        render={requireAuthenticationFront((subProps) => {
          return (
            <ImageView
              title="Classe A - 2ème paire"
              image_code="C"
              type="2eme-paire"
              reseauId={subProps.match.params.reseauId}
              className=""
            />
          );
        })}
      />
      <Route exact path="/login" component={LoginAdmin} />

      <Route path="/admin/reseaux" render={requireAuthentication(ReseauxAdminContainer)} />
      <Route path="/admin/options" render={requireAuthentication(OptionsAdminContainer)} />
      <Route path="/admin/services" render={requireAuthentication(ServicesAdminContainer)} />
      <Route path="/admin/garanties" render={requireAuthentication(GarantiesAdminContainer)} />
      <Route path="/admin/gammes" render={requireAuthentication(GammesAdminContainer)} />
      <Route path="/admin/images" render={requireAuthentication(ImagesAdminContainer)} />
      <Route
        path="/admin/remisesMontures"
        render={requireAuthentication(RemisesMonturesAdminContainer)}
      />
      <Route
        path="/admin/reglesGestion"
        render={requireAuthentication(ReglesGestionAdminContainer)}
      />
      <Route path="/admin/tarifs" render={requireAuthentication(TarifsAdminContainer)} />
      <Route path="/admin/models" render={requireAuthentication(ModelsContainer)} />
      <Route path="/admin/uploadContents" render={requireAuthentication(UploadContentsContainer)} />
      <Route path="/admin/appSettings" render={requireAuthentication(AppSettingsContainer)} />
      <Route path="/admin" render={requireAuthentication(AdministrateursAdminContainer)} />

      <Route path="*" render={PageNotFound} />
    </Switch>
  </BrowserRouter>
);

export default Router;
