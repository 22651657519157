import { flow, set } from "lodash/fp";
import { omit, isUndefined } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_GARANTIES_REQUEST = "ksa/garanties/FETCH_ALL_GARANTIES_REQUEST";
export const FETCH_ALL_GARANTIES_SUCCESS = "kwa/garanties/FETCH_ALL_GARANTIES_SUCCESS";

const FETCH_ALL_GARANTIES_FAILURE = "ksa/garanties/FETCH_ALL_RESEAUX_FAILURE";

const FETCH_GARANTIE_REQUEST = "ksa/garanties/FETCH_GARANTIE_REQUEST";
const FETCH_GARANTIE_SUCCESS = "ksa/garanties/FETCH_GARANTIE_SUCCESS";
const FETCH_GARANTIE_FAILURE = "ksa/garanties/FETCH_GARANTIE_FAILURE";

const SET_GARANTIE_REQUEST = "ksa/garanties/SET_GARANTIE_REQUEST";
const SET_GARANTIE_SUCCESS = "ksa/garanties/SET_GARANTIE_SUCCESS";
const SET_GARANTIE_FAILURE = "ksa/garanties/SET_GARANTIE_FAILURE";

const DELETE_GARANTIE_REQUEST = "ksa/garanties/DELETE_GARANTIE_REQUEST";
const DELETE_GARANTIE_SUCCESS = "ksa/garanties/DELETE_GARANTIE_SUCCESS";
const DELETE_GARANTIE_FAILURE = "ksa/garanties/DELETE_GARANTIE_FAILURE";

export const garantiesActionsHandlers = {
  [FETCH_ALL_GARANTIES_REQUEST]: (state) =>
    flow(set("loaded.garanties", false), set("loading.garanties", true))(state),
  [FETCH_GARANTIE_REQUEST]: (state) => {
    return flow(set("loaded.garanties", false), set("loading.garanties", true))(state);
  },
  [FETCH_ALL_GARANTIES_SUCCESS]: (state, action) => {
    return flow(
      set("entities.garanties", action.response.entities.garanties || {}),
      set("loaded.garanties", true),
      set("loading.garanties", false)
    )(state);
  },

  [FETCH_ALL_GARANTIES_FAILURE]: (state) =>
    flow(set("loaded.garanties", false), set("loading.garanties", false))(state),
  [FETCH_GARANTIE_FAILURE]: (state) =>
    flow(set("loaded.garanties", false), set("loading.garanties", false))(state),

  [FETCH_GARANTIE_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.garanties.${action.id}`, action.response.entities.garanties[action.id]),
      set("loaded.garanties", true),
      set("loading.garanties", false)
    )(state);
  },

  [SET_GARANTIE_REQUEST]: (state) =>
    flow(set("loaded.garanties", false), set("loading.garanties", true))(state),
  [SET_GARANTIE_SUCCESS]: (state, action) =>
    flow(
      set("entities.garanties", {
        ...state.entities.garanties,
        ...action.response.entities.garanties,
      }),
      set("loaded.garanties", true),
      set("loading.garanties", false)
    )(state),
  [SET_GARANTIE_FAILURE]: (state, action) =>
    flow(set("loaded.garanties", false), set("loading.garanties", false))(state),

  [DELETE_GARANTIE_SUCCESS]: (state, action) =>
    flow(set("entities.garanties", omit(state.entities.garanties, action.id)))(state),
};

export function loadGarantie(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_GARANTIE_REQUEST, FETCH_GARANTIE_SUCCESS, FETCH_GARANTIE_FAILURE],
      method: "GET",
      endpoint: `/garanties/${id}`,
      schema: Schemas.GARANTIE,
      successMessage: "La garanties a bien été chargée",
    },
  };
}

export function loadGaranties() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_GARANTIES_REQUEST,
        FETCH_ALL_GARANTIES_SUCCESS,
        FETCH_ALL_GARANTIES_FAILURE,
      ],
      method: "GET",
      endpoint: "/garanties",
      schema: Schemas.GARANTIE_ARRAY,
    },
  };
}

export function createOrUpdateGarantie(garantie) {
  console.log("createOrUpdate garantie()", garantie.garantie_id);
  console.log("garantie", garantie);

  return {
    [CALL_API]: {
      types: [SET_GARANTIE_REQUEST, SET_GARANTIE_SUCCESS, SET_GARANTIE_FAILURE],
      method: !garantie || !Number.isInteger(garantie.garantie_id) ? "POST" : "PUT",
      endpoint:
        !garantie || !Number.isInteger(garantie.garantie_id)
          ? "/garanties"
          : `/garanties/${garantie.garantie_id}`,
      schema: Schemas.GARANTIE,
      body: garantie,
      successMessage: "Garantie enregistré avec succès",
    },
  };
}

export function deleteGarantie(id) {
  console.log("deleteGarantie() id===", id);
  return {
    id,
    [CALL_API]: {
      types: [DELETE_GARANTIE_REQUEST, DELETE_GARANTIE_SUCCESS, DELETE_GARANTIE_FAILURE],
      method: "DELETE",
      endpoint: `/garanties/${id}`,
      successMessage: "Garantie supprimé avec succès",
    },
  };
}
