import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";
import { values, isUndefined } from "lodash";

import { loadAppSettings } from "../../../store/modules/appSettings";
import { Field, FileField, InputField, ToggleField, SelectField } from "react-components-linaia";

import { uploadFiles, removeFile } from "../../../store/modules/uploads";

import { UPLOADS_URL } from "../../../constants/Config";

const AppSettingEditForm = ({ handleSubmit, baseUrl, initialValues, upFiles, rmFile }) => (

  <div className="box">
    <div className="box-content">
      <form onSubmit={handleSubmit}>
        <Field name="update_date" component={InputField} label="Date dernière Version *" className="col-12" fieldClassName="inline"/>
        <div className="btn-group center">
          <button className="btn btn-tertiary" type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    </div>
  </div>

);

AppSettingEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired
};

export default reduxForm({
  form: "AppSettingEditForm",
  validate: (values = {}) => {
    const errors = {};
    if (!values.appsetting) errors.appsetting = "Merci de renseigner une date de dernière version";
    return errors;
  }
})(
  connect(
    (state, { appsettingID }) => ({
      appsettings: state.data.entities.appsettings
    }),
    { upFiles: uploadFiles, rmFile: removeFile, loadAppSettings }
  )(AppSettingEditForm)
);
