export const pagesData = [
  {
    label: "Verres minéraux",
    value: "M",
  },
  {
    label: "2ème paire / Unifocaux",
    value: "2U",
  },
  {
    label: "2ème paire / Progressifs",
    value: "2P",
  },
  {
    label: "2ème paire / Dégressifs",
    value: "2D",
  },
  {
    label: "Verres Panier A",
    value: "N",
  },
  {
    label: "Classe A – 2ème paire",
    value: "C",
  },
];

export const operators = [
  {
    label: ">",
    value: ">",
  },
  {
    label: ">=",
    value: ">=",
  },
  {
    label: "<",
    value: "<",
  },
  {
    label: "<=",
    value: "<=",
  },
  {
    label: "=",
    value: "=",
  },
];
