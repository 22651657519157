import "babel-polyfill";
import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import moment from "moment";
// import { Chromestore } from 'react-components-linaia';

import App from "./containers/App";

// Global / Default local for moment
moment.locale("fr");

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("service-worker.js", {
        scope: "/"
      })
      .then(registration => {
        console.log("SW registered: ", registration);
      })
      .catch(registrationError => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}
// Persistent Storage request
const requestedBytes = 1024 * 1024 * 1024 * 2; // 2 Go
// Chromestore.init(requestedBytes);

function renderWithHotReload(RootElement) {
  render(
    <AppContainer>
      <RootElement />
    </AppContainer>,
    document.getElementById("root")
  );
}

renderWithHotReload(App);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./containers/App", () => {
    const ReplaceContainer = require("./containers/App");
    renderWithHotReload(ReplaceContainer);
  });
}
