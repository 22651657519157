import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { capitalize, get, orderBy } from "lodash";
import { connect } from "react-redux";
import { ButtonConfirmModal } from "react-components-linaia";

import TableListView from "../../shared/TableListView";

import { UPLOADS_URL } from "../../../constants/Config";

const ReseauxTableListView = ({ history: { push }, reseaux, baseUrl, onClickDelete, isAdmin }) => (
  <TableListView
    className="table-boxed full-height"
    data={reseaux}
    cols={{
      nom: {
        label: "Nom du réseau",
        className: "col",
        value: capitalize,
      },

      services: {
        label: "Services",
        className: "col-2_sm-12 text-center",
        value: (value) => (value ? "Oui" : "Non"),
      },
      mineraux: {
        label: "Verres minéraux",
        className: "col-2_sm-12 text-center",
        value: (value) => (value ? "Oui" : "Non"),
      },
      classea_nikon: {
        label: "Verres Panier A",
        className: "col-2_sm-12 text-center",
        value: (value) => (value ? "Oui" : "Non"),
      },
      prix_prisme: {
        label: "Prix option Prisme",
        className: "col-2_sm-12 text-center",
        value: (value) => value + "€",
      },
      libelle_classeA: {
        label: "Libellé Classe A",
        className: "col-2_sm-12 text-center",
        value: (value) => value,
      },
    }}
    search={["nom"]}
    searchLabel={"Rechercher un réseau"}
    sortByDefault="reseau_id"
    sortOrderDefault="asc"
    buttons={
      <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
        <i className="far fa-plus" />
        <span>Ajouter un réseau</span>
      </NavLink>
    }
    rowButtons={(reseau) => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {reseau && (
            <NavLink className="btn" to={`${baseUrl}/${reseau.reseau_id}/edit`}>
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {reseau && (
            <ButtonConfirmModal
              onConfirmationButton={() => onClickDelete(reseau.reseau_id)}
              message="Voulez-vous vraiment supprimer ce réseau ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

ReseauxTableListView.propTypes = {
  reseaux: PropTypes.object,
  isAdmin: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect((state, { reseauId }) => ({
  reseaux: state.data.entities.reseaux,
  reseauId,
}))(withRouter(ReseauxTableListView));
