import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, ImageRadioField } from "react-components-linaia";
import { reduxForm, reset } from "redux-form";
import { values, map } from "lodash";
import { withRouter } from "react-router-dom";
import BaseLayout from "../../containers/shared/BaseLayout";
import { setConfig } from "../../store/modules/globals";
import { loadMarques } from "../../store/modules/marques";

const MarqueForm = ({ history: { push }, marques, handleSubmit, loadMarques }) => {
  useEffect(() => {
    async function fetchData() {
      // You can await here
      await loadMarques();
      // ...
    }
    fetchData();
  }, []);

  const submit = (values, dispatch, props) => {
    let marque = props.marques.find(m => m.marque_id === props.values.marque);
    localStorage.setItem("marque", props.values.marque);
    props.setConfig("marque", marque);

    push(`/home`);
  };
  return (
    <BaseLayout title="MyContainer">
      <form className="" onSubmit={handleSubmit(submit)} name="marqueForm">
        <div className="grid">
          <Field
            name="marque"
            component={ImageRadioField}
            parse={value => Number(value)}
            options={map(marques, marque => ({
              value: marque.marque_id,
              label: marque.libelle
            }))}
            placeholder=""
            label="Marques"
            className="col-12"
            fieldClassName="custom-radio-wrapper"
            classNameGrid="cssGrid"
            classNameCol="cssGrid-item"
          />
        </div>

        <div className="btn-group right">
          <button className="btn btn-primary btn-big" type="submit">
            <span>Valider</span>
          </button>
        </div>
      </form>
    </BaseLayout>
  );
};

export default withRouter(
  connect(
    state => ({
      marques: values(state.data.entities.marques),
      initialValues: {
        marque: state.data.config.marque.marque_id
      }
    }),
    {
      loadMarques,
      setConfig
    }
  )(
    reduxForm({
      form: "MarqueForm",
      validate: (values = {}) => {
        const errors = {};

        if (!values.marque) errors.marque = "Champ requis";

        return errors;
      }
    })(MarqueForm)
  )
);
