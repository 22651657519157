import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Field as RFField, formValueSelector } from "redux-form";
import { map, filter, find, groupBy, flatMap, orderBy, includes, isEmpty } from "lodash";
import { NavLink } from "react-router-dom";

import {
  showPrecalibrage,
  showEquilibrage,
  showPrecalibrageCas2,
  showPrecalibrageCas3,
  showPrecalibrageCas4,
} from "../../utils/PropsUtils";

const tarifKalixiaOeilDroit = (tarif, correction, gamme, sphere, cylindre) => {
  let price = tarif.prix;

  if (
    Math.abs(sphere + cylindre) <= 3 &&
    correction.type_correction === "U" &&
    gamme &&
    gamme.k_sph_cyl_inf_3
  ) {
    price = price - 15.84;
  }

  if (
    correction.galbe >= 5 &&
    correction.galbe <= 7 &&
    correction.distance_vo >= 11 &&
    correction.distance_vo <= 13 &&
    correction.angle >= 7 &&
    correction.angle <= 9 &&
    correction.diff_vlvp >= 2.4 &&
    correction.diff_vlvp <= 2.6 &&
    gamme.k_aspherisation &&
    (gamme.libelle !== "SUPERSEE AP" || tarif.indice !== 1.74)
  ) {
    // #9123 passer a 10 pourcent
    price = price * 0.9;
  }
  return parseFloat(price.toFixed(2));
};

const tarifKalixiaOeilGauche = (tarif, correction, gamme, sphere, cylindre) => {
  let price = tarif.prix;

  if (
    Math.abs(sphere + cylindre) <= 3 &&
    correction.type_correction === "U" &&
    gamme &&
    gamme.k_sph_cyl_inf_3
  ) {
    price = price - 15.84;
  }

  if (
    correction.galbe >= 5 &&
    correction.galbe <= 7 &&
    correction.distance_vo >= 11 &&
    correction.distance_vo <= 13 &&
    correction.angle >= 7 &&
    correction.angle <= 9 &&
    correction.diff_vlvp >= 2.4 &&
    correction.diff_vlvp <= 2.6 &&
    gamme.k_aspherisation &&
    (gamme.libelle !== "SUPERSEE AP" || tarif.indice !== 1.74)
  ) {
    // #9123 passer a 10 pourcent
    price = price * 0.9;
  }
  return parseFloat(price.toFixed(2));
};

const ModeleVerreField = ({
  oeil,
  tarifs,
  reseau,
  correction,
  type_verre,
  plus,
  gammes,
  name,
  side,
  regle_gestions,
  marque,
  selectedValue,
  cylindre,
  sphere,
  addition,
  verre_couleur,
  showAppairageMessage,
  setMaxIndice,
}) => {
  let tarifSelected = filter(tarifs, (tarif) => {
    //check si tarif.reseau_id === reseau.reseau_id
    if (tarif.reseau_id !== reseau.reseau_id) {
      return false;
    }

    //check si tarif.type_verre === correction.type_correction
    if (tarif.type_verre !== correction.type_correction) {
      return false;
    }

    //check les différents types de verres
    if (
      !(
        !type_verre ||
        (type_verre === "blancs" && tarif.verre_blanc) ||
        (type_verre === "sansAntiReflet" && tarif.verre_sar) ||
        (type_verre === "blue" && tarif.verre_blue) ||
        (type_verre === "ndriveBoost" && tarif.ndrive_boost) ||
        (type_verre === "photochromique" && tarif.verre_photochromique) ||
        (type_verre === "polarises" && tarif.verre_polarise)
      )
    ) {
      return false;
    }

    if (
      !(
        !verre_couleur ||
        !type_verre ||
        !includes(["polarises"], type_verre) ||
        (includes(["polarises"], type_verre) && verre_couleur === tarif.couleur)
      )
    ) {
      return false;
    }

    // dans le cas kalixia -16, si la règle de gestion est du 1.5 on retourne tous les tarifs cas_k_moins_16 sinon on utilise la regle de gestion classique
    if (
      reseau.code === "KALIXIA" &&
      correction.type_correction === "U" &&
      correction.moins_16 &&
      !find(regle_gestions, (rg) =>
        rg.indice === "1.5" ? tarif.cas_k_moins_16 : rg.indice === tarif.indice
      )
    ) {
      return false;
    }

     // dans le cas kalixia  on utilise la regle de gestion classique
     if (
      reseau.code === "KALIXIA" &&
      correction.type_correction === "U" &&
      !correction.moins_16 &&
      // vu qu'on fait l'inverse du find, on veut que moins de 16 soit false, mais que l'indice != du tarif pour sortir que les bons tarifs..
      // je ne l'explique pas vraiment, mais quand je test j'ai bien des tarifs en moins de 16...
      !find(regle_gestions, (rg) =>
        rg.indice === tarif.indice
      )
    ) {
      return false;
    }

    // on affiche pas les cas_k_moins_16 si on est pas dans le cas kalixia -16
    if (reseau.code === "KALIXIA" && !correction.moins_16 && tarif.cas_k_moins_16) {
      return false;
    }

    // dans le cas kalixia percee_nylor, on remplace les règles de gestions 1.5 en 1.6
    if (
      reseau.code === "KALIXIA" &&
      !(correction.type_correction === "U" && correction.moins_16) &&
      !find(
        regle_gestions,
        (rg) =>
          (correction.percee_nylor === "percee_nylor" &&
          tarif.cas_k_monture_spec &&
          rg.indice === "1.5"
            ? "1.6"
            : rg.indice) === tarif.indice
      )
    ) {
      return false;
    }

    // check if kalixia perceeNylor
    if (
      !(
        reseau.code !== "KALIXIA" ||
        (correction.percee_nylor === "percee_nylor" && tarif.cas_k_monture_spec === true) ||
        (correction.percee_nylor !== "percee_nylor" && !tarif.cas_k_monture_spec)
      )
    ) {
      return false;
    }

    if (
      !(
        gammes &&
        marque &&
        gammes[tarif.gamme_id] &&
        marque.marque_id === gammes[tarif.gamme_id].marque_id &&
        (plus || gammes[tarif.gamme_id].masquee === false)
      )
    ) {
      return false;
    }

    //Application des règles de gestions sur tous les réseaux hors KALIXIA pour trouvé tarif.indice
    if (!(reseau.code === "KALIXIA" || regle_gestions.find((rg) => rg.indice === tarif.indice))) {
      return false;
    }

    // CAS SANTECLAIR SI SPHERE >= 2 || SPHERE < 2
    if (
      !(
        reseau.code !== "SANTECLAIR" ||
        (tarif.cas_sante === true && Math.abs(parseInt(sphere + cylindre)) < 2) ||
        (!tarif.cas_sante && Math.abs(parseInt(sphere + cylindre)) >= 2)
      )
    ) {
      return false;
    }
    return true;
  });

  setMaxIndice(Math.max(...tarifSelected.map((tarif) => tarif.indice)), side);

  let tarifsGroupedSorted = map(
    groupBy(
      flatMap(
        groupBy(
          flatMap(
            groupBy(
              flatMap(
                groupBy(
                  orderBy(tarifSelected, (obj) => obj.libelle),
                  (obj) => obj.libelle
                ),
                (value, key, obj) => orderBy(value, (obj) => parseInt(obj.prix), ["asc"])
              ),
              (obj) => obj.prix
            ),
            (value, key, obj) =>
              orderBy(value, (obj) => parseFloat(gammes[obj.gamme_id].numero_order))
          ),

          (obj) => gammes[obj.gamme_id].numero_order
        ),
        (value, key, obj) =>
          orderBy(value, (obj) => parseFloat(obj.indice.replace(",", ".")), ["asc"])
      ),
      (obj) => obj.indice
    ),
    (v) => v
  );

  console.table(tarifsGroupedSorted);
  return (
    <Fragment>
      {oeil && oeil === side && (
        <Fragment>
          {!isEmpty(
            flatMap(tarifsGroupedSorted, (indice) =>
              filter(
                indice,
                (tarif, key) =>
                  reseau.code !== "KALIXIA" && (tarif.indice === "1.5" || tarif.indice === "1.6")
              )
            )
          ) && (
            <div className="messageWrapper">
              <div className="messageBloc">
                <ul>
                  <li>1.6 RX et précal obligatoire sur les nylor et perçée</li>
                </ul>
              </div>
            </div>
          )}

          {(showPrecalibrage(reseau, sphere, cylindre, addition) ||
            showAppairageMessage ||
            showEquilibrage(reseau, correction) ||
            showPrecalibrageCas2(reseau, correction) ||
            showPrecalibrageCas3(reseau, correction) ||
            showPrecalibrageCas4(reseau, correction)) && (
            <div className="messageWrapper">
              <div className="messageBloc">
                <ul>
                  {showPrecalibrage(reseau, sphere, cylindre, addition) && (
                    <li>Précalibrage autorisé + 12€</li>
                  )}
                  {showEquilibrage(reseau, correction) && (
                    <li>Equilibrage des épaisseurs autorisé</li>
                  )}
                  {showPrecalibrageCas2(reseau, correction) && (
                    <li>
                      Le précalibrage est autorisé sur le verre dont la sphère est le plus près de 0
                    </li>
                  )}
                  {showPrecalibrageCas3(reseau, correction) && (
                    <li>Le précalibrage est autorisé sur les 2 verres</li>
                  )}
                  {showPrecalibrageCas4(reseau, correction) && (
                    <li>Le précalibrage est autorisé sur les 2 verres</li>
                  )}
                  {showAppairageMessage && <li>Appairage avec l'indice le + fort autorisé</li>}
                </ul>
              </div>
            </div>
          )}
          <div className="liste-verres">
            {map(tarifsGroupedSorted, (indice) => {
              return (
                <div className="grid-6_xs-2_sm-4_md-4_lg-5-center full-width">
                  {map(indice, (tarif, key) => {
                    const TarifPrice =
                      reseau.code === "KALIXIA" && gammes
                        ? oeil === "droit"
                          ? tarifKalixiaOeilDroit(
                              tarif,
                              correction,
                              gammes[tarif.gamme_id],
                              sphere,
                              cylindre
                            )
                          : tarifKalixiaOeilGauche(
                              tarif,
                              correction,
                              gammes[tarif.gamme_id],
                              sphere,
                              cylindre
                            )
                        : tarif.prix;
                    return (
                      <div className="col" key={key}>
                        <RFField
                          id={name + "_" + tarif.tarif_id}
                          name={name}
                          parse={(value) => Number(value)}
                          component="input"
                          type="radio"
                          value={tarif.tarif_id}
                          checked={selectedValue === tarif.tarif_id}
                        />
                        <label
                          className="custom-radio custom-radio-verre"
                          htmlFor={name + "_" + tarif.tarif_id}
                        >
                          {gammes[tarif.gamme_id].ensavoirplus === true && (
                            <NavLink className="btn-plus" to={`/modele-verre/` + tarif.gamme_id}>
                              <i class="fas fa-info"></i>
                            </NavLink>
                          )}

                          <span className="custom-radio-check" />
                          <div className="custom-radio-content">
                            <div className="verre-indice">{tarif.indice}</div>
                            <div className="verre-libelle">{tarif.libelle}</div>
                            <div className="verre-prix">
                              {tarif.prix_precal
                                ? (TarifPrice + tarif.prix_precal).toFixed(2)
                                : TarifPrice}
                              €
                            </div>
                            <div className="verre-precal">
                              {tarif.prix_precal
                                ? TarifPrice + "€ + " + tarif.prix_precal + " € (précal)"
                                : ""}
                            </div>
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

const selector = formValueSelector("ModeleVerreForm");

// export default ModeleVerreField;
export default connect(
  (state, props) => ({
    selectedValue: selector(state, props.name),
  }),
  {}
)(ModeleVerreField);
